import { Container, useMediaQuery } from '@mui/material';
import AppFooter from 'components/footer';
import ContactOnboarding from 'components/contact-onboarding';
import ImageInformationSection from 'components/information-sections/image-information-section';
import { StyledDivider } from '../../home/styles';

const TrackChanges = () => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sections = [
    {
      title: 'Track Changes with Ease',
      description:
        'DDB enhances team collaboration with real-time tracking on all modifications, ensuring visibility and compliance. With audit logs and user access permissions, DDB offers transparency, control, and traceability throughout your projects.',
      imageSrc: '/illustrations/landing-page/changes.svg',
      imageAlt: 'changes',
      buttonText: 'Go To Projects',
      titleSection: true,
    },
    {
      title: 'History of design changes',
      description:
        "DDB meticulously logs every modification to assets, parameters, and sources in our comprehensive audit log. This feature provides project teams with a transparent timeline for each design parameter, offering valuable insights into the project's evolution.",
      imageSrc: '/illustrations/landing-page/designChangesHistory.svg',
      imageAlt: 'design changes history',
      leftSection: true,
    },
    {
      title: 'Simplified Quality Assurance',
      description:
        'DDB has a checking and approval feature which enables a robust QA process. Results are displayed to the user through colour wheel indicators, providing quick visual feedback on the check and approval status of an entry.',
      imageSrc: '/illustrations/landing-page/qualityAssurance.svg',
      imageAlt: 'quality assurance',
    },
    {
      title: 'User Access Permissions',
      description:
        'Project teams can assign roles that grant varying degrees of permissions to users, ensuring the integrity of your data and enabling adherence to AMS quality management protocols. Projects can restrict access even further by labelling a project as confidential.',
      imageSrc: '/illustrations/landing-page/users.svg',
      imageAlt: 'users',
      leftSection: true,
    },
  ];

  return (
    <>
      <main id="main-content" tabIndex="-1" style={{ minHeight: 650 }}>
        <Container maxWidth="lg">
          {sections.map((section, index) => (
            <div key={section.title}>
              <ImageInformationSection
                title={section.title}
                description={section.description}
                imageSrc={section.imageSrc}
                imageAlt={section.imageAlt}
                buttonText={section.buttonText}
                titleSection={section.titleSection}
                isMdUp={isMdUp}
                leftSection={section.leftSection}
              />
              {index < sections.length - 1 && <StyledDivider />}
            </div>
          ))}
          <ContactOnboarding isMdUp={isMdUp} />
        </Container>
      </main>
      <AppFooter />
    </>
  );
};

export default TrackChanges;
