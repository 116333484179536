import { useState, useEffect, useCallback, useContext } from 'react';
import { useImmer } from 'use-immer';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { useIsAuthenticated } from '@azure/msal-react';
import { GridRowModes } from '@mui/x-data-grid-premium';
import { isEmpty, find, findIndex } from 'lodash';
import { userApi } from 'api';
import { UserContext, ProjectContext, ProjectPermissionContext } from 'context';
import { checkInternalUsers } from 'api/graph';

const useProjectPermissions = projectId => {
  const { project } = useContext(ProjectContext);
  const isAuthenticated = useIsAuthenticated();
  const { user } = useContext(UserContext);
  const { projectRoles, getProjectPermissions } = useContext(
    ProjectPermissionContext
  );
  const [openAdminPermissionWarning, setOpenAdminPermissionWarning] = useState(
    {}
  );
  const [openDeletePermissionWarning, setOpenDeletePermissionWarning] =
    useState(false);
  const [openReaderPermissionWarning, setOpenReaderPermissionWarning] =
    useState({});
  const [openRemoveReaderWithOtherRoles, setOpenRemoveReaderWithOtherRoles] =
    useState({});
  const [openAllPermissionWarning, setOpenAllPermissionWarning] = useState({});
  const [projectPermissions, setProjectPermissions] = useImmer([]);
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [rowModesModel, setRowModesModel] = useState({});
  const [updateUsers, setUpdateUsers] = useState({ status: 'idle' });
  const [columnVisibilityModel, setColumnVisibilityModel] = useImmer({});

  const resetUpdateUserError = () => {
    setUpdateUsers({ status: 'idle' });
  };

  useEffect(() => {
    if (!isEmpty(projectPermissions)) {
      const internal = projectPermissions.every(
        projectUser => projectUser.internal
      );
      setColumnVisibilityModel(curr => {
        curr.arupUser = !internal;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectPermissions, project]);

  const checkUserList = async newUsers => {
    const userEmails = newUsers.map(roleUser => roleUser.email);
    const checkedUsers = await checkInternalUsers(userEmails);
    const mappedUsers = newUsers.map(newUser => {
      const userMatch = checkedUsers.find(
        extUser =>
          extUser?.mail?.toLowerCase() === newUser?.email?.toLowerCase() ||
          extUser?.userPrincipalName?.toLowerCase() ===
            newUser?.email?.toLowerCase()
      );
      return { ...newUser, internal: userMatch?.internalUser || false };
    });
    return mappedUsers;
  };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getProjectUserRoles = async afterQuery => {
      const query = {
        resource_id: [projectId],
        sort_by: 'email',
        order: 'asc',
        page_limit: 50,
      };

      if (afterQuery) query.after = afterQuery;

      try {
        const response = await userApi('getUsersRoles', query, source.token);
        if (!didCancel && response) {
          const { usersRoles, paging } = response;
          const rolesWithInternal = await checkUserList([...usersRoles]);
          setProjectPermissions(curr => [...curr, ...rolesWithInternal]);
          setPermissionsLoading(false);
          if (paging?.cursors?.after)
            await getProjectUserRoles(paging?.cursors?.after);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setPermissionsLoading(false);
      }
    };

    if (projectId && isAuthenticated) getProjectUserRoles();

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isAuthenticated]);

  const updateRow = async (newRow, oldRow) => {
    resetUpdateUserError();
    let email = [];
    const updatedRow = { ...newRow, isNew: false };

    if (newRow.isNew) {
      email = updatedRow.user;
      updatedRow.reader = true;
    } else email = [updatedRow.email];

    if (isEmpty(email)) {
      setPermissionsLoading(false);
      setRowModesModel({
        ...rowModesModel,
        [oldRow.userId]: { mode: GridRowModes.Edit },
      });

      return Promise.reject({ details: 'User(s) cannot be empty' });
    }
    if (
      newRow.isNew &&
      !isEmpty(
        projectPermissions.filter(roles => {
          const users = email.map(mail => mail.toLowerCase());
          return users.includes(roles.email);
        })
      )
    ) {
      setPermissionsLoading(false);
      setRowModesModel({
        ...rowModesModel,
        [newRow.userId]: { mode: GridRowModes.Edit },
      });

      return Promise.reject({ details: 'User(s) already exists' });
    }

    const toUpdateActions = projectRoles?.reduce((toUpdate, { name }) => {
      const existingRole = find(oldRow?.roles, { name });
      const newRole = updatedRow[name];

      if (existingRole && newRole !== !!existingRole) toUpdate[name] = newRole;
      if (!existingRole && newRole) toUpdate[name] = newRole;
      return toUpdate;
    }, {});

    const newRoles = projectRoles.filter(
      action => !!toUpdateActions[action.name]
    );
    const removeRoles = projectRoles.filter(
      action => toUpdateActions[action.name] === false
    );

    if (newRow.isNew && isEmpty(toUpdateActions)) {
      setPermissionsLoading(false);
      setProjectPermissions(curr => {
        curr[0].user = newRow.user;
      });
      setRowModesModel({
        ...rowModesModel,
        [newRow.userId]: {
          mode: GridRowModes.Edit,
        },
      });

      return Promise.reject({
        details: 'User(s) must have at least one permission',
      });
    }

    let addedRows;

    if (newRow.isNew) {
      const isInternal = await checkInternalUsers([...newRow?.user]);
      const areUsersExternal = isInternal.some(users => {
        return users.internalUser === false;
      });
      if (areUsersExternal && newRow.administrator) {
        setPermissionsLoading(false);
        setRowModesModel({
          ...rowModesModel,
          [oldRow.userId]: { mode: GridRowModes.Edit },
        });

        return Promise.reject({
          details: 'External users cannot be project administrators',
        });
      }
    }
    if (newRoles.length) {
      try {
        const { usersRoles } = await userApi('putUsersRoles', {
          body: {
            users_roles: [
              {
                emails: email,
                role_ids: newRoles.map(role => role.id),
              },
            ],
          },
        });
        addedRows = usersRoles;
      } catch (err) {
        setRowModesModel({
          ...rowModesModel,
          [oldRow.userId]: { mode: GridRowModes.Edit },
        });

        return Promise.reject(err);
      }
    }
    if (removeRoles.length) {
      try {
        await userApi('deleteUsersRoles', {
          body: {
            users_roles: removeRoles.map(({ id }) => ({
              user_id: oldRow.userId,
              role_id: id,
            })),
          },
        });
      } catch (err) {
        setRowModesModel({
          ...rowModesModel,
          [oldRow.userId]: { mode: GridRowModes.Edit },
        });

        return Promise.reject(err);
      }
    }

    if (newRow.isNew) {
      const isInternal = await checkInternalUsers([...newRow?.user]);
      addedRows.forEach(row => {
        const rowMatch = isInternal.find(
          extUser =>
            extUser?.mail?.toLowerCase() === row?.email?.toLowerCase() ||
            extUser?.userPrincipalName?.toLowerCase() ===
              row?.email?.toLowerCase()
        );
        row.internal = rowMatch?.internalUser || false;
      });
      setProjectPermissions(curr => [
        ...addedRows,
        ...curr.filter(row => row.userId !== newRow.userId),
      ]);
      setRowModesModel(curr => {
        delete curr[newRow?.userId];
        delete curr[oldRow?.userId];
        return curr;
      });
      return addedRows;
    }

    const index = findIndex(projectPermissions, { userId: oldRow.userId });
    const userRole = find(projectPermissions, { userId: oldRow.userId });

    const updated = { ...userRole, isNew: false };
    if (newRoles.length) updated.roles = [...updated.roles, ...newRoles];

    if (removeRoles.length)
      removeRoles.forEach(toRemove => {
        updated.roles = updated.roles.filter(role => role.id !== toRemove.id);
      });

    setProjectPermissions(curr => {
      curr[index] = updated;
    });
    if (email.includes(user.email.toLowerCase())) {
      getProjectPermissions();
    }
    return updated;
  };

  const processRowUpdate = (newRow, oldRow) => {
    if (
      newRow.email?.toLowerCase() === user.email.toLowerCase() &&
      !newRow.administrator &&
      !isEmpty(oldRow?.roles?.filter(role => role.name === 'administrator'))
    ) {
      setOpenAdminPermissionWarning({ open: true, newRow, oldRow });
      return Promise.resolve(oldRow);
    }
    if (
      !newRow.isNew &&
      !newRow.reader &&
      !project.confidential &&
      !(
        newRow.editor ||
        newRow.checker ||
        newRow.approver ||
        newRow.administrator
      )
    ) {
      setOpenReaderPermissionWarning({ open: true, newRow, oldRow });
      return Promise.resolve(oldRow);
    }
    if (
      !newRow.isNew &&
      !newRow.reader &&
      project.confidential &&
      !(
        newRow.editor ||
        newRow.checker ||
        newRow.approver ||
        newRow.administrator
      )
    ) {
      setOpenAllPermissionWarning({ open: true, newRow, oldRow });
      return Promise.resolve(oldRow);
    }
    if (
      !newRow.isNew &&
      !newRow.reader &&
      (newRow.editor ||
        newRow.checker ||
        newRow.approver ||
        newRow.administrator)
    ) {
      setOpenRemoveReaderWithOtherRoles({ open: true, newRow, oldRow });
      return Promise.resolve(oldRow);
    }
    return updateRow(newRow, oldRow);
  };

  const handleProcessRowUpdateError = useCallback(error => {
    if (error?.details) setUpdateUsers({ status: 'error', error });
  }, []);

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const deleteUser = async (userId, row) => {
    resetUpdateUserError();
    setPermissionsLoading(true);
    try {
      const toDelete = row.roles.map(({ id }) => ({
        user_id: userId,
        role_id: id,
      }));

      await userApi('deleteUsersRoles', { body: { users_roles: toDelete } });

      setProjectPermissions(
        projectPermissions.filter(permission => permission.userId !== userId)
      );
    } catch (error) {
      setUpdateUsers({ status: 'error', error });
    }
    if (row.email?.toLowerCase() === user.email.toLowerCase()) {
      getProjectPermissions();
    }
    setPermissionsLoading(false);
  };

  const handleDeleteClick = (userId, row) => async () => {
    if (row.email?.toLowerCase() === user.email.toLowerCase()) {
      setOpenDeletePermissionWarning(true);
    } else {
      deleteUser(userId, row);
    }
  };

  const handleCancelClick = id => () => {
    resetUpdateUserError();
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = find(projectPermissions, { userId: id });
    if (editedRow?.isNew)
      setProjectPermissions(
        projectPermissions.filter(row => row.userId !== id)
      );
  };

  const handleAddUsers = () => {
    const id = uuid();
    setRowModesModel(oldModel => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'user' },
      ...oldModel,
    }));
    setProjectPermissions(curr => [
      {
        email: '',
        roles: [],
        staffId: '',
        userId: id,
        userName: '',
        isNew: true,
      },
      ...curr,
    ]);
  };

  const handleAcceptDelete = () => {
    const row = projectPermissions.filter(
      person => person.email === user.email.toLowerCase()
    );
    if (openDeletePermissionWarning) {
      setOpenDeletePermissionWarning(false);
      deleteUser(row[0].userId, row[0]);
    }
    if (openReaderPermissionWarning.open) {
      deleteUser(
        openReaderPermissionWarning.newRow.userId,
        openReaderPermissionWarning.oldRow
      );
      setOpenReaderPermissionWarning({});
    }
    if (openAdminPermissionWarning.open) {
      setOpenAdminPermissionWarning({});
      updateRow(
        openAdminPermissionWarning.newRow,
        openAdminPermissionWarning.oldRow
      );
    }
    if (openAllPermissionWarning.open) {
      deleteUser(
        openAllPermissionWarning.newRow.userId,
        openAllPermissionWarning.oldRow
      );
      setOpenAllPermissionWarning({});
    }
    if (openRemoveReaderWithOtherRoles.open) {
      deleteUser(
        openRemoveReaderWithOtherRoles.newRow.userId,
        openRemoveReaderWithOtherRoles.oldRow
      );
      setOpenRemoveReaderWithOtherRoles({});
    }
  };

  const handleCancelDelete = () => {
    const row = projectPermissions.filter(
      person => person.email === user.email.toLowerCase()
    );
    if (openReaderPermissionWarning.open) {
      setRowModesModel({
        ...rowModesModel,
        [openReaderPermissionWarning.newRow.userId]: {
          mode: GridRowModes.Edit,
        },
      });
      setOpenReaderPermissionWarning({});
    }
    if (openAdminPermissionWarning.open) {
      setRowModesModel({
        ...rowModesModel,
        [row[0].userId]: { mode: GridRowModes.Edit },
      });
    }
    if (openDeletePermissionWarning) {
      setRowModesModel({
        ...rowModesModel,
        [row[0].userId]: { mode: GridRowModes.View },
      });
    }
    if (openAllPermissionWarning.open) {
      setRowModesModel({
        ...rowModesModel,
        [openAllPermissionWarning.newRow.userId]: {
          mode: GridRowModes.Edit,
        },
      });
    }
    if (openRemoveReaderWithOtherRoles.open) {
      setRowModesModel({
        ...rowModesModel,
        [openRemoveReaderWithOtherRoles.newRow.userId]: {
          mode: GridRowModes.Edit,
        },
      });
    }
    setOpenAdminPermissionWarning({});
    setOpenReaderPermissionWarning({});
    setOpenAllPermissionWarning({});
    setOpenRemoveReaderWithOtherRoles({});
    setOpenDeletePermissionWarning(false);
  };

  return {
    projectPermissions,
    setProjectPermissions,
    permissionsLoading,
    setPermissionsLoading,
    updateUsers,
    rowModesModel,
    resetUpdateUserError,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    handleProcessRowUpdateError,
    handleAddUsers,
    openAdminPermissionWarning,
    setOpenAdminPermissionWarning,
    openDeletePermissionWarning,
    setOpenDeletePermissionWarning,
    deleteUser,
    setRowModesModel,
    updateRow,
    handleAcceptDelete,
    handleCancelDelete,
    columnVisibilityModel,
    setColumnVisibilityModel,
    openReaderPermissionWarning,
    setOpenReaderPermissionWarning,
    openAllPermissionWarning,
    setOpenAllPermissionWarning,
    openRemoveReaderWithOtherRoles,
  };
};

export default useProjectPermissions;
