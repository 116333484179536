import { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import { DataTemplatesContext } from 'context';

export default (assetNodes, selectedAssets) => {
  const { templateAssets } = useContext(DataTemplatesContext);
  const [requiredAssetsSelected, setRequiredAssetsSelected] = useState([]);
  const [requirementsCount, setRequirementsCount] = useState(0);
  const [validationError, setValidationError] = useState([]);

  const assetCountIsCorrect = (minCount, maxCount, length) =>
    length >= minCount && (!maxCount || length <= maxCount);

  const getRequiredLength = (min, max) => {
    if (max === null) return `${min} or more assets`;
    if (min === max && min <= 1) return `${min} asset`;
    if (min === max && min > 1) return `${min} assets`;
    return `between ${min} and ${max} assets`;
  };

  const countErrorMessage = (ph, parentId) => {
    const requiredLength = getRequiredLength(ph.minCount, ph.maxCount);

    return parentId
      ? `${requiredLength} must be assigned to asset placeholder "${ph.name}" and parent asset "${parentId.name}"`
      : `${requiredLength} must be assigned to asset placeholder "${ph.name}"`;
  };

  const orderAssetsHierarchically = assets => {
    const orderedAssets = [];

    const addAssetAndChildren = asset => {
      orderedAssets.push(asset);
      assets
        .filter(child => child.parentAssetPlaceholderId === asset.id)
        .forEach(addAssetAndChildren);
    };

    assets
      .filter(asset => asset.parentAssetPlaceholderId === null)
      .forEach(addAssetAndChildren);

    return orderedAssets;
  };

  const templateAssetsOrdered = orderAssetsHierarchically(templateAssets);

  const validateDataSetTree = (templateAssetsArr, selectedAssetsArr) => {
    const topPlaceholder = templateAssetsArr.find(
      templateAsset => templateAsset.parentAssetPlaceholderId === null
    );
    const validationErrorsArray = [];
    templateAssetsArr.forEach(templateAsset => {
      // if the asset is the top level of the template, validate only one top level asset is selected
      if (templateAsset.id === topPlaceholder.id) {
        if (templateAsset.assetType) {
          const assetsAtLevel = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId === templateAsset.id
          );
          if (assetsAtLevel.length !== 1) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: countErrorMessage(templateAsset, null),
            });
          }
        }
      } else if (templateAsset.assetType) {
        // if the asset is the top non-project level of the template, validate the correct number are selected
        const topAssetLevelPlaceholder = templateAssetsArr.find(
          asset => asset.parentAssetPlaceholderId === topPlaceholder.id
        );
        if (
          templateAsset.assetType.id === topAssetLevelPlaceholder.assetType.id
        ) {
          const assetsAtLevel = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId === templateAsset.id
          );
          if (
            assetCountIsCorrect(
              topAssetLevelPlaceholder.minCount,
              topAssetLevelPlaceholder.maxCount,
              assetsAtLevel.length
            ) === false
          ) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: countErrorMessage(templateAsset, null),
            });
          }
        } else {
          const assetsAtParent = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId ===
              templateAsset.parentAssetPlaceholderId
          );
          const assetsAtLevel = selectedAssetsArr.filter(
            selectedAsset =>
              selectedAsset.assetPlaceholderId === templateAsset.id
          );
          if (assetsAtParent.length) {
            assetsAtParent.forEach(asset => {
              const children = assetsAtLevel.filter(
                child => child.parent === asset.id
              );
              if (
                assetCountIsCorrect(
                  templateAsset.minCount,
                  templateAsset.maxCount,
                  children.length
                ) === false
              ) {
                validationErrorsArray.push({
                  assetTypeId: templateAsset.assetType.id,
                  assetSubTypeId: templateAsset.assetSubType?.id,
                  parentAssetPlaceholderId:
                    templateAsset.parentAssetPlaceholderId,

                  details: countErrorMessage(templateAsset, asset),
                });
              }
            });
          } else if (!assetsAtLevel.length && templateAsset.minCount >= 1) {
            validationErrorsArray.push({
              assetTypeId: templateAsset.assetType.id,
              assetSubTypeId: templateAsset.assetSubType?.id,
              parentAssetPlaceholderId: templateAsset.parentAssetPlaceholderId,
              details: countErrorMessage(templateAsset, null),
            });
          }
        }
      }
    });
    return validationErrorsArray;
  };

  useEffect(() => {
    const validationResult = validateDataSetTree(
      templateAssetsOrdered,
      selectedAssets
    );
    setValidationError(validationResult);
    setRequirementsCount(validationResult.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets]);

  useEffect(() => {
    if (assetNodes.length) {
      const filterAssetsForRequirements = assetNode => {
        if (!isEmpty(selectedAssets)) {
          return assetNode?.children.reduce((acc, assetChild) => {
            if (!isEmpty(assetChild.children)) {
              acc.push(...filterAssetsForRequirements(assetChild));
            }
            if (
              selectedAssets.find(
                selectedAsset => selectedAsset.id === assetChild.id
              )
            ) {
              acc.push(assetChild);
            }
            return acc;
          }, []);
        }
        return [];
      };

      const selected = filterAssetsForRequirements(assetNodes[0]);
      setRequiredAssetsSelected([...selected]);
    }
  }, [assetNodes, selectedAssets]);

  return {
    requirementsCount,
    requiredAssetsSelected,
    validationError,
  };
};
