import { Suspense } from 'react';
import PT from 'prop-types';
import { Skeleton, useMediaQuery, Divider } from '@mui/material';
import PermanentDrawer from '../permanent';
import handleNavigationDrawer from './hooks';
import StyledBox from './responsive-styles';
import NavigationMenu from './navigation-menu';

export default function ResponsiveContainer({
  drawerWidth,
  navigation: Navigation,
  useNavigationMenu,
  children,
  ...rest
}) {
  const screenMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { toggleDrawerOpen, open } = handleNavigationDrawer();

  return (
    <>
      <PermanentDrawer
        width={drawerWidth}
        open={open}
        toggleDrawerOpen={toggleDrawerOpen}
      >
        <Suspense
          fallback={
            <>
              <Skeleton height={50} />
              <Divider light />
            </>
          }
        >
          {useNavigationMenu ? (
            <NavigationMenu
              drawerOpen={open}
              toggleDrawerOpen={screenMd ? () => {} : toggleDrawerOpen}
              {...rest}
            />
          ) : (
            <Navigation
              drawerOpen={open}
              toggleDrawerOpen={screenMd ? () => {} : toggleDrawerOpen}
              {...rest}
            />
          )}
        </Suspense>
      </PermanentDrawer>
      <StyledBox
        open={open}
        screenmd={screenMd?.toString() || 'false'}
        drawerwidth={drawerWidth}
      >
        {children}
      </StyledBox>
    </>
  );
}

ResponsiveContainer.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  drawerWidth: PT.number,
  useNavigationMenu: PT.bool,
  navigation: PT.func,
};

ResponsiveContainer.defaultProps = {
  drawerWidth: 375,
  useNavigationMenu: true,
  navigation: null,
};
