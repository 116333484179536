import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router';
import axios from 'axios';
import { handleApiError, parametersApi } from 'api';

export default (
  push,
  setOpenParameterCreator,
  openParameterCreator,
  setRowSelectionModel,
  availableParameterTypes
) => {
  const { projectId } = useParams();
  const [nonCustomValue, setNonCustomValue] = useState({});
  const [existingParamValue, setExistingParamValue] = useState({});
  const [allUnitTypes, setAllUnitTypes] = useState([]);
  const [unitTypesLoading, setUnitTypesLoading] = useState(true);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [allParameterTypes, setAllParameterTypes] = useState([]);
  const [allLoading, setAllLoading] = useState(true);
  const { assetId } = useParams();
  const [error, setError] = useState(undefined);

  const getAllParameterTypes = async after => {
    const query = {
      page_limit: 500,
      sort_by: 'name',
      order: 'asc',
      scope_id: projectId,
      show_local: true,
      show_global: true,
    };
    if (after) {
      query.after = after;
    }
    try {
      const response = await parametersApi('getAllParameterTypes', query);

      if (response) {
        const { parameterTypes: data, paging } = response;
        const mappedResponse = data.map(type => {
          return {
            id: uuidv4(),
            parameterTypeId: type.id,
            parameterTypeName: type.name,
            dataType: type.dataType,
            globalParameter: type.globalParameter,
            status: 'unanswered',
            value: null,
            unitTypeId: type.unitType?.id || null,
            unit: null,
            name: type.name,
            source: null,
            tags: [],
            disciplines: [],
            checked: true,
            currentAvailableParameter: !!availableParameterTypes.find(
              param => param.parameterTypeId === type.id
            ),
          };
        });
        setAllParameterTypes(curr => [...curr, ...mappedResponse]);
        if (paging?.cursors?.after) {
          getAllParameterTypes(paging.cursors.after);
        } else {
          setAllLoading(false);
        }
      }
    } catch (err) {
      setAllLoading(false);
      setError(err);
    }
  };

  const getUnitTypes = async (after, option) => {
    setUnitTypesLoading(true);
    const response = await parametersApi('getAllUnitTypes', {
      sort_by: 'name',
      order: 'asc',
      after,
    }).catch(err => {
      setUnitTypesLoading(false);
      setError(err);
      handleApiError(err, []);
    });

    if (response) {
      const { unitTypes, paging } = response;
      const filtered = unitTypes.filter(unitType => {
        return unitType?.unitSystems?.some(system => system.units.length > 0);
      });
      setAllUnitTypes(curr => [...curr, ...filtered]);
      if (paging?.cursors?.after) getUnitTypes(paging.cursors.after, option);
      else setUnitTypesLoading(false);
    }
  };

  const getHelperText = value => {
    if (value === 'string') {
      return 'The parameter will accept both text and number';
    }
    if (value === 'boolean') {
      return 'The parameter will accept only true or false';
    }
    if (value === 'float') {
      return 'The parameter will accept only numbers including decimals';
    }
    if (value === 'integer') {
      return 'The parameter will accept only a date';
    }
    if (value === 'date') {
      return 'The parameter will accept only a date';
    }
    return 'Select a data type for the parameter';
  };

  useEffect(() => {
    setAllUnitTypes([]);
    setAvailableUnits([]);
    getUnitTypes();
    setAllParameterTypes([]);
    setAllLoading(true);
    getAllParameterTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUnitType) {
      const units = [];
      selectedUnitType?.unitSystems?.forEach(system => {
        system?.units.forEach(unit => {
          units.push({ id: unit.id, name: unit.symbol });
        });
      });

      setAvailableUnits(units);
    }
  }, [selectedUnitType]);

  const getExistingParameter = async parameterTypeId => {
    const didCancel = false;
    const source = axios.CancelToken.source();
    const query = {
      parameter_type_id: parameterTypeId,
    };
    if (assetId) {
      query.asset_id = assetId;
    } else {
      query.project_parameter = true;
    }
    const response = await parametersApi(
      'getAllParameters',
      query,
      source.token
    ).catch(err => {
      setError(err);
      handleApiError(err, []);
    });
    if (!didCancel && response) {
      if (response.parameters.length) {
        return response.parameters[0];
      }
      return null;
    }
    return null;
  };

  const handleAutocompleteChange = async (event, value, reason) => {
    let existingParam;
    if (value?.parameterTypeId) {
      existingParam = await getExistingParameter(value?.parameterTypeId);
    }
    if (existingParam) {
      setExistingParamValue(existingParam);
      setNonCustomValue({});
    } else if (reason === 'selectOption') {
      setNonCustomValue(value);
      setExistingParamValue({});
    } else {
      setNonCustomValue({});
      setExistingParamValue({});
    }
  };

  const handleSelectUnitTypeChange = (event, newValue, form, mutators) => {
    setSelectedUnitType(newValue);
    mutators.remove('unit');
  };

  const submitNewParameterTypes = values => {
    const typeId = uuidv4();
    const newParamType = {
      id: typeId,
      scopeId: projectId,
      dataType: values.dataType,
      name: values.name,
    };
    if (values.unit) {
      newParamType.defaultUnitId = values.unit;
    }
    const newParameter = {
      id: uuidv4(),
      parameterTypeId: typeId,
      dataType: values.dataType,
      status: 'unanswered',
      value: null,
      unitTypeId: values.unitType || null,
      unit: null,
      name: values.name,
      source: null,
      tags: [],
      disciplines: [],
      checked: true,
      isNewParameterType: true,
      isNewItemType: true,
    };
    push('parameterTypes', newParameter);
    push('parameterTypesImport', newParamType);
    push('itemsImport', newParameter);
    setRowSelectionModel(curr => [...curr, newParameter.parameterTypeId]);
    setOpenParameterCreator(!openParameterCreator);
  };

  const isUnitDisabled = dataType => {
    if (
      dataType === 'string' ||
      dataType === 'boolean' ||
      dataType === 'date'
    ) {
      return true;
    }
    return false;
  };

  return {
    nonCustomValue,
    setNonCustomValue,
    allUnitTypes,
    setAllUnitTypes,
    unitTypesLoading,
    setUnitTypesLoading,
    selectedUnitType,
    setSelectedUnitType,
    handleAutocompleteChange,
    handleSelectUnitTypeChange,
    getHelperText,
    availableUnits,
    setAvailableUnits,
    submitNewParameterTypes,
    isUnitDisabled,
    existingParamValue,
    setExistingParamValue,
    allParameterTypes,
    allLoading,
    setAllLoading,
    error,
  };
};
