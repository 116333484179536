import { useMediaQuery, Grid } from '@mui/material';
import { StyledTypography } from './sources-page-styles';

const GlobalSourcesTitle = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      <Grid item>
        <StyledTypography
          aria-label="drawer-title-sources"
          variant={screenSm ? 'h6' : 'h5'}
          pt={1.2}
        >
          Import global sources
        </StyledTypography>
      </Grid>
    </Grid>
  );
};

export default GlobalSourcesTitle;
