import { useState, useEffect, useContext } from 'react';
import { useImmer } from 'use-immer';
import { isEmpty, orderBy } from 'lodash';
import { formatAssetNavigation } from 'utils/format-asset-navigation';
import { PageLayoutContext, AssetContext } from 'context';

export default () => {
  const { allAssets, assetsLoading, wizardOpen, setWizardOpen } =
    useContext(AssetContext);
  const { openDrawers, setOpenDrawers } = useContext(PageLayoutContext);
  const [assetNodes, setAssetNodes] = useImmer([]);
  const [isProjectAssetHierarchy, setIsProjectAssetHierarchy] = useState(true);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [drawerWidth, setDrawerWidth] = useState('20%');

  useEffect(() => {
    const updateDrawerWidth = () => {
      const minWidth = 351;
      const percentageWidth = minWidth / window.innerWidth;
      const calculatedWidth = Math.max(0.1, percentageWidth);
      setDrawerWidth(calculatedWidth * 100 + '%');
    };

    updateDrawerWidth();
    window.addEventListener('resize', updateDrawerWidth);

    return () => {
      window.removeEventListener('resize', updateDrawerWidth);
    };
  }, []);

  const handleOpenCloseDrawer = () =>
    setOpenDrawers(curr => {
      curr.openNavigation = !openDrawers.openNavigation;
    });

  const handleOpenCloseWizard = () => setWizardOpen(!wizardOpen);

  const filterMenu = [
    {
      switchLabel: 'Project asset hierarchy',
      handleChange: () => {
        setIsProjectAssetHierarchy(!isProjectAssetHierarchy);
      },
      isChecked: isProjectAssetHierarchy,
      disabled: false,
    },
  ];

  useEffect(() => {
    if (!assetsLoading && !isEmpty(allAssets)) {
      const orderedAssets = orderBy(
        allAssets,
        [
          'deletedAt',
          asset => asset.assetType.name.toLowerCase(),
          asset => asset.name.toLowerCase(),
        ],
        ['desc', 'asc', 'asc']
      );
      const hierarchy = formatAssetNavigation(orderedAssets, null);
      setAssetNodes(hierarchy);
      setLoading(false);
    } else if (!assetsLoading && isEmpty(allAssets)) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsLoading, allAssets]);

  return {
    assetNodes,
    isProjectAssetHierarchy,
    setIsProjectAssetHierarchy,
    filterMenu,
    handleOpenCloseDrawer,
    handleOpenCloseWizard,
    openDrawers,
    expanded,
    setExpanded,
    loading,
    drawerWidth,
  };
};
