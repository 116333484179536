import { Person as MsPerson } from '@microsoft/mgt-react';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import PT from 'prop-types';

const StyledPerson = styled(MsPerson)(({ theme }) => ({
  '--initials-background-color': 'none',
  '--color': theme.palette.text.primary,
  '--line2-color': theme.palette.text.primary,
  '--line3-color': theme.palette.text.primary,
}));

function Person({ avatarSize, showPresence, ...rest }) {
  const isCypress = import.meta.env.VITE_APP_IS_CYPRESS;

  return isCypress ? (
    <Avatar>S</Avatar>
  ) : (
    <StyledPerson
      avatarSize={avatarSize}
      showPresence={showPresence}
      {...rest}
    />
  );
}

Person.propTypes = {
  avatarSize: PT.string,
  showPresence: PT.bool,
};

Person.defaultProps = {
  avatarSize: 'large',
  showPresence: true,
};

export default Person;
