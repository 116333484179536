import { forwardRef } from 'react';
import { Grid, Link, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router';
import HomePageStack from 'components/home-page-stack';
import { StyledTitleTypography } from './styles';

const CentraliseDataLink = forwardRef((props, ref) => (
  <Link
    to="/centralise-data"
    ref={ref}
    {...props}
    component={RouterLink}
    onClick={() => window.scrollTo(0, 0)}
  />
));
const DataIntegrationLink = forwardRef((props, ref) => (
  <Link
    to="/data-integration"
    ref={ref}
    {...props}
    component={RouterLink}
    onClick={() => window.scrollTo(0, 0)}
  />
));

const TrackChangesLink = forwardRef((props, ref) => (
  <Link
    to="/track-changes"
    ref={ref}
    {...props}
    component={RouterLink}
    onClick={() => window.scrollTo(0, 0)}
  />
));

CentraliseDataLink.displayName = 'CentraliseDataLink';
DataIntegrationLink.displayName = 'DataIntegrationLink';
TrackChangesLink.displayName = 'TrackChangesLink';

const DDBUseCases = () => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const propsList = [
    {
      title: 'Centralise Project Data',
      description:
        'DDB offers a centralised hub to store and manage all your project data and design decisions. No more digging through reports and emails to find the latest project information. Keep everything synchronised, consistent, and up-to-date at every stage of your projects with a trusted source.',
      imageSrc: '/illustrations/landing-page/centraliseData.svg',
      imageAlt: 'centralise data',
      LinkComponent: CentraliseDataLink,
      learnMoreText: 'Learn More',
    },
    {
      title: 'Seamless Data Integration',
      description:
        'With DDB, effortlessly connect to other design software and applications. Our powerful connectors and flexible API integrate seamlessly with tools like Grasshopper, PowerBI, DesignCheck, and Zero, ensuring smooth data flow across platforms.',
      imageSrc: '/illustrations/landing-page/integration.svg',
      imageAlt: 'integration',
      LinkComponent: DataIntegrationLink,
      learnMoreText: 'Learn More',
    },
    {
      title: 'Track Changes with Ease',
      description:
        'DDB enhances team collaboration with real-time tracking on all modifications, ensuring visibility and compliance. With audit logs and user access permissions, DDB offers transparency, control, and traceability throughout your projects.',
      imageSrc: '/illustrations/landing-page/changes.svg',
      imageAlt: 'changes',
      LinkComponent: TrackChangesLink,
      learnMoreText: 'Learn More',
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      data-testid="why-ddb"
      spacing={4}
    >
      <Grid item md={12}>
        <StyledTitleTypography
          variant={isMdUp ? 'h3' : 'h1'}
          component="h1"
          color="inherit"
        >
          Why use DDB?
        </StyledTitleTypography>
      </Grid>
      {propsList.map(props => (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            pb: theme => theme.spacing(8),
          }}
          // eslint-disable-next-line react/prop-types
          key={props.title}
        >
          <HomePageStack {...props} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DDBUseCases;
