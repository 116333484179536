import PT from 'prop-types';
import { CardHeader, Skeleton } from '@mui/material';

function ActionHeader({
  style,
  renderAvatar,
  renderTitle,
  titleTypographyProps = {},
  subheaderTypographyProps = {},
  renderAction,
  renderSubheader,
  isLoading,
  dataCy,
}) {
  return (
    <CardHeader
      sx={{
        backgroundColor: style.backgroundColour
          ? style.backgroundColour
          : theme => theme.palette.background.default,
        // eslint-disable-next-line no-confusing-arrow
        height: theme =>
          style === 'projectCardHeader' ? theme.spacing(10) : null,

        flexWrap: () => (style !== 'projectCardHeader' ? 'wrap' : 'no-wrap'),
      }}
      data-testid="card-header"
      avatar={
        isLoading ? (
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            data-testid="loading"
          />
        ) : (
          renderAvatar
        )
      }
      title={isLoading ? <Skeleton data-testid="loading" /> : renderTitle}
      titleTypographyProps={titleTypographyProps}
      data-cy={dataCy}
      subheader={
        isLoading ? <Skeleton data-testid="loading" /> : renderSubheader
      }
      subheaderTypographyProps={subheaderTypographyProps}
      action={renderAction}
    />
  );
}

ActionHeader.propTypes = {
  renderAvatar: PT.node,
  renderTitle: PT.oneOfType([PT.string, PT.bool, PT.node]),
  titleTypographyProps: PT.shape({}),
  subheaderTypographyProps: PT.shape({}),
  renderAction: PT.node,
  renderSubheader: PT.oneOfType([PT.shape({}), PT.string]),
  isLoading: PT.bool.isRequired,
  dataCy: PT.string,
  style: PT.oneOfType([PT.shape({}), PT.string]),
};

ActionHeader.defaultProps = {
  renderTitle: '',
  renderAction: {},
  renderAvatar: {},
  titleTypographyProps: {},
  subheaderTypographyProps: {},
  renderSubheader: {},
  dataCy: '',
  style: {},
};

export default ActionHeader;
