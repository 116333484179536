/* eslint-disable no-unused-vars */
import PT from 'prop-types';
import { useLocation } from 'react-router';
import qs from 'qs';
import { Check } from '@mui/icons-material';
import { Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';
import StyledMenuItem from './filter-menu-styles';

function FilterMenu({
  anchorEl,
  handleClose,
  sortByOptions,
  handleSortOptions,
  filterOptions,
  handleFilterOptions,
}) {
  const { search } = useLocation();
  const {
    order,
    sort_by: sortBy,
    ...rest
  } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      data-testid="filterMenu"
    >
      <StyledMenuItem dense disabled>
        Sort by
      </StyledMenuItem>
      {sortByOptions.map(sortOption => (
        <MenuItem
          dense
          key={sortOption.value}
          onClick={event => {
            handleSortOptions(event, sortOption);
            handleClose();
          }}
        >
          {sortOption.value === sortBy && (
            <ListItemIcon>
              <Check fontSize="small" data-testid="currentSort" />
            </ListItemIcon>
          )}
          {sortOption.label}
        </MenuItem>
      ))}
      <Divider />
      <StyledMenuItem dense disabled>
        Filter by
      </StyledMenuItem>
      {filterOptions.map(filterOption => (
        <MenuItem
          dense
          key={filterOption.title}
          onClick={event => {
            if (Object.keys(filterOption.value)[0] === Object.keys(rest)[0])
              handleFilterOptions(event, null);
            else handleFilterOptions(event, filterOption);
            handleClose();
          }}
        >
          {Object.keys(filterOption.value)[0] === Object.keys(rest)[0] && (
            <ListItemIcon>
              <Check fontSize="small" data-testid="currentFilter" />
            </ListItemIcon>
          )}
          {filterOption.title}
        </MenuItem>
      ))}
    </Menu>
  );
}

FilterMenu.propTypes = {
  anchorEl: PT.oneOfType([PT.object, PT.func]),
  handleClose: PT.func.isRequired,
  handleSortOptions: PT.func.isRequired,
  handleFilterOptions: PT.func.isRequired,
  sortByOptions: PT.arrayOf(
    PT.shape({ label: PT.string.isRequired, value: PT.string.isRequired })
  ).isRequired,
  filterOptions: PT.arrayOf(
    // eslint-disable-next-line react/forbid-prop-types
    PT.shape({ title: PT.string.isRequired, value: PT.object.isRequired })
  ).isRequired,
};

FilterMenu.defaultProps = { anchorEl: null };

export default FilterMenu;
