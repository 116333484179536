import { useContext, Suspense } from 'react';
import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { SourcesContext } from 'context';
import SourcesTable from '../sources-table';
import SourcesTitle from '../sources-titles/sources-title';
import { StyledGrid, Container, StyledTypography } from './sources-page-styles';

const SourcesContent = () => {
  const { selectedSource, sourceErrorOrSuccess } = useContext(SourcesContext);
  return (
    <Container>
      <Suspense fallback={null}>
        <Grid
          container
          sx={{ flex: 1, overflow: 'auto', flexDirection: 'row' }}
        >
          <Grid
            item
            xs={
              isEmpty(selectedSource) && isEmpty(sourceErrorOrSuccess) ? 12 : 8
            }
            sx={{
              '& .MuiPaper-root': {
                boxShadow: 0,
              },
            }}
          >
            <StyledGrid item>
              <SourcesTitle />
            </StyledGrid>
            <StyledTypography>
              Click on an individual row to view or edit source details
            </StyledTypography>
            <SourcesTable />
          </Grid>
        </Grid>
      </Suspense>
    </Container>
  );
};

export default SourcesContent;
