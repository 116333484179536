import { Grid, Typography } from '@mui/material';
import PT from 'prop-types';

const ImageSection = ({
  isMdUp,
  title,
  description,
  imageSrc,
  imageAlt,
  imageRight,
}) => {
  return (
    <Grid container direction={imageRight ? 'row' : 'row-reverse'}>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        paddingTop={theme => theme.spacing(4)}
        paddingBottom={theme => theme.spacing(4)}
      >
        <Typography
          variant={isMdUp ? 'h4' : 'h2'}
          component="h2"
          sx={{
            fontWeight: theme => theme.typography.fontWeightRegular,
          }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant={isMdUp ? 'subtitle1' : 'h6'}
          component="p"
          style={{ width: '80%', height: 'auto' }}
          fontWeight={theme => theme.typography.fontWeightRegular}
        >
          {description}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        paddingTop={theme => theme.spacing(4)}
        paddingBottom={theme => theme.spacing(4)}
      >
        <img
          src={imageSrc}
          alt={imageAlt}
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>
    </Grid>
  );
};

ImageSection.propTypes = {
  title: PT.string.isRequired,
  description: PT.string.isRequired,
  imageSrc: PT.string.isRequired,
  imageAlt: PT.string.isRequired,
  isMdUp: PT.bool.isRequired,
  imageRight: PT.bool,
};

ImageSection.defaultProps = {
  imageRight: false,
};

export default ImageSection;
