/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { useParams } from 'react-router';
import { ParametersContext } from 'context';
import { parametersApi } from 'api';

export default (
  parameter,
  setParameter,
  setOpenCommentForm,
  setError,
  type
) => {
  const { updateParametersList } = useContext(ParametersContext);
  const { parameterId } = useParams();

  const validateRejection = values => {
    const errors = {};
    if (!values.comment && type !== 'approve' && type !== 'check') {
      errors.comment = 'Required';
    }
    return errors;
  };

  const rejectParameterValue = ({ comment }) => {
    setError(undefined);
    const newUpdateStatus = async () => {
      try {
        const response = await parametersApi('patchRevisions', {
          body: {
            revisions: [
              {
                revision_id: parameter.selectedEntry.revisionId,
                status:
                  type === 'reject'
                    ? 'rejected'
                    : type === 'reset'
                      ? 'answered'
                      : type === 'check'
                        ? 'checked'
                        : 'approved',
                ...(comment && { comment }),
              },
            ],
          },
        });

        if (response) {
          const { revisions } = response;
          setParameter({
            ...parameter,
            selectedEntry: {
              ...parameter.selectedEntry,
              revisionId: revisions[0].id,
              status: revisions[0].status,
              comment: revisions[0].comment,
              updatedBy: revisions[0].updatedBy,
              updatedAt: revisions[0].updatedAt,
            },
          });
          updateParametersList(
            {
              ...parameter,
              selectedEntry: {
                ...parameter.selectedEntry,
                revisionId: revisions[0].id,
                status: revisions[0].status,
                comment: revisions[0].comment,
                updatedBy: revisions[0].updatedBy,
                updatedAt: revisions[0].updatedAt,
              },
            },
            parameterId
          );
          setOpenCommentForm(false);
        }
      } catch (err) {
        setError(err?.response?.data);
      }
    };
    newUpdateStatus();
  };

  return { rejectParameterValue, validateRejection, setOpenCommentForm };
};
