import { useState } from 'react';

export default () => {
  const [openSummary, setOpenSummary] = useState(true);
  const handleOpen = () => setOpenSummary(!openSummary);

  return {
    openSummary,
    handleOpen,
  };
};
