import PT from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';

export const PageLayoutContext = React.createContext();

export const PageLayoutProvider = ({ children }) => {
  const [openDrawers, setOpenDrawers] = useImmer({
    openNavigation: true,
    openAssetDetail: true,
    openParamList: true,
    openParamDetail: true,
  });
  const [numberOfOpen, setNumberOfOpen] = useState(4);

  const [openTemplatePanels, setOpenTemplatePanels] = useImmer({
    openTemplates: true,
    openTemplateDetails: true,
    openDataSets: true,
    openDataSetDetails: true,
  });

  const [numberOfTemplatePanelsOpen, setNumberOfTemplatePanelsOpen] =
    useState(4);

  useEffect(() => {
    const numberOfOpenDrawers = Object.values(openDrawers).filter(
      isOpen => isOpen === true
    ).length;
    setNumberOfOpen(numberOfOpenDrawers);
  }, [openDrawers]);

  useEffect(() => {
    const numberOfOpenTemplatesDrawers = Object.values(
      openTemplatePanels
    ).filter(isOpen => isOpen === true).length;
    setNumberOfTemplatePanelsOpen(numberOfOpenTemplatesDrawers);
  }, [openTemplatePanels]);

  return (
    <PageLayoutContext.Provider
      value={{
        openDrawers,
        setOpenDrawers,
        numberOfOpen,
        numberOfTemplatePanelsOpen,
        openTemplatePanels,
        setOpenTemplatePanels,
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};

PageLayoutProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
