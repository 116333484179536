import { useState, useContext } from 'react';
import PT from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Popover,
  Checkbox,
  IconButton,
  useMediaQuery,
  Tooltip,
  Grid,
} from '@mui/material';
import {
  GridRowModes,
  GridActionsCellItem,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { Edit, DeleteOutlined, Add, Save, Cancel } from '@mui/icons-material';
import { isEmpty, find, capitalize } from 'lodash';
import { DataGrid } from 'components/tables';
import { withAppInsights } from 'app/app-insights';
import { Person, PersonCard, PeoplePicker } from 'components/microsoft';
import { ProjectPermissionContext } from 'context';
import { ActionButton } from 'components/buttons';
import WarningDialog from 'components/dialogs/warning';
import CustomAlert from 'components/alerts';
import StyledBox from './project-settings-styles';
import useProjectPermissions from './hooks';

function ProjectUserSettings({ projectId }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { adminPermission, projectRoles } = useContext(
    ProjectPermissionContext
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEmail(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popId = popoverOpen ? 'simple-popover' : undefined;

  const {
    projectPermissions,
    permissionsLoading,
    updateUsers,
    resetUpdateUserError,
    rowModesModel,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    handleProcessRowUpdateError,
    handleAddUsers,
    openAdminPermissionWarning,
    openDeletePermissionWarning,
    handleAcceptDelete,
    handleCancelDelete,
    columnVisibilityModel,
    setColumnVisibilityModel,
    openReaderPermissionWarning,
    openAllPermissionWarning,
    openRemoveReaderWithOtherRoles,
  } = useProjectPermissions(projectId);

  const renderCheckbox = ({ id, value, field }) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    return (
      <Checkbox
        checked={value}
        disabled={!isInEditMode}
        inputProps={{
          'aria-label': `checkbox-${field}`,
          'data-cy': `checkbox-${field}`,
        }}
      />
    );
  };

  const hiddenFields = ['arupUser', 'user'];
  const getTogglableColumns = columns => {
    return columns
      .filter(column => !hiddenFields.includes(column.field))
      .map(column => column.field);
  };

  // eslint-disable-next-line react/prop-types
  function CustomUserEdit({ id: currentId, field: currentField, ...rest }) {
    const apiRef = useGridApiContext();

    const handleValueChange = event => {
      const newValue = event.target.selectedPeople.map(person => {
        return person.mail || person.userPrincipalName;
      });
      apiRef.current.setEditCellValue({
        id: currentId,
        field: currentField,
        value: newValue,
      });
    };

    return (
      <PeoplePicker selectionChanged={handleValueChange} error={rest.error} />
    );
  }

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode)
          return [
            <Tooltip key="save" title="Save">
              <GridActionsCellItem
                sx={{ padding: theme.spacing(0.5), margin: 0 }}
                data-cy="save-role"
                icon={
                  <span>
                    <Save color="primary" />
                  </span>
                }
                onClick={handleSaveClick(id)}
                label="Save"
              />
            </Tooltip>,
            <Tooltip key="cancel" title="Cancel">
              <GridActionsCellItem
                sx={{ padding: theme.spacing(0.5), margin: 0 }}
                icon={
                  <span>
                    <Cancel color="primary" />
                  </span>
                }
                onClick={handleCancelClick(id)}
                data-cy="cancel-save-role"
                label="Cancel"
                className="textPrimary"
                color="inherit"
              />
            </Tooltip>,
          ];

        return [
          <Tooltip
            key="edit"
            title={
              !adminPermission
                ? "You don't have permission to do this"
                : 'Edit permissions'
            }
          >
            <GridActionsCellItem
              sx={{ padding: theme.spacing(0.5), margin: 0 }}
              data-cy="edit-role"
              icon={
                <span>
                  <Edit color="primary" />
                </span>
              }
              onClick={handleEditClick(id)}
              label="Edit"
              className="textPrimary"
              color="inherit"
              disabled={!adminPermission}
            />
          </Tooltip>,
          <Tooltip
            key="delete"
            title={
              !adminPermission
                ? "You don't have permission to do this"
                : 'Remove user permissions'
            }
          >
            <GridActionsCellItem
              sx={{ padding: theme.spacing(0.5), margin: 0 }}
              data-cy="delete-role"
              icon={
                <span>
                  <DeleteOutlined color="primary" />
                </span>
              }
              onClick={handleDeleteClick(id, row)}
              label="Delete"
              color="inherit"
              disabled={!adminPermission}
            />
          </Tooltip>,
        ];
      },
    },
    {
      headerName: 'Arup Staff',
      description: 'Is an Arup staff member',
      field: 'arupUser',
      hideable: false,
      editable: false,
      width: 100,
      valueGetter: (value, row) => row?.internal,
      display: 'flex',
      renderCell: ({ value }) => (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {
            // eslint-disable-next-line no-nested-ternary
            value === true ? 'Yes' : value === false ? 'No' : ''
          }
        </Grid>
      ),
    },
    {
      headerName: 'User',
      field: 'user',
      hideable: false,
      editable: true,
      minWidth: matches ? 80 : 250,
      display: 'flex',
      valueGetter: (value, row) => row.email,
      renderEditCell: CustomUserEdit,
      preProcessEditCellProps: params => {
        const hasError = isEmpty(params.props.value);
        return { ...params.props, error: hasError };
      },
      renderCell: ({ value }) => (
        <IconButton aria-describedby={popId} onClick={handleClick}>
          <Person
            view={matches ? 0 : 4}
            personQuery={value}
            fallbackDetails={{
              name: value,
              userPrincipalName: value,
              mail: value,
            }}
          />
        </IconButton>
      ),
    },
    ...projectRoles.map(({ name, id }) => ({
      description: name,
      headerName: capitalize(name),
      'data-cy': name,
      field: name,
      valueGetter: (value, row) => !!find(row.roles, { name }),
      flex: 0.5,
      type: 'boolean',
      renderCell: renderCheckbox,
      editable: true,
      id,
    })),
  ];

  const handleMuiDefaultBehaviour = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const warningDialogTitle = () => {
    if (openReaderPermissionWarning.open) return 'Delete read permission?';
    if (openAllPermissionWarning.open) return 'Delete user permissions?';
    if (openRemoveReaderWithOtherRoles.open)
      return `${'Remove user permissions'}?`;
    return 'Delete administrator permissions?';
  };

  const warningDialogBody = () => {
    if (
      openReaderPermissionWarning.open &&
      openReaderPermissionWarning.oldRow.internal
    ) {
      return 'Remove internal user? Internal Arup staff will still have read access to this non-confidential project';
    }
    if (
      openReaderPermissionWarning.open &&
      !openReaderPermissionWarning.oldRow.internal
    ) {
      return 'Remove external user? Non-Arup staff need read access to view projects. They will no longer be able to open this project';
    }
    if (openAllPermissionWarning.open)
      return "Remove user access? This project is confidential, if you remove this user they won't be able to read it";
    if (openRemoveReaderWithOtherRoles.open)
      return 'Cannot remove read permission from user with other roles. If you continue the user will be removed from the project.';
    return 'You will no longer be able to add or edit users to this project';
  };

  return (
    <StyledBox aria-label="project-settings-roles">
      <Tooltip
        title={!adminPermission ? "You don't have permission to do this" : ''}
      >
        <span>
          <ActionButton
            sx={{ marginBottom: theme.spacing(1) }}
            aria-label="Add users"
            data-cy="add-user"
            disabled={!adminPermission}
            startIcon={<Add />}
            onClick={handleAddUsers}
          >
            {'Add Users'.toLocaleUpperCase()}
          </ActionButton>
        </span>
      </Tooltip>
      <br />
      {updateUsers.status === 'error' && (
        <>
          <Alert
            severity="error"
            onClose={resetUpdateUserError}
            data-cy="error-alert"
            aria-label="error-alert"
          >
            {updateUsers.error.details && `${updateUsers.error.details}`}
          </Alert>
          <br />
        </>
      )}
      <CustomAlert
        ariaLabel="guidance-note"
        severity="info"
        variant="outlined"
        title="By default, all internal Arup users are readers of non-confidential projects, but only those with assigned permissions can read confidential projects"
        boxStyle={{
          padding: theme.spacing(0.25),
          '& .MuiAlert-message': { padding: 0 },
          alignItems: 'center',
          marginBottom: theme.spacing(0.75),
        }}
        titleStyle={{
          margin: theme.spacing(0),
        }}
      />
      <DataGrid
        columns={columns}
        height="65vh"
        rows={projectPermissions}
        onRowEditStart={handleMuiDefaultBehaviour}
        onRowEditStop={handleMuiDefaultBehaviour}
        processRowUpdate={processRowUpdate}
        rowModesModel={rowModesModel}
        getRowId={row => row.userId}
        aria-label="rolesTable"
        onCellClick={({ value }) => setEmail(value)}
        disableSelectionOnClick
        loading={permissionsLoading}
        experimentalFeatures={{ newEditingApi: true }}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        onCellDoubleClick={handleMuiDefaultBehaviour}
        isCellEditable={params => {
          if (params.row.isNew) return true;
          if (params.field === 'administrator' && params.row.internal === false)
            return false;
          return params.field !== 'user';
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={newModel =>
          setColumnVisibilityModel(newModel)
        }
        slotProps={{
          toolbar: { exportName: `project-${projectId}-permissions` },
          columnsPanel: {
            getTogglableColumns,
          },
        }}
      />
      <Popover
        id={popId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PersonCard isExpanded={false} personQuery={email} />
      </Popover>
      {(openAdminPermissionWarning ||
        openDeletePermissionWarning ||
        openReaderPermissionWarning.open ||
        openAllPermissionWarning.open) && (
        <WarningDialog
          aria-label="permission-warning"
          open={
            !!(
              openAdminPermissionWarning.open ||
              openDeletePermissionWarning ||
              openReaderPermissionWarning.open ||
              openAllPermissionWarning.open ||
              openRemoveReaderWithOtherRoles.open
            )
          }
          handleAction={handleAcceptDelete}
          handleClose={handleCancelDelete}
          secondaryButton
          title={warningDialogTitle()}
          body={warningDialogBody()}
          primaryButtonText="Accept"
          secondaryButtonText="Cancel"
        />
      )}
    </StyledBox>
  );
}

ProjectUserSettings.propTypes = { projectId: PT.string.isRequired };

export default withAppInsights(ProjectUserSettings, 'ProjectRolesPage');
