import { Grid, useMediaQuery } from '@mui/material';
import PT from 'prop-types';
import ExpandButton from 'components/drawer/permanent/expand-button';
import {
  FoldedStyledTypography,
  ExpandedStyledTypography,
} from './folding-header-styles';

const FoldingHeader = ({
  title,
  open,
  onClick,
  disabled,
  collapsedTooltip,
}) => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  return (
    <>
      {open && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingTop: theme => theme.spacing(0),
            height: title
              ? theme => theme.spacing(7.5)
              : theme => theme.spacing(2.5),
          }}
        >
          <Grid item xs={9} sm={10} md={10} lg={11} xl={11}>
            <ExpandedStyledTypography
              aria-label="expanded-drawer-title"
              variant={screenSm ? 'h6' : 'h5'}
              component="h1"
            >
              {title}
            </ExpandedStyledTypography>
          </Grid>
          <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
            <Grid container justifyContent="flex-end">
              <ExpandButton
                disabled={disabled}
                open={open}
                toggleDrawerOpen={onClick}
                title={title}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!open && (title || collapsedTooltip) && (
        <Grid
          container
          justifyContent="center"
          sx={{ paddingTop: theme => theme.spacing(1.5) }}
        >
          <ExpandButton
            disabled={disabled}
            open={open}
            toggleDrawerOpen={onClick}
            title={title}
          />
          <Grid container justifyContent="center">
            <FoldedStyledTypography
              aria-label="folded-drawer-title"
              variant="body2"
            >
              {title || collapsedTooltip}
            </FoldedStyledTypography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

FoldingHeader.propTypes = {
  title: PT.string.isRequired,
  collapsedTooltip: PT.string,
  open: PT.bool.isRequired,
  onClick: PT.func.isRequired,
  disabled: PT.bool,
};
FoldingHeader.defaultProps = {
  disabled: false,
  collapsedTooltip: null,
};
export default FoldingHeader;
