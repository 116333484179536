import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useImmer } from 'use-immer';
import { handleApiError, parametersApi } from 'api';
import { AssetContext } from 'context';

export default open => {
  const { projectId, assetId } = useParams();
  const [availableParameterTypesLoading, setAvailableParameterTypesLoading] =
    useState(true);
  const [availableParameterTypes, setAvailableParameterTypes] = useImmer([]);
  const { theAsset, isLoading: isAssetLoading } = useContext(AssetContext);
  const [openImportDrawer, setOpenImportDrawer] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(undefined);

  const handleOpen = () => setOpenImportDrawer(!openImportDrawer);

  useEffect(() => {
    let didCancel = false;
    const getParameterTypes = async after => {
      const query = {
        not_project_id: projectId,
        page_limit: 100,
        sort_by: 'name',
        order: 'asc',
        scope_id: projectId,
        show_local: true,
        show_global: true,
      };

      if (assetId) {
        if (theAsset?.assetSubType?.id)
          query.asset_sub_type_id = theAsset?.assetSubType?.id;
        else query.asset_type_id = theAsset?.assetType?.id;
        query.not_asset_id = theAsset?.id;
      } else {
        query.asset_type_id = '';
        query.not_asset_id = '';
      }
      if (after) {
        query.after = after;
      }
      const response = await parametersApi('getAllParameterTypes', query).catch(
        err => {
          handleApiError(err, []);
          setError(err);
          setAvailableParameterTypesLoading(false);
        }
      );
      if (!didCancel && response) {
        const { parameterTypes: data, paging } = response;
        const mappedResponse = data.map(type => {
          return {
            id: uuidv4(),
            parameterTypeId: type.id,
            dataType: type.dataType,
            status: 'unanswered',
            value: null,
            unitTypeId: type.unitType?.id || null,
            unit: null,
            name: type.name,
            source: null,
            tags: [],
            disciplines: [],
            checked: true,
            isNewItemType: false,
            isNewParameterType: false,
          };
        });
        setAvailableParameterTypes(curr => [...curr, ...mappedResponse]);
        if (paging?.cursors?.after) {
          getParameterTypes(paging.cursors.after);
        }
        setAvailableParameterTypesLoading(false);
      }
    };

    if (projectId && open && !isAssetLoading) {
      setAvailableParameterTypes([]);
      getParameterTypes();
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, open, theAsset, isAssetLoading]);

  return {
    availableParameterTypesLoading,
    setAvailableParameterTypesLoading,
    availableParameterTypes,
    openImportDrawer,
    setOpenImportDrawer,
    handleOpen,
    inputValue,
    setInputValue,
    error,
  };
};
