import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { compact, find } from 'lodash';
import { UserContext } from 'context';
import { userApi, environmentContextApi } from 'api';

export default () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [userPermissions, setUserPermissions] = useState([]);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getPermissions = async () => {
      setLoading(true);
      setError(undefined);

      try {
        const response = await userApi(
          'getUsersRoles',
          { emails: [user.email] },
          source.token
        );

        if (!didCancel && response) {
          const { usersRoles } = response;
          const projectResponse = await environmentContextApi(
            'getProjects',
            {
              project_id: compact([
                ...new Set(usersRoles[0]?.roles?.map(role => role.resourceId)),
              ]),
            },
            source.token
          );
          if (!didCancel && projectResponse) {
            const { projects } = projectResponse;
            const newPermissions = usersRoles.reduce((final, { roles }) => {
              roles.forEach(({ resourceId, name }) => {
                const project = find(final, { id: resourceId });
                if (project) project.roles.push(name);
                else {
                  const userProject = find(projects, {
                    projectId: resourceId,
                  });
                  if (userProject)
                    final.push({
                      id: resourceId,
                      projectNumber: userProject.projectNumber,
                      shortTitle: userProject.shortTitle,
                      roles: [name],
                    });
                }
              });
              return final;
            }, []);

            setUserPermissions(newPermissions);
          }
          setLoading(false);
        }
      } catch (err) {
        if (err?.response?.status === 503)
          navigate('/error', {
            replace: true,
            state: {
              status: err.response.status,
              ...err.response.data,
            },
          });
        else {
          setError(err?.response?.data);
          setLoading(false);
        }
      }
    };

    if (user.email) getPermissions();

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { userPermissions, error, loading };
};
