import { Security, GroupAdd } from '@mui/icons-material';

export default [
  {
    path: `../roles`,
    text: 'Roles',
    icon: <GroupAdd />,
    userAccess: true,
    'data-cy': 'settings-roles',
  },
  {
    path: `../security`,
    text: 'Security',
    icon: <Security />,
    userAccess: true,
    'data-cy': 'settings-security',
  },
];
