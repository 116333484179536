import PT from 'prop-types';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function Actions({
  toggleOpen,
  handleClose,
  addedProject,
  postProject,
  addingProject,
  selectedProject,
  addingProjectErrors,
  projectExists,
}) {
  const navigate = useNavigate();

  const buttonNavigation = () => {
    if (addedProject || (projectExists && projectExists !== 'checking'))
      return 'Go to Project';
    return 'Add';
  };

  const goToProject = () => {
    toggleOpen();
    navigate(`/projects/${addedProject || projectExists}`);
  };

  return (
    <>
      <Button
        aria-label="close-add-project"
        onClick={handleClose}
        data-cy="close-add-project"
      >
        {addedProject ? 'Close' : 'Cancel'}
      </Button>
      <LoadingButton
        aria-label="confirm-add-project"
        onClick={addedProject || projectExists ? goToProject : postProject}
        color="primary"
        variant="contained"
        loading={addingProject || projectExists === 'checking'}
        data-cy="confirm-add-project"
        disabled={!selectedProject || !!addingProjectErrors || addingProject}
      >
        {buttonNavigation()}
      </LoadingButton>
    </>
  );
}

Actions.propTypes = {
  addingProject: PT.bool.isRequired,
  toggleOpen: PT.func.isRequired,
  handleClose: PT.func.isRequired,
  postProject: PT.func.isRequired,
  addedProject: PT.oneOfType([PT.string, PT.bool]).isRequired,
  selectedProject: PT.shape({
    projectNumber: PT.oneOfType([PT.number, PT.string]).isRequired,
    shortTitle: PT.string.isRequired,
  }),
  addingProjectErrors: PT.oneOfType([PT.bool, PT.object]).isRequired,
  projectExists: PT.oneOfType([PT.string, PT.bool]).isRequired,
};

Actions.defaultProps = {
  selectedProject: undefined,
};

export default Actions;
