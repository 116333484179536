/* eslint-disable no-nested-ternary */
import { Suspense, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { List as ListIcon } from '@mui/icons-material';
import {
  useNavigate,
  useParams,
  useLocation,
  createSearchParams,
} from 'react-router';
import qs from 'qs';
import EmptyState from 'components/empty-state';
import InfiniteList from 'components/infinite-list';
import Alert from 'components/alerts';
import { ParameterCard } from 'components/cards';
import { ParametersContext, PageLayoutContext } from 'context';
import { FoldingDrawer } from 'components/drawer';
import Filters from 'components/filters';
import PageLayoutBox from 'components/layout/page-layout-styles';
import handleFilters from 'components/filters/hooks/index';
import formatParameterInfo from '../helpers';
import { StyledBox } from '../parameters-page-styles';
import ToggleFilter from '../../../components/toggle-filter/index';
import Header from './header';

function ParameterList() {
  const { openDrawers, setOpenDrawers, numberOfOpen } =
    useContext(PageLayoutContext);
  const { parameters, loading, getParameters, pageLimit, error, after } =
    useContext(ParametersContext);
  const { parameterId, projectId, assetId } = useParams();
  const [paramSelected, setParamSelected] = useState('');
  const [paramFiltersActiveCount, setParamFiltersActiveCount] = useState(0);
  const { search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };
  const {
    sortByOptions,
    filters,
    applyFilters,
    filtersMap,
    handleOnChange,
    handleOnCancel,
    clearFilters,
    isFilterActive,
    handleFilterActive,
  } = handleFilters(setParamFiltersActiveCount);
  const navigate = useNavigate();
  const resetToParamListView = () => {
    navigate({
      pathname: assetId
        ? `/projects/${projectId}/asset/${assetId}/parameters`
        : `/projects/${projectId}/parameters`,

      search: `?${createSearchParams({ ...searchString })}`,
    });
    setParamSelected('');
  };

  return (
    <FoldingDrawer
      open={openDrawers.openParamList}
      tooltip="Parameter list"
      width={numberOfOpen > 2 ? '20%' : '30%'}
      onClick={() =>
        setOpenDrawers(curr => {
          curr.openParamList = !openDrawers.openParamList;
        })
      }
    >
      <PageLayoutBox>
        <Suspense fallback={null}>
          <StyledBox>
            <Suspense fallback={null}>
              <Header
                handleFilterActive={handleFilterActive}
                isFilterActive={isFilterActive}
                filterCount={paramFiltersActiveCount}
                setFilterCount={setParamFiltersActiveCount}
              />
            </Suspense>
            {isFilterActive ? (
              <Suspense fallback={null}>
                <ToggleFilter type="deletedParamFilter" />
                <Filters
                  sortByOptions={sortByOptions}
                  filters={filters}
                  applyFilters={applyFilters}
                  filtersMap={filtersMap}
                  handleOnChange={handleOnChange}
                  handleOnCancel={handleOnCancel}
                  clearFilters={clearFilters}
                />
              </Suspense>
            ) : !loading && isEmpty(parameters) ? (
              <Suspense fallback={null}>
                {error ? (
                  <div style={{ padding: theme => theme.spacing(2) }}>
                    <Alert title={error.msg} text={error.details} />
                  </div>
                ) : (
                  <EmptyState
                    Icon={ListIcon}
                    title="No parameters"
                    subtitle="Try changing your filter or asset selection"
                  />
                )}
              </Suspense>
            ) : (
              <InfiniteList
                loadMoreRows={getParameters}
                hasNextPage={!!after}
                itemSize={70}
                list={parameters}
                loading={loading}
                loaderNumber={pageLimit}
                loader={<ParameterCard loading />}
              >
                {parameters.map(parameter => {
                  const updatedParameter = formatParameterInfo(parameter);
                  return (
                    <ParameterCard
                      key={parameter.id}
                      loading={loading}
                      parameter={updatedParameter}
                      handleClick={() => {
                        if (paramSelected === parameter.id) {
                          if (openDrawers.openParamDetail) {
                            resetToParamListView();
                          } else {
                            setParamSelected(parameter.id);
                            navigate({
                              pathname: `${parameter.id}`,
                              search: `?${createSearchParams({
                                ...searchString,
                              })}`,
                            });
                          }
                        } else {
                          setParamSelected(parameter.id);
                          navigate({
                            pathname: `${parameter.id}`,
                            search: `?${createSearchParams({
                              ...searchString,
                            })}`,
                          });
                        }
                      }}
                      selected={parameterId === parameter.id}
                    />
                  );
                })}
              </InfiniteList>
            )}
          </StyledBox>
        </Suspense>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default ParameterList;
