import { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from 'context';

export default () => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogout = () => {
    handleClose();
    navigate('/');
    logout();
  };

  return { anchorEl, navigate, userLogout, handleMenu, handleClose };
};
