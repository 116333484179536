import PT from 'prop-types';
import { capitalize } from 'lodash';
import { useLocation, useNavigate, createSearchParams } from 'react-router';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { DataGrid } from 'components/tables';

export default function TemplatesList({
  rows,
  loading,
  rowSelectionModel,
  setRowSelectionModel,
}) {
  const [dataGridHeight, setDataGridHeight] = useState(200);

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

  const templatesColumns = [
    {
      flex: 0.5,
      field: 'name',
      headerName: 'Template Name',
      type: 'string',
      valueGetter: value => capitalize(value),
      renderCell: ({ row, value }) => {
        if (row.publishedAt) {
          return <span>{capitalize(value)}</span>;
        }
        return (
          <span>
            {capitalize(value)} (<strong>Draft</strong>)
          </span>
        );
      },
    },
    {
      flex: 0.5,
      field: 'description',
      headerName: 'Description',
      hideable: false,
      valueGetter: value => capitalize(value),
    },
  ];

  useEffect(() => {
    const updateHeight = () => {
      setDataGridHeight(window.innerHeight - 200);
    };
    updateHeight();

    // Update height on window resize
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <DataGrid
      sx={{ borderRadius: 0 }}
      loading={loading}
      density="standard"
      disableColumnMenu
      columns={templatesColumns}
      rows={rows}
      rowHeight={32}
      height={dataGridHeight}
      aria-label="dataTemplatesTable"
      showToolbar={false}
      noRowsMessage="No Parameter Sets Found"
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={newRowSelectionModel => {
        const searchParams = `?${createSearchParams({
          ...searchString,
        })}`;

        navigate({
          pathname: `template/${newRowSelectionModel[0]}`,
          search: searchParams,
        });
        setRowSelectionModel(newRowSelectionModel);
      }}
    />
  );
}

TemplatesList.propTypes = {
  rows: PT.arrayOf(PT.shape({})).isRequired,
  loading: PT.bool.isRequired,
  rowSelectionModel: PT.arrayOf(PT.string).isRequired,
  setRowSelectionModel: PT.func.isRequired,
};
