import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import axios from 'axios';
import { parameterMetadataApi, handleApiError } from 'api';

export default () => {
  const [isTagLoading, setIsTagLoading] = useState(true);
  const [projectTags, setProjectTags] = useImmer([]);
  const { projectId } = useParams();

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getProjectTags = async after => {
      const query = {
        reference_id: projectId,
      };
      if (after) query.after = after;
      const response = await parameterMetadataApi(
        'getTags',
        query,
        source.token
      ).catch(err => handleApiError(err, []));

      if (response && !didCancel) {
        const { tags, paging } = response;
        setProjectTags(curr => [...curr, ...tags]);
        if (paging?.cursors?.after) {
          getProjectTags(paging?.cursors?.after);
        }
        setIsTagLoading(false);
      }
    };

    if (projectId && isTagLoading) {
      getProjectTags();
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    projectTags,
    setProjectTags,
    isTagLoading,
  };
};
