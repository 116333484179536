import { useNavigate, useLocation } from 'react-router';
import { Grid, Typography } from '@mui/material';
import { withAppInsights } from 'app/app-insights';
import { ActionButton } from 'components/buttons';
import StyledImage from './styles';

function ErrorPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const Image = () => {
    if (state) {
      if (String(state.status).startsWith('5'))
        return <StyledImage src="/photos/500.png" alt="service unavailable" />;
      if (String(state.status) === '400')
        return <StyledImage src="/photos/error.png" alt="error" />;
    }
    return <StyledImage src="/photos/404.png" alt="not found" />;
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '90vh', width: '100%', padding: 10 }}
      data-testid="error-page"
    >
      <Grid item>
        <Image />
      </Grid>
      <Grid item sm>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h2" align="center">
              Whoops! Something went wrong...
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" align="center">
              {state
                ? `${state.status} - ${
                    state?.msg ||
                    'An error occurred, please refresh the page and try again'
                  }`
                : `404 - ${'Not Found'}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" align="center">
              {state?.details ?? 'The page you requested could not be found'}
            </Typography>
          </Grid>
          <Grid item>
            <ActionButton onClick={() => navigate('/')}>
              GO TO HOMEPAGE
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAppInsights(ErrorPage, 'ErrorPage');
