import React, { useState, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router';
import { uniqBy, isEmpty } from 'lodash';
import axios from 'axios';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import qs from 'qs';
import { templateApi, parametersApi } from 'api';

export const DataTemplatesContext = React.createContext();

export const DataTemplatesProvider = ({ children }) => {
  const { dataTemplateId } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dataTemplates, setDataTemplates] = useImmer([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
  const [dataTemplate, setDataTemplate] = useState({});
  const [templateAssets, setTemplateAssets] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const query = { ...parsedSearch };
  const { projectId } = useParams();
  const [openTemplateWizard, setOpenTemplateWizard] = useState(false);
  const [assetNodes, setAssetNodes] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [templateParameters, setTemplateParameters] = useState([]);
  const [loadingParameters, setLoadingParameters] = useState(true);
  const [templateParameterTypes, setTemplateParameterTypes] = useState([]);
  const [formattedAssets, setFormattedAssets] = useState({});

  useEffect(() => {
    const source = axios.CancelToken.source();
    const paramQuery = {};
    const getTemplateParameters = async after => {
      setLoadingParameters(true);
      paramQuery.template_id = dataTemplateId;
      if (after) paramQuery.after = after;
      try {
        const response = await templateApi(
          'getParameterPlaceholders',
          paramQuery,
          source.token
        );
        if (response) {
          const { parameterPlaceholders, paging } = response;
          setTemplateParameters(curr =>
            uniqBy([...curr, ...parameterPlaceholders], 'id')
          );
          if (paging?.cursors?.after) {
            await getTemplateParameters(paging?.cursors?.after);
          }
        }
        setLoadingParameters(false);
      } catch (err) {
        setError(err.response.data);
        setLoadingParameters(false);
      }
    };

    if (dataTemplateId && !isEmpty(dataTemplate)) {
      setTemplateParameters([]);
      getTemplateParameters();
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate]);

  useEffect(() => {
    setTemplateParameterTypes([]);
    const source = axios.CancelToken.source();
    const didCancel = false;
    const getParameterTypes = async after => {
      const typeIds = templateParameters.map(parameters => {
        return parameters.parameterTypeId;
      });
      const typeQuery = {
        sort_by: 'name',
        order: 'asc',
        parameter_type_id: [...typeIds],
      };
      if (after) typeQuery.after = after;
      try {
        const response = await parametersApi(
          'getAllParameterTypes',
          typeQuery,
          source.token
        );
        if (response) {
          const { parameterTypes, paging } = response;
          if (!didCancel && parameterTypes) {
            setTemplateParameterTypes(current => [
              ...current,
              ...parameterTypes,
            ]);
            // eslint-disable-next-line max-depth
            if (paging?.cursors?.after) {
              getParameterTypes(paging.cursors.after);
            }
          }
        }
      } catch (err) {
        setError(err.response.data);
      }
    };
    if (!isEmpty(templateParameters) && !loadingParameters) {
      getParameterTypes();
    }
    return () => {
      source.cancel();
    };
  }, [templateParameters, loadingParameters]);

  useEffect(() => {
    if (!isEmpty(templateAssets)) {
      const parametersWithType =
        !isEmpty(templateParameters) && !isEmpty(templateParameterTypes)
          ? templateParameters.reduce((tot, curr) => {
              const typeWithName = templateParameterTypes.find(
                type => type.id === curr.parameterTypeId
              );
              tot.push({ ...curr, parameterTypeName: typeWithName?.name });
              return tot;
            }, [])
          : [];

      const formatNestedAssets = (assetList, parentId = null) => {
        return assetList.reduce((total, curr) => {
          if (curr.parentAssetPlaceholderId === parentId) {
            const obj = { ...curr };
            if (
              !isEmpty(templateParameters) &&
              !isEmpty(templateParameterTypes)
            ) {
              const filteredParams = parametersWithType.filter(
                parameter => parameter.assetPlaceholderId === curr.id
              );
              obj.parameters = [...filteredParams];
            }
            const nestedChildren = formatNestedAssets(assetList, curr.id);
            if (nestedChildren.length) obj.children = nestedChildren;
            total.push(obj);
          }
          return total;
        }, []);
      };
      const nestedAssets = formatNestedAssets(templateAssets, null);
      setFormattedAssets({ ...nestedAssets[0] });
    } else {
      setFormattedAssets({});
    }
  }, [templateAssets, templateParameters, templateParameterTypes]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const paramQuery = {};
    const getTemplateParameters = async after => {
      setLoadingParameters(true);
      paramQuery.template_id = dataTemplateId;
      if (after) paramQuery.after = after;
      try {
        const response = await templateApi(
          'getParameterPlaceholders',
          paramQuery,
          source.token
        );
        if (response) {
          const { parameterPlaceholders, paging } = response;
          setTemplateParameters(curr =>
            uniqBy([...curr, ...parameterPlaceholders], 'id')
          );
          if (paging?.cursors?.after) {
            await getTemplateParameters(paging?.cursors?.after);
          }
        }
        setLoadingParameters(false);
      } catch (err) {
        setError(err.response.data);
        setLoadingParameters(false);
      }
    };

    if (dataTemplateId && !isEmpty(dataTemplate)) {
      setTemplateParameters([]);
      getTemplateParameters();
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate]);

  const handleOpenCloseWizard = () => {
    setOpenTemplateWizard(!openTemplateWizard);
  };

  useEffect(() => {
    setAssetNodes([]);
    const source = axios.CancelToken.source();
    const getDataTemplates = async after => {
      setLoading(true);
      if (after) query.after = after;
      if (query.show_active_instances === 'true') {
        query.project_id = projectId;
        delete query.show_active_instances;
      } else {
        delete query.project_id;
        delete query.show_active_instances;
      }
      try {
        const response = await templateApi('getTemplates', query, source.token);
        if (response) {
          const { templates, paging } = response;
          setDataTemplates(currentDataTemplates =>
            uniqBy([...currentDataTemplates, ...templates], 'id')
          );
          if (paging?.cursors?.after) {
            await getDataTemplates(paging?.cursors?.after);
          } else {
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.response?.status === 503)
          navigate('/error', {
            replace: true,
            state: {
              status: err.response.status,
              ...err.response.data,
            },
          });
        else {
          setLoading(false);
          setError(err.response?.data);
        }
      }
    };

    if (
      (pathname.includes('/templates') && search) ||
      (isEmpty(dataTemplates) && dataTemplateId) ||
      (isEmpty(dataTemplates) && pathname.includes('/templates'))
    ) {
      setDataTemplates([]);
      setAssetNodes([]);
      getDataTemplates();
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const getTemplate = () => {
      const filteredParameterSetTemplate = dataTemplates.find(
        setType => setType.id === dataTemplateId
      );
      setDataTemplate(filteredParameterSetTemplate);
      setRowSelectionModel([filteredParameterSetTemplate?.id]);
      setLoading(false);
    };

    if (
      (dataTemplateId || pathname.includes(dataTemplateId)) &&
      dataTemplates.length
    ) {
      getTemplate();
    } else if (
      !(dataTemplateId || pathname.includes(dataTemplateId)) &&
      dataTemplates.length
    ) {
      setLoading(false);
      setDataTemplate({});
      setRowSelectionModel([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId, dataTemplates]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getTemplateAssets = async after => {
      setLoadingAssets(true);
      const assetQuery = { template_id: dataTemplateId, sort_by: 'name' };
      if (after) query.after = after;
      try {
        const response = await templateApi(
          'getAssetPlaceholders',
          assetQuery,
          source.token
        );
        if (response) {
          const { assetPlaceholders, paging } = response;
          const mappedPlaceholders = assetPlaceholders.map(
            ({ count, maxCount, ...rest }) => {
              return {
                maxCount: maxCount === undefined ? count : maxCount,
                ...rest,
              };
            }
          );
          setTemplateAssets(curr =>
            uniqBy([...curr, ...mappedPlaceholders], 'id')
          );
          if (paging?.cursors?.after) {
            await getTemplateAssets(paging?.cursors?.after);
          }
        }
        setLoadingAssets(false);
      } catch (err) {
        setError(err?.response?.data);
        setLoadingAssets(false);
      }
    };

    if (dataTemplateId && !isEmpty(dataTemplate)) {
      setTemplateAssets([]);
      setAssetNodes([]);
      getTemplateAssets();
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate]);

  return (
    <DataTemplatesContext.Provider
      value={{
        dataTemplates,
        setDataTemplates,
        loading,
        error,
        dataTemplate,
        query,
        openTemplateWizard,
        setOpenTemplateWizard,
        handleOpenCloseWizard,
        templateAssets,
        loadingAssets,
        assetNodes,
        setAssetNodes,
        rowSelectionModel,
        setRowSelectionModel,
        templateParameters,
        loadingParameters,
        formattedAssets,
      }}
    >
      {children}
    </DataTemplatesContext.Provider>
  );
};

DataTemplatesProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
