import GroupedHistoriesByDate from '../grouped-histories/grouped-histories-by-date';
import Container from './history-page-styles';

const HistoryContent = () => {
  return (
    <Container aria-label="history-container">
      <GroupedHistoriesByDate />
    </Container>
  );
};

export default HistoryContent;
