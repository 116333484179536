/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { Save as SaveIcon, Done } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import config from 'config';
import { AssetContext } from 'context';

export default (
  activeStep,
  handleClose,
  handleBack,
  handleNext,
  handleFinish,
  values,
  errors,
  submitting,
  submitError
) => {
  const { allAssets, isLoading } = useContext(AssetContext);

  const actionButtons = {
    parameters: [
      {
        type: 'cancel',
        button: 'Cancel',
        onClick: handleClose,
        dataCy: 'wizard-cancel-button',
        sx: { marginRight: 'auto' },
        disabled: false,
      },
      {
        type: 'back',
        button: 'Back',
        onClick: () => handleBack(),
        dataCy: 'wizard-back-button',
        disabled: activeStep === 0,
      },
      {
        type: 'finish',
        button:
          activeStep === 3 && submitError
            ? 'Retry'
            : activeStep === 3
              ? 'Finish'
              : 'Next',
        onClick: activeStep !== 3 ? () => handleNext() : () => handleFinish(),
        dataCy:
          activeStep === 3 ? 'wizard-finish-button' : 'wizard-next-button',
        disabled:
          (activeStep === 0 &&
            config.featureFlags.isCreateItemEnabled &&
            isEmpty(values.parameterTypes) &&
            isEmpty(values.itemsImport)) ||
          (activeStep === 0 &&
            !config.featureFlags.isCreateItemEnabled &&
            isEmpty(values.parameterTypes)) ||
          (activeStep === 1 &&
            !errors?.parameterTypes?.every(parameter => isEmpty(parameter))) ||
          (activeStep === 3 &&
            (isEmpty(values?.parameterTypes) ||
              !errors?.parameterTypes?.every(parameter => isEmpty(parameter)))),
        variant: 'contained',
        color: 'primary',
        loading: submitting,
        loadingPosition: 'start',
        startIcon: activeStep === 3 ? <SaveIcon /> : <Done />,
      },
    ],
    assets: [
      {
        type: 'cancel',
        button: 'Cancel',
        onClick: handleClose,
        dataCy: 'wizard-cancel-button',
        sx: { marginRight: 'auto' },
        disabled: false,
      },
      {
        type: 'back',
        button: 'Back',
        onClick: () => handleBack(),
        dataCy: 'wizard-back-button',
        disabled: activeStep === 0,
      },
      {
        type: 'finish',
        button: activeStep === 0 ? 'Continue' : 'Finish',
        onClick: () => handleFinish(),
        dataCy: 'wizard-finish-button',
        variant: 'contained',
        color: 'primary',
        loading: isLoading,
        loadingPosition: 'start',
        startIcon: activeStep === 4 ? <SaveIcon /> : <Done />,
        disabled:
          activeStep === 0 &&
          isEmpty(allAssets.filter(asset => asset?.new || asset?.editedName)),
      },
    ],
    dataSets: [
      {
        type: 'cancel',
        button: 'Cancel',
        onClick: handleClose,
        dataCy: 'wizard-cancel-button',
        sx: { marginRight: 'auto' },
        disabled: activeStep === 3 && !isEmpty(values.dataSet),
      },
      {
        type: 'back',
        button: 'Back',
        onClick: () => handleBack(),
        dataCy: 'wizard-back-button',
        disabled:
          activeStep === 0 ||
          (activeStep === 3 && !isEmpty(values.dataSet)) ||
          (activeStep === 3 && !isEmpty(values.newAssets)) ||
          (activeStep === 3 && !isEmpty(values.postDataSetError)),
      },
      {
        type: activeStep === 3 ? 'finish' : 'next',
        button: activeStep === 3 ? 'Finish' : 'Next',
        onClick: activeStep === 3 ? () => handleFinish() : () => handleNext(),
        dataCy:
          activeStep === 3 ? 'wizard-finish-button' : 'wizard-next-button',
        disabled:
          (activeStep === 1 &&
            values.requirementsCount !== 0 &&
            !isEmpty(values.validationErrorDetails)) ||
          (activeStep === 3 && isEmpty(values.dataSet)),
        variant: 'contained',
      },
    ],
  };
  return { actionButtons };
};
