/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { useParams } from 'react-router';
import axios from 'axios';
import { isEmpty, uniqBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { parametersApi } from 'api';
import { DataTemplatesContext } from 'context';
import { createTemplateParametersLookup } from 'utils';

export default (formattedAssets, templateParameters, selectedAssets) => {
  const { projectId } = useParams();
  const { templateAssets } = useContext(DataTemplatesContext);
  const [existingAssetLevelParams, setExistingAssetLevelParams] = useImmer([]);
  const [existingProjectLevelParams, setExistingProjectLevelParams] = useImmer(
    []
  );
  const [error, setError] = useState({});
  const [assetParamsLoading, setAssetParamsLoading] = useState(true);
  const [projParamsLoading, setProjParamsLoading] = useState(true);
  const [dataSetParameters, setDataSetParameters] = useState([]);
  const [assetParamsProcessed, setAssetParamsProcessed] = useState(false);
  const [projParamsProcessed, setProjParamsProcessed] = useState(false);
  const [dataSetAssetParameters, setDataSetAssetParameters] = useState([]);
  const [dataSetProjectParameters, setDataSetProjectParameters] = useState([]);
  const [allDataLoading, setAllDataLoading] = useState(true);
  const existingAssetsIds = selectedAssets
    .filter(asset => asset.new === undefined)
    .map(asset => asset.id);

  const projectAssetPlaceholder = templateAssets.find(
    assetPlaceholder => assetPlaceholder.assetType === null
  );
  const projectLevelAssetPlaceholderId = projectAssetPlaceholder?.id;

  const templateParametersLookup =
    createTemplateParametersLookup(formattedAssets);

  const templateAssetsParameterTypeIds = templateParameters
    .filter(
      param => param.assetPlaceholderId !== projectLevelAssetPlaceholderId
    )
    .map(param => param.parameterTypeId);

  const templateProjectLevelParameterTypeIds = templateParameters
    .filter(
      templateParameter =>
        templateParameter.assetPlaceholderId === projectLevelAssetPlaceholderId
    )
    .map(templateParameter => templateParameter.parameterTypeId);

  const getExistingAssetsParams = async after => {
    setAssetParamsLoading(true);
    const didCancel = false;
    const source = axios.CancelToken.source();
    const query = {
      asset_id: existingAssetsIds,
      project_id: projectId,
      parameter_type_id: templateAssetsParameterTypeIds,
    };
    if (after) query.after = after;
    const response = await parametersApi(
      'getAllParameters',
      query,
      source.token
    ).catch(err => {
      setError(err);
    });
    if (!didCancel && response) {
      const { parameters, paging } = response;
      setExistingAssetLevelParams(currentParameters =>
        uniqBy([...currentParameters, ...parameters], 'id')
      );
      if (paging?.cursors?.after) {
        await getExistingAssetsParams(paging?.cursors?.after);
      }
    }
  };

  const getExistingProjectLevelParams = async after => {
    setProjParamsLoading(true);
    const didCancel = false;
    const source = axios.CancelToken.source();
    const query = {
      project_id: projectId,
      project_parameter: true,
      parameter_type_id: templateProjectLevelParameterTypeIds,
    };
    if (after) query.after = after;
    const response = await parametersApi(
      'getAllParameters',
      query,
      source.token
    ).catch(err => {
      setError(err);
    });
    if (!didCancel && response) {
      const { parameters, paging } = response;
      setExistingProjectLevelParams(currentParameters =>
        uniqBy([...currentParameters, ...parameters], 'id')
      );
      if (paging?.cursors?.after) {
        await getExistingProjectLevelParams(paging?.cursors?.after);
      }
    }
  };

  useEffect(() => {
    if (selectedAssets.length && templateAssetsParameterTypeIds.length) {
      getExistingAssetsParams().then(() => setAssetParamsLoading(false));
    }
    if (
      !isEmpty(templateProjectLevelParameterTypeIds) ||
      isEmpty(selectedAssets)
    ) {
      getExistingProjectLevelParams().then(() => setProjParamsLoading(false));
    }
    if (isEmpty(templateParameters)) {
      setProjParamsLoading(false);
      setAssetParamsLoading(false);
    }
    if (isEmpty(templateProjectLevelParameterTypeIds)) {
      setProjParamsLoading(false);
    }
    if (isEmpty(templateAssetsParameterTypeIds)) {
      setAssetParamsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets]);

  // add project level parameters
  useEffect(() => {
    if (!projParamsLoading) {
      const existingProjectLevelParamsTypeIds = existingProjectLevelParams.map(
        param => param.parameterType.id
      );
      if (!isEmpty(templateParametersLookup[projectLevelAssetPlaceholderId])) {
        const allProjectLevelParams = templateParametersLookup[
          projectLevelAssetPlaceholderId
        ].map(templateProjectParameter => {
          if (
            existingProjectLevelParamsTypeIds.includes(
              templateProjectParameter.parameterTypeId
            )
          ) {
            const existingProjectLevelParam = existingProjectLevelParams.find(
              existingProjLevelParam =>
                existingProjLevelParam.parameterType.id ===
                templateProjectParameter.parameterTypeId
            );
            return {
              ...existingProjectLevelParam,
              assetType: null,
              assetInstanceName: 'Project',
              assetInstanceKey: 'Project',
              parameterTypeName: existingProjectLevelParam.parameterType.name,
              directionId: templateProjectParameter?.directionId,
              parameterTypeId: existingProjectLevelParam.parameterType.id,
              isNew: false,
            };
          }
          return {
            ...templateProjectParameter,
            assetType: null,
            assetInstanceName: 'Project',
            assetInstanceKey: 'Project',
            isNew: true,
          };
        });
        setDataSetProjectParameters(allProjectLevelParams);
      }
      setProjParamsProcessed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projParamsLoading]);

  // add asset level parameters
  useEffect(() => {
    if (!assetParamsLoading) {
      // console.log(existingAssetLevelParams);
      const reduceExistingAssetParams = existingAssetLevelParams.reduce(
        (acc, curr) => {
          const templateParameter = templateParameters.find(
            templateParam =>
              templateParam.parameterTypeId === curr.parameterType.id
          );
          const parentId = curr.parents[0]?.id;
          if (!acc[parentId]) {
            acc[parentId] = {
              parameters: [],
            };
          }
          acc[parentId].parameters.push({
            ...curr.parameterType,
            parameterTypeId: curr.parameterType.id,
            id: curr.id,
            isAssetInstanceNew: false,
            isNew: false,
            directionId: templateParameter?.directionId,
            parameterTypeName: curr.parameterType.name,
            assetInstanceName: curr.parents[0]?.name,
            assetInstanceKey: `${curr.parents[0]?.id}-${curr.parents[0]?.name}`,
            assetType: curr.parents[0]?.assetType?.name,
          });
          return acc;
        },
        {}
      );
      const combinedAssetParametersInfo = selectedAssets
        .filter(
          selectedAsset =>
            templateParametersLookup[selectedAsset.assetPlaceholderId]
        )
        .map(selectedAsset => {
          if (reduceExistingAssetParams[selectedAsset.id]) {
            return {
              ...selectedAsset,
              ...reduceExistingAssetParams[selectedAsset.id],
            };
          }
          return {
            ...selectedAsset,
            assetTypeName: selectedAsset.assetType.name,
            parameters: [],
          };
        });
      const finalAssetParameters = combinedAssetParametersInfo
        .map(assetInfo => {
          if (
            assetInfo.parameters.length ===
            templateParametersLookup[assetInfo.assetPlaceholderId].length
          ) {
            return assetInfo.parameters.map(param => {
              return {
                ...param,
              };
            });
          }
          if (isEmpty(assetInfo.parameters)) {
            return templateParametersLookup[assetInfo.assetPlaceholderId].map(
              templateParam => {
                const { id, ...rest } = templateParam;
                return {
                  ...rest,
                  id: uuid(),
                  assetInstanceName: assetInfo.name,
                  assetInstanceKey: `${assetInfo.id}-${assetInfo.name}`,
                  assetType: assetInfo.assetTypeName,
                  isNew: true,
                  isAssetInstanceNew: !!assetInfo.new,
                };
              }
            );
          }
          if (
            !isEmpty(assetInfo.parameters) &&
            assetInfo.parameters.length !==
              templateParametersLookup[assetInfo.assetPlaceholderId].length
          ) {
            const existingParams = assetInfo.parameters.map(param => {
              return {
                ...param,
              };
            });
            const missingParams = templateParametersLookup[
              assetInfo.assetPlaceholderId
            ].filter(
              templateParam =>
                !existingParams
                  .map(param => param.parameterTypeId)
                  .includes(templateParam.parameterTypeId)
            );
            return [
              ...existingParams,
              ...missingParams.map(param => {
                const { id, ...rest } = param;
                return {
                  ...rest,
                  id: uuid(),
                  assetInstanceName: assetInfo.name,
                  assetInstanceKey: `${assetInfo.id}-${assetInfo.name}`,
                  assetType: assetInfo.assetType.name,
                  isNew: true,
                  isAssetInstanceNew: !!assetInfo.new,
                };
              }),
            ];
          }
          return [];
        })
        .flat();

      const sortedAssetParameters = finalAssetParameters.sort((a, b) =>
        a.parameterTypeName.localeCompare(b.parameterTypeName)
      );
      setDataSetAssetParameters(sortedAssetParameters);
      setAssetParamsProcessed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetParamsLoading]);

  useEffect(() => {
    if (
      (projParamsProcessed && assetParamsProcessed) ||
      (isEmpty(selectedAssets) && projParamsProcessed)
    ) {
      setDataSetParameters(
        dataSetProjectParameters.concat(dataSetAssetParameters)
      );
      setAllDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projParamsProcessed, assetParamsProcessed]);

  return { error, dataSetParameters, allDataLoading };
};
