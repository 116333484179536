import { useState, useContext } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
  useLocation,
} from 'react-router';
import qs from 'qs';
import { UserContext } from 'context';
import config from 'config';

export default () => {
  const { user } = useContext(UserContext);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  const parsedSearch = qs.parse(searchParams.toString());
  const navigate = useNavigate();
  const sortByOptions = [
    { label: 'Project Number', value: 'number' },
    { label: 'Project Name', value: 'name' },
    { label: 'Created At', value: 'created_at' },
  ];

  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  const {
    administrator: projectAdmin,
    editor: projectEditor,
    checker: projectChecker,
    approver: projectApprover,
    project_manager_email: projectManager,
    project_director_email: projectDirector,
    recentProjectsFor: projectTimesheet,
  } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };

  const filterOptionClick = () => {
    navigate({
      search: `?${createSearchParams(paramsValues)}`,
    });
  };

  const filterOptions = {
    'My Permissions': [
      {
        ariaLabel: 'admin-chip',
        label: 'Administrator',
        value: { administrator: 'administrator' },
        onClick: { filterOptionClick },
        isActive: projectAdmin,
      },
      {
        ariaLabel: 'editor-chip',
        label: 'Editor',
        value: { editor: 'editor' },
        onClick: { filterOptionClick },
        isActive: projectEditor,
      },
      {
        ariaLabel: 'checker-chip',
        label: 'Checker',
        value: { checker: 'checker' },
        onClick: { filterOptionClick },
        isActive: projectChecker,
      },
      {
        ariaLabel: 'approver-chip',
        label: 'Approver',
        value: { approver: 'approver' },

        onClick: { filterOptionClick },
        isActive: projectApprover,
      },
    ],
    'My Roles': [
      {
        ariaLabel: 'pm-chip',
        label: 'PM',
        value: { project_manager_email: user.email },
        onClick: { filterOptionClick },
        isActive: projectManager,
      },
      {
        ariaLabel: 'pd-chip',
        label: 'PD',
        value: { project_director_email: user.email },
        onClick: { filterOptionClick },
        isActive: projectDirector,
      },
    ],
  };

  if (config.featureFlags.isTimesheetProjectFilterEnabled) {
    filterOptions['Timesheet Projects'] = [
      {
        ariaLabel: 'timesheet-chip',
        label: 'Projects I book time to',
        value: { recentProjectsFor: user.email },
        onClick: { filterOptionClick },
        isActive: projectTimesheet,
      },
    ];
  }

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
    setOpenFilterMenu(!openFilterMenu);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
    setOpenFilterMenu(!openFilterMenu);
  };
  const handleFilterOptions = (event, option) => {
    const filter = option?.value || {};

    const filterKey = Object.keys(filter)[0];

    if (filterKey in parsedSearch) {
      const newSearch = { ...parsedSearch };
      delete newSearch[filterKey];

      setSearchParams({
        order: parsedSearch.order,
        sort_by: parsedSearch.sort_by,
        ...newSearch,
      });
    } else {
      setSearchParams({
        order: parsedSearch.order,
        sort_by: parsedSearch.sort_by,
        ...parsedSearch,
        ...filter,
      });
    }
  };
  const clearFilters = () => {
    setSearchParams({
      order: parsedSearch.order,
      sort_by: parsedSearch.sort_by,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortDirection = () => {
    setSearchParams({
      ...parsedSearch,
      order: parsedSearch.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleSortOptions = (event, option) =>
    setSearchParams({
      ...parsedSearch,
      sort_by: option.value,
    });

  return {
    anchorEl,
    handleClose,
    sortByOptions,
    filterOptions,
    handleSortDirection,
    handleSortOptions,
    handleFilterOptions,
    handleFilterClick,
    handleFilterClose,
    openFilterMenu,
    clearFilters,
  };
};
