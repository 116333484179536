/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import { IconButton } from '@mui/material';
import { styled, lighten, darken } from '@mui/material/styles';

const StyledButton = styled(props => <IconButton {...props} />)(
  ({ theme, ...props }) => ({
    '&:hover': {
      backgroundColor:
        props.mode === 'dark'
          ? darken(theme.palette.primary.main, 0.7)
          : lighten(theme.palette.primary.main, 0.8),
    },
  })
);

const StyledTreeIconButton = ({ title, icon, type, ...rest }) => {
  return (
    <StyledButton
      color="primary"
      size="large"
      {...rest}
      data-cy={rest['data-cy'] || type}
      aria-label={title}
    >
      {icon}
    </StyledButton>
  );
};

StyledTreeIconButton.propTypes = {
  onClick: PT.func,
  title: PT.oneOfType([PT.string, PT.node]),
  icon: PT.any.isRequired,
  type: PT.string,
};

StyledTreeIconButton.defaultProps = {
  onClick: () => {},
  title: '',
  type: '',
};

export default StyledTreeIconButton;
