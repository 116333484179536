import commonConfig from './common';

export default {
  sdks: {
    ...commonConfig.sdks,
  },
  integrations: {
    ...commonConfig.integrations,
  },
  changelogs: {
    ...commonConfig.changelogs,
  },
  url: {
    ...commonConfig.url,
    api: {
      base: 'https://sandbox.ddb.arup.com',
    },
  },
  featureFlags: {
    areTemplatesEnabled: true,
    isProjectSettingsEnabled: true,
    isRoleBasedPermissionEnabled: true,
    isCreateItemEnabled: true,
    isSourceCommentEnabled: false,
    isAssetHistoryEnabled: false,
    areSourcesEnabled: true,
    isSourceHistoryEnabled: false,
    areGlobalSourcesEnabled: false,
    isTimesheetProjectFilterEnabled: false,
    areTemplateFeaturesEnabled: false,
    areTemplatesButtonsEnabled: false,
    isProjectHistoryTabEnabled: false,
    isNumberOfDuplicatesEnabled: false,
    isDuplicateAssetsEnabled: false,
    isParameterListTableEnabled: false,
  },
  env: 'ut',
  faviconPath: '/utFavicon.png',
};
