/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import axios from 'axios';
import { uniqBy } from 'lodash';
import {
  parameterMetadataApi,
  parametersApi,
  handleApiError,
  qaApi,
  commentsApi,
} from 'api';

export default (parameterId, parameter) => {
  const [parameterTags, setParameterTags] = useImmer([]);
  const [parameterDisciplines, setParameterDisciplines] = useImmer([]);
  const [isTagLoading, setIsTagLoading] = useState(true);
  const [parameterError, setParameterError] = useState(undefined);
  const [selectedHistoryRowId, setSelectedHistoryRowId] = useState('');
  const [statusHistory, setStatusHistory] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);

  const pageLimit = 50;

  const getCommentById = async commentId => {
    let didCancel = false;
    const source = axios.CancelToken.source();
    const response = await commentsApi(
      'getCommentById',
      {
        comment_id: commentId,
      },
      source.token
    ).catch(handleApiError);
    if (response && !didCancel) {
      return response.comment?.content;
    }
    return () => {
      didCancel = true;
    };
  };

  useEffect(() => {
    setParameterTags([]);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getTags = async after => {
      const query = {
        page_limit: pageLimit,
        reference_id: parameterId,
        reference_table: 'parameter',
      };
      if (after) query.after = after;
      setIsTagLoading(true);
      const response = await parameterMetadataApi(
        `getTags`,
        query,
        source.token
      ).catch(err => handleApiError(err, []));

      if (response && !didCancel) {
        const { tags, paging } = response;
        setParameterTags(curr => [...curr, ...tags]);
        if (paging?.cursors?.after) {
          getTags(paging?.cursors?.after);
        }
        setIsTagLoading(false);
      }
    };
    if (parameterId) {
      getTags();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameterId]);

  useEffect(() => {
    setStatusHistory([]);
    let didCancel = false;
    const getRecords = async after => {
      const referenceId =
        selectedHistoryRowId || parameter?.selectedEntry?.revisionId;
      const query = {
        reference_id: referenceId,
        sort_by: 'created_at',
        order: 'desc',
      };
      if (after) query.after = after;
      setStatusLoading(true);
      const response = await qaApi('getRecords', query).catch(handleApiError);
      const { records: headerRevision, paging } = response;
      if (headerRevision && !didCancel) {
        const moreRevs = await Promise.all(
          headerRevision.map(async revision => {
            let comment;
            if (revision?.commentId) {
              comment = await getCommentById(revision?.commentId);
            }
            return {
              id: revision.id,
              status: revision?.type?.name || 'Unanswered',
              date: revision?.createdAt,
              updatedBy: revision?.createdBy,
              comment,
            };
          })
        );
        setStatusHistory(curr => uniqBy([...curr, ...moreRevs], 'id'));
      }
      if (paging?.cursors?.after) {
        getRecords(paging?.cursors?.after);
      }
      setStatusLoading(false);
    };

    if (parameter?.selectedEntry?.revisionId || selectedHistoryRowId) {
      getRecords();
    }
    return () => {
      didCancel = true;
    };
  }, [parameter, selectedHistoryRowId]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getDisciplines = async () => {
      const res = await parametersApi(
        `getAllParameterDisciplines`,
        {
          parameter_id: parameterId,
        },
        source.token
      ).catch(err => handleApiError(err, []));

      if (!didCancel && res) {
        const { disciplines: response } = res;
        const reformattedDisciplines = response?.map(discipline => {
          return {
            id: discipline.disciplineId,
            name: discipline.disciplineName,
            code: discipline.disciplineCode,
          };
        });
        setParameterDisciplines(reformattedDisciplines);
      }
    };

    if (parameterId) {
      getDisciplines();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameterId]);

  return {
    isTagLoading,
    parameterTags,
    setParameterTags,
    setIsTagLoading,
    parameterDisciplines,
    setParameterDisciplines,
    parameterError,
    setParameterError,
    selectedHistoryRowId,
    setSelectedHistoryRowId,
    setStatusHistory,
    statusHistory,
    statusLoading,
    getCommentById,
  };
};
