/* eslint-disable no-unused-vars */

import { Grid, Typography } from '@mui/material';
import PT from 'prop-types';
import { useContext } from 'react';
import ProjectCard from 'components/cards/project-card';
import { ProjectContext } from 'context';
import useNavigationHeader from './hooks/use-navigation-header';

const NavigationHeader = ({
  open,
  handleClick,
  label,
  hasProjectCard,
  dataSetName,
  handleAdd,
  disabled,
  isActive,
  filterCount,
  setFilterCount,
  tooltipMessage,
  ...rest
}) => {
  const { project } = useContext(ProjectContext);
  const { actionButtons } = useNavigationHeader(
    open,
    handleClick,
    handleAdd,
    disabled,
    filterCount,
    tooltipMessage,
    rest
  );

  return (
    <Grid
      aria-label="navigation-header"
      container
      justifyContent="flex-start"
      direction={open ? 'row' : 'column'}
      alignItems="center"
    >
      <Grid item xs={project.confidential ? 7 : 8}>
        {hasProjectCard ? (
          <ProjectCard open={open} />
        ) : (
          dataSetName && (
            <Typography
              variant="body1"
              sx={{ paddingLeft: theme => theme.spacing(0.65) }}
            >
              {dataSetName}
            </Typography>
          )
        )}
      </Grid>
      {actionButtons[label]}
    </Grid>
  );
};
NavigationHeader.propTypes = {
  open: PT.bool.isRequired,
  handleClick: PT.func,
  handleAdd: PT.func,
  label: PT.string.isRequired,
  hasProjectCard: PT.bool.isRequired,
  dataSetName: PT.string,
  disabled: PT.bool,
  isActive: PT.bool,
  filterCount: PT.number,
  setFilterCount: PT.func,
  tooltipMessage: PT.string,
};
NavigationHeader.defaultProps = {
  dataSetName: '',
  handleAdd: () => {},
  disabled: false,
  filterCount: 0,
  isActive: false,
  handleClick: () => {},
  setFilterCount: () => {},
  tooltipMessage: '',
};

export default NavigationHeader;
