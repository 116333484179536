import PT from 'prop-types';
import { Stack, Divider } from '@mui/material';
import { darken } from '@mui/material/styles';

const PageLayout = ({ children, ariaLabel }) => {
  return (
    <Stack
      aria-label={ariaLabel || 'page-layout'}
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="stretch"
      alignContent="stretch"
      minHeight={500}
      divider={<Divider orientation="vertical" flexItem />}
      // eslint-disable-next-line no-confusing-arrow
      bgcolor={theme =>
        theme.palette.mode === 'dark'
          ? darken(theme.palette.secondary.main, 0.9)
          : theme.palette.background.paper
      }
      sx={{ overflow: 'hidden' }}
    >
      {children}
    </Stack>
  );
};

PageLayout.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  ariaLabel: PT.string,
};
PageLayout.defaultProps = {
  ariaLabel: '',
};

export default PageLayout;
