import { useContext } from 'react';
import { LocalOffer } from '@mui/icons-material';
import { Divider, Typography, Grid, Box, Drawer, Toolbar } from '@mui/material';
import { useParams } from 'react-router';
import Alert from 'components/alerts';
import {
  GlobalTypesContext,
  ProjectPermissionContext,
  PageLayoutContext,
} from 'context';
import { formatDate } from 'utils';
import config from 'config';
import { FoldingDrawer } from 'components/drawer';
import formatParameterInfo from '../helpers';
import DataDrawerGrid from '../common/grid';
import History from '../common/version-history';
import Status from '../common/status-history';
import Tags from '../common/tags';
import { StyledGrid, StyledCard } from '../common/details-pane-styles';
import Value from './value';
import Details from './details';
import { useParameter, useParameterDetails } from './hooks';
import DataCardHeader from './header';

function DataDrawer() {
  const { parameterTagsAndTypes, allDisciplines } =
    useContext(GlobalTypesContext);
  const { openDrawers, setOpenDrawers, numberOfOpen } =
    useContext(PageLayoutContext);
  const { writePermission } = useContext(ProjectPermissionContext);

  const { error, parameter, setParameter, loading, commentLoading } =
    useParameter();
  const { parameterValue, valueWithUnit } = formatParameterInfo(parameter);

  const { parameterId } = useParams();
  const {
    parameterTags,
    setParameterTags,
    parameterDisciplines,
    setParameterDisciplines,
    isTagLoading,
    parameterError,
    setParameterError,
    setSelectedHistoryRowId,
    statusHistory,
    statusLoading,
  } = useParameterDetails(parameterId, parameter);

  if (error)
    return (
      <Box sx={{ padding: theme => theme.spacing(3) }}>
        <Alert title={error.msg} text={error.details} />
      </Box>
    );

  const drawerContents = (
    <Box
      sx={{
        overflowX: 'auto',
        maxHeight: 'calc(100vh - 64px)',
      }}
    >
      <DataCardHeader
        loading={loading}
        parameter={parameter}
        setError={setParameterError}
      />
      <Divider />
      {parameterError && (
        <Box sx={{ padding: theme => theme.spacing(3) }}>
          <Alert
            title={parameterError?.msg}
            text={parameterError?.details}
            onClose={() => {
              setParameterError(undefined);
            }}
          />
        </Box>
      )}
      <StyledCard>
        <StyledGrid
          aria-label="content"
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Value
            parameterValue={parameterValue}
            valueWithUnit={valueWithUnit}
          />
          <br />
          <Details
            loading={loading}
            parameter={parameter}
            setParameter={setParameter}
            setError={setParameterError}
            commentLoading={commentLoading}
            parameterValue={parameterValue}
            valueWithUnit={valueWithUnit}
          />
          <History
            parameter={parameter}
            header={
              parameter?.selectedEntry
                ? `Updated on ${String(
                    formatDate(parameter?.selectedEntry?.createdAt, undefined)
                  )} by ${parameter?.selectedEntry?.createdBy?.email}`
                : 'Not defined'
            }
            setSelectedHistoryRowId={setSelectedHistoryRowId}
          />
          {/* TO DO - refactor status history once multiple entries is implemented */}
          <Status
            parameter={parameter}
            header={
              parameter?.selectedEntry?.status
                ? `${
                    statusHistory[0]?.status ||
                    parameter?.selectedEntry?.status ||
                    'unanswered'
                  } on ${formatDate(statusHistory[0]?.date, undefined)} by ${
                    statusHistory[0]?.updatedBy?.email
                  }`
                : 'Not defined'
            }
            statusHistory={statusHistory}
            statusLoading={statusLoading}
          />
          <DataDrawerGrid
            icon={<LocalOffer />}
            caption=""
            content={
              <Grid container aria-label="tags component">
                <Typography variant="caption" color="textSecondary">
                  Tags
                </Typography>
                {parameterTagsAndTypes.map(tagType => (
                  <Tags
                    id={parameterId}
                    existingTags={parameterTags}
                    setExistingTags={setParameterTags}
                    tagType={tagType}
                    globalTags={tagType?.tags}
                    key={tagType?.id}
                    loading={isTagLoading}
                    referenceTable="parameter"
                    permission={writePermission}
                  />
                ))}
                <Tags
                  title="disciplines"
                  id={parameterId}
                  existingTags={parameterDisciplines}
                  setExistingTags={setParameterDisciplines}
                  tagType={{
                    name: 'Disciplines',
                  }}
                  globalTags={allDisciplines}
                  key="disciplines"
                  loading={isTagLoading}
                  referenceTable="parameter"
                  permission={writePermission}
                />
              </Grid>
            }
          />
        </StyledGrid>
      </StyledCard>
    </Box>
  );

  return (
    <>
      {!config.featureFlags.isParameterListTableEnabled && (
        <FoldingDrawer
          width={numberOfOpen > 2 ? '30%' : '40%'}
          open={openDrawers.openParamDetail}
          tooltip="Parameter details"
          onClick={() =>
            setOpenDrawers(curr => {
              curr.openParamDetail = !openDrawers.openParamDetail;
            })
          }
        >
          {drawerContents}
        </FoldingDrawer>
      )}
      {config.featureFlags.isParameterListTableEnabled && (
        <Drawer
          aria-label="parameter-detail-panel"
          variant="persistent"
          sx={{
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: '32vw',
              boxSizing: 'border-box',
            },
          }}
          open
          anchor="right"
        >
          <Toolbar />
          {drawerContents}
        </Drawer>
      )}
    </>
  );
}

export default DataDrawer;
