import { capitalize, isEmpty } from 'lodash';

export default (loading, dataTemplateId, dataSets, dataSetId) => {
  let dataSetName = '';
  if (!loading && dataTemplateId && !isEmpty(dataSets)) {
    dataSetName = `${capitalize(
      dataSets[0]?.parameterSetTemplate?.name
    )} parameter set`;
  } else if (dataSetId) {
    const selectedParamInstance = dataSets.filter(
      paramSet => paramSet.id === dataSetId
    );
    dataSetName = `${capitalize(
      selectedParamInstance[0]?.parameterSetTemplate?.name
    )} parameter set`;
  }
  return dataSetName;
};
