import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { handleApiError, parameterMetadataApi } from 'api';

export default () => {
  const { dataTemplateId } = useParams();
  const [templateTags, setTemplateTags] = useImmer([]);
  const [isTagLoading, setIsTagLoading] = useState(false);

  useEffect(() => {
    setTemplateTags([]);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getSetTemplateTags = async after => {
      setIsTagLoading(true);
      const query = {
        reference_id: dataTemplateId,
      };
      if (after) query.after = after;
      const response = await parameterMetadataApi(
        'getTags',
        query,
        source.token
      ).catch(err => {
        handleApiError(err, []);
      });
      if (response) {
        const { tags, paging } = response;
        if (tags && !didCancel) {
          setTemplateTags(curr => [...curr, ...tags]);
          setIsTagLoading(false);
        }
        if (!didCancel && paging?.cursors?.after)
          getSetTemplateTags(paging?.cursors?.after);
      }
    };
    if (dataTemplateId) {
      getSetTemplateTags();
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dataTemplateId]);

  return {
    templateTags,
    setTemplateTags,
    isTagLoading,
    setIsTagLoading,
  };
};
