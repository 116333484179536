import axios from 'axios';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { handleApiError, parameterMetadataApi } from 'api';

export default () => {
  const { dataSetId } = useParams();
  const [instanceSetTags, setInstanceSetTags] = useImmer([]);
  const [isTagLoading, setIsTagLoading] = useState(false);

  useEffect(() => {
    setInstanceSetTags([]);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getSetTags = async after => {
      setIsTagLoading(true);
      const query = {
        reference_id: dataSetId,
      };
      if (after) query.after = after;
      const response = await parameterMetadataApi(
        'getTags',
        query,
        source.token
      ).catch(err => {
        handleApiError(err, []);
      });
      if (response) {
        const { tags, paging } = response;
        if (tags && !didCancel) {
          setInstanceSetTags(curr => [...curr, ...tags]);
          setIsTagLoading(false);
        }
        if (!didCancel && paging?.cursors?.after)
          getSetTags(paging?.cursors?.after);
      }
    };
    if (dataSetId) {
      getSetTags();
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dataSetId]);

  return {
    instanceSetTags,
    isTagLoading,
    setInstanceSetTags,
  };
};
