import {
  Stack,
  Box,
  Typography,
  Button,
  Divider,
  useMediaQuery,
} from '@mui/material';
import PT from 'prop-types';

const HomePageStack = ({
  title,
  description,
  imageSrc,
  imageAlt,
  LinkComponent,
  learnMoreText,
}) => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Stack
      spacing={4}
      sx={{
        height: '100%',
        width: '90%',
        alignItems: isMdUp ? 'flex-start' : 'center',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box sx={{ pr: theme => theme.spacing(1.5), width: '100%' }}>
        <Typography
          variant={isMdUp ? 'h4' : 'h2'}
          component="h2"
          sx={{
            fontWeight: theme => theme.typography.fontWeightRegular,
          }}
        >
          {title}
        </Typography>
        <Divider
          sx={{
            marginTop: theme => theme.spacing(2),
            width: '100%',
            backgroundColor: theme => theme.palette.secondary.main,
          }}
        />
      </Box>
      <Box
        sx={{
          pr: theme => theme.spacing(2),
          // eslint-disable-next-line no-nested-ternary
          width: isMdUp ? '100%' : isSmUp ? '60%' : '80%',
          aspectRatio: '1 / 1',
        }}
      >
        <img height="100%" width="100%" src={imageSrc} alt={imageAlt} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          variant={isMdUp ? 'subtitle1' : 'h6'}
          component="p"
          fontWeight={theme => theme.typography.fontWeightRegular}
          pr={theme => theme.spacing(4)}
          mt={0}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignSelf: 'flex-start',
          pr: theme => theme.spacing(8),
        }}
      >
        <Button
          component={LinkComponent}
          sx={{
            marginTop: 'auto',
          }}
          variant="contained"
          color="primary"
        >
          <Typography variant="body2">{learnMoreText}</Typography>
        </Button>
      </Box>
    </Stack>
  );
};

HomePageStack.displayName = 'HomePageStack';
export default HomePageStack;

HomePageStack.propTypes = {
  title: PT.string.isRequired,
  description: PT.string.isRequired,
  imageSrc: PT.string.isRequired,
  imageAlt: PT.string.isRequired,
  LinkComponent: PT.elementType.isRequired,
  learnMoreText: PT.string.isRequired,
};
