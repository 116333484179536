import { Typography, Box, Link } from '@mui/material';
import { sourcesEventsPropTypes } from 'routes/history-page/history-content/prop-types/prop-types';
import { formatDate } from 'utils';

const SourceAccordionDetails = ({ history }) => {
  const isCreatedOrUpdated = !history.eventType.endsWith('source.removed');
  const href =
    isCreatedOrUpdated &&
    history.fileDirectoryUrl &&
    (history.fileDirectoryUrl.startsWith('http://') ||
      history.fileDirectoryUrl.startsWith('https://'))
      ? history.fileDirectoryUrl
      : `https://${history?.fileDirectoryUrl}`;

  const { publicationRevisionDate: { dateYear, dateMonth, dateDay } = {} } =
    history;

  const formattedDate =
    isCreatedOrUpdated &&
    dateYear !== null &&
    dateMonth !== null &&
    dateDay !== null
      ? formatDate(new Date(dateYear, dateMonth - 1, dateDay), undefined)
      : '';

  return (
    <Box aria-label="source-accordion-details">
      {isCreatedOrUpdated && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="source-details"
        >
          <Typography
            variant="body2"
            aria-label="source-name"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            {`${'Source Name'}: ${history.title}`}
          </Typography>
          <Typography
            variant="body2"
            aria-label="source-reference"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            {`${'Reference'}: ${history.reference}`}
          </Typography>
          <Typography
            variant="body2"
            aria-label="source-type"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            {`${'Source Type'}: ${history.sourceType}`}
          </Typography>
          <Typography
            variant="body2"
            aria-label="source-date"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            {`${'Publication / Revision Date'}: ${formattedDate}`}
          </Typography>
          <Typography
            variant="body2"
            aria-label="source-directory"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            File Directory / URL:
            <Link
              underline="always"
              color="inherit"
              href={href}
              aria-label="file-directory-url-link"
              sx={{ ml: theme => theme.spacing(0.5) }}
            >
              {history.fileDirectoryUrl}
            </Link>
          </Typography>
        </Box>
      )}
      {history.eventType.endsWith('source.removed') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="source-removed-details"
        >
          <Typography
            variant="body2"
            aria-label="source-deleted"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            Source was deleted.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

SourceAccordionDetails.propTypes = {
  history: sourcesEventsPropTypes.isRequired,
};

export default SourceAccordionDetails;
