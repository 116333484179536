import { isEmpty } from 'lodash';

export const formatAssetNavigation = (allAssets, parent) => {
  const response = [];
  allAssets.forEach(asset => {
    if (asset.parent === parent) {
      const children = formatAssetNavigation(allAssets, asset.id);
      response.push({
        id: asset?.id,
        name: asset?.name,
        assetType: {
          ...asset?.assetType,
          parent: asset?.assetType?.parent,
        },
        parent,
        setInfo: asset.isTypeOnly
          ? [{ ...asset }]
          : [{ ...asset?.typeIndex }] || null,
        assetSubType: {
          ...asset?.assetSubType,
        },
        deletedAt: asset?.deletedAt,
        children,
        hasChildren: !isEmpty(asset.children),
        assetTypeName: asset?.assetType?.name,
      });
    }
  });

  return response;
};
