import { useContext } from 'react';
import { History as HistoryIcon, Close } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import {
  useParams,
  useNavigate,
  useLocation,
  createSearchParams,
} from 'react-router';
import qs from 'qs';
import { isEmpty } from 'lodash';
import WarningDialog from 'components/dialogs/warning';
import {
  StyledIconButton,
  EditButton,
  CopyButton,
  DeleteButton,
} from 'components/buttons';
import { SourcesContext } from 'context';
import config from 'config';
import useHeaderActions from './hooks/use-header-actions';

function SourcesHeader() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

  const {
    selectedSource,
    setOpenEditingForm,
    openEditingForm,
    isLoading,
    sourceParameters,
    setRowSelectionModel,
  } = useContext(SourcesContext);

  const { deleteSelectedSource, deleteSourceOpen, setDeleteSourceOpen } =
    useHeaderActions();

  return (
    <Grid
      aria-label="sources-panel-header"
      container
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{ overflow: 'none' }}
    >
      <Grid
        sx={{
          paddingTop: theme => theme.spacing(1),
          paddingLeft: theme => theme.spacing(1.5),
        }}
        item
      >
        <Typography
          variant="subtitle1"
          sx={{ fontSize: 22 }}
          data-cy="source-details-title"
        >
          Source Details
        </Typography>
      </Grid>
      <Grid item>
        <StyledIconButton
          tooltipProps={{ placement: 'left-end' }}
          title="Close Source Details"
          onClick={() => {
            setRowSelectionModel([]);
            navigate({
              pathname: `/projects/${projectId}/sources`,
              search: `?${createSearchParams({ ...searchString })}`,
            });
          }}
          aria-label="close-editing-button"
          icon={<Close />}
          data-cy="close-source-icon"
        />
      </Grid>
      <Grid item xs={12}>
        <EditButton
          data-cy="edit-source"
          key="edit-button"
          onClick={() => {
            setOpenEditingForm(!openEditingForm);
          }}
          isEditing={openEditingForm}
          type="Source"
          disabled={
            isEmpty(selectedSource) ||
            !selectedSource.sourceType.visible ||
            selectedSource.deletedAt !== null
          }
        />
        {config.featureFlags.isSourceHistoryEnabled && (
          <StyledIconButton
            key="history-button"
            title="Open Source History"
            variant="outlined"
            disabled
            aria-label="viewHistory"
            icon={<HistoryIcon />}
          />
        )}
        <CopyButton
          aria-label="copy"
          key="copy"
          toBeCopied={selectedSource?.id || null}
          toolTipText="Source ID"
        />
        <DeleteButton
          data-cy="delete-source"
          aria-label="delete"
          disabledTootlip={
            openEditingForm
              ? 'Close edit mode to delete source'
              : 'Sources with linked parameters cannot be deleted'
          }
          disabled={
            isEmpty(selectedSource) ||
            !selectedSource.sourceType.visible ||
            isLoading ||
            !isEmpty(sourceParameters) ||
            openEditingForm ||
            selectedSource.deletedAt !== null
          }
          key="delete"
          type="source"
          onClick={() => {
            setDeleteSourceOpen(true);
          }}
        />
      </Grid>
      <WarningDialog
        open={deleteSourceOpen}
        handleAction={deleteSelectedSource}
        handleClose={() => {
          setDeleteSourceOpen(false);
        }}
        title="Delete Source"
        body="Are you sure you want to remove this source from this project?"
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        secondaryButton
      />
    </Grid>
  );
}

export default SourcesHeader;
