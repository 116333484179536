import _ from 'lodash';

/**
 * Pads a number with leading zeros to a specified length.
 *
 * @param {number} number - The number to pad.
 * @param {number} paddingLength - The desired length of the padded number.
 * @returns {string} The padded number as a string.
 */
const padNumber = (number, paddingLength = 1) => {
  if (!_.isNumber(number)) return '';
  const numberStr = number.toString();
  const zerosNeeded = paddingLength - numberStr.length;
  const zeros = '0'.repeat(zerosNeeded > 0 ? zerosNeeded : 0);
  return zeros + numberStr;
};

/**
 * Creates a duplicate name by appending a suffix to the given name if it already exists in the list of children names
 *
 * @param {string} nameToDuplicate - The name to duplicate
 * @param {string[]} childrenNames - The list of existing children names
 * @returns {string} - The duplicate name with a unique suffix if required
 */
export const createUniqueName = (nameToDuplicate, childrenNames) => {
  // Remove existing suffix from nameToDuplicate if it exists
  const baseName = nameToDuplicate.replace(/-\d+$/, '');

  // Create regex pattern to match baseName & optionally end with hyphen followed by digits
  const pattern = new RegExp(`^${_.escapeRegExp(baseName)}(?:-(\\d+))?$`);
  const matchingNames = _.filter(childrenNames, name => pattern.test(name));

  const suffixes = _.map(matchingNames, name => {
    const match = name.match(/-(\d+)$/);
    return match ? parseInt(match[1], 10) : 0;
  });
  const highestSuffix = _.max(suffixes) || 0;
  const newSuffix = padNumber(highestSuffix + 1, 3);

  return `${baseName}-${newSuffix}`;
};
