import { useState, useContext } from 'react';
import { findIndex } from 'lodash';
import validateAssetName from 'utils/validate-asset-name';
import { AssetContext } from 'context';

export default (
  asset,
  columnIndex,
  index,
  assetInstanceIndex,
  setRemoveAssetWarning
) => {
  const [editingValue, setEditingValue] = useState(asset.name);
  const [textFieldError, setTextFieldError] = useState(null);
  const {
    assetManipulation,
    getAnotherColumn,
    updateAssetInWizard,
    setAssetManipulation,
    assetWizardColumns,
    setTheAsset,
    setIndex,
    allAssets,
    setAllAssets,
    setAssetWizardColumns,
  } = useContext(AssetContext);

  const onSelectClick = () => {
    setTheAsset(asset);
    setIndex(columnIndex + 1);
    getAnotherColumn(asset.assetType.id, columnIndex);
    setAssetManipulation(curr => {
      Object.keys(curr.isSelected).forEach(key => {
        if (key >= columnIndex) {
          delete curr.isSelected[key];
        }
      });
      curr.isSelected[columnIndex] = asset.id;
      curr.isAddingNew = {};
      curr.allSubTypesUsed = {};
    });
  };

  const onEditClick = () =>
    setAssetManipulation(curr => {
      curr.isEdited[asset.id] = true;
    });

  const onClearClick = () => {
    setEditingValue(asset.name);
    setAssetManipulation(curr => {
      curr.isEdited[asset.id] = false;
    });
  };

  const onDoneClick = async () => {
    const errors = validateAssetName(
      editingValue,
      asset.parent || null,
      asset.assetType.id,
      allAssets
    );
    setTextFieldError(errors);
    if (!errors) {
      setAssetManipulation(curr => {
        curr.isEdited[asset.id] = false;
      });
      updateAssetInWizard(columnIndex, index, assetInstanceIndex, {
        ...asset,
        editedName: asset.name,
        name: editingValue,
        parentName: asset.parent
          ? allAssets.reduce((assets, { id, name }) => {
              if (id === asset.parent) return name;
              return assets;
            }, {})
          : '',
      });
    }
  };

  const removeAssets = (assetId, currAllAssets) => {
    if (assetId !== '') {
      const newAssetIndex = findIndex(currAllAssets, item => {
        return item.id === assetId;
      });

      if (newAssetIndex > -1) {
        const childIds = [...currAllAssets[newAssetIndex].children];

        currAllAssets.splice(newAssetIndex, 1);
        childIds.forEach(id => {
          return removeAssets(id, currAllAssets);
        });
      }
    }
    return currAllAssets;
  };

  const removeNewAssets = async () => {
    setIndex(columnIndex + 1);
    setAllAssets(curr => {
      const assetId = asset.id;
      return removeAssets(assetId, curr);
    });

    setAssetWizardColumns(curr => {
      const newAssetIndex = findIndex(
        curr[columnIndex][index].children,
        item => {
          return item.id === asset.id;
        }
      );
      if (newAssetIndex > -1) {
        curr[columnIndex][index].children.splice(newAssetIndex, 1);
        curr.splice(columnIndex + 1, curr.length - 1);
      }
      return curr;
    });
    setAssetManipulation(curr => {
      curr.isEdited = {};
      curr.isAddingNew[asset.assetType.name] = false;
      curr.hasSubTypes[asset.assetType.id] = [];
      curr.allSubTypesUsed[asset.assetType.id] = false;
      curr.isSelected[columnIndex] = '';
    });
    setRemoveAssetWarning({ open: false });
  };

  const onRemoveClick = async () => {
    setRemoveAssetWarning({
      open: true,
      title: 'Are you sure you want to remove this new Asset?',
      body: "All of the Asset's children will also be removed",
      onClose: () => setRemoveAssetWarning({ warning: false }),
      onAccept: removeNewAssets,
      primaryButtonText: 'Remove',
    });
  };

  return {
    onSelectClick,
    onDoneClick,
    onClearClick,
    onEditClick,
    textFieldError,
    editingValue,
    setEditingValue,
    assetManipulation,
    assetWizardColumns,
    getAnotherColumn,
    onRemoveClick,
    allAssets,
  };
};
