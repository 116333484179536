import {
  Grid,
  Menu,
  Chip,
  Typography,
  ListItem,
  Button,
  useMediaQuery,
} from '@mui/material';
import { Tune as TuneIcon } from '@mui/icons-material';
import { lighten, darken } from '@mui/material/styles';
import { useContext } from 'react';
import { StyledIconButton } from 'components/buttons';
import { ThemeContext } from 'context';
import { handleFiltersMenu } from '../../hooks';

const FilterByMenu = () => {
  const {
    handleFilterClick,
    handleFilterClose,
    openFilterMenu,
    anchorEl,
    handleFilterOptions,
    filterOptions,
    clearFilters,
  } = handleFiltersMenu();

  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { localTheme } = useContext(ThemeContext);
  return (
    <Grid container p={1} alignItems="center">
      {!screenSm && (
        <Grid item pr={1}>
          <Typography
            color="textSecondary"
            sx={{
              padding: theme => theme.spacing(0.2),
              color: theme => theme.palette.text.secondary,
              fontSize: 15,
            }}
          >
            Filter by
          </Typography>
        </Grid>
      )}

      <Grid item>
        {Object.values(filterOptions)
          .flat()
          .filter(option => {
            return option.isActive;
          })
          .map(option => {
            return (
              <Chip
                onDelete={event => {
                  handleFilterOptions(event, option);
                }}
                key={option.label}
                aria-label={option.ariaLabel}
                label={option.label}
                sx={{
                  margin: theme => theme.spacing(0.25),
                  color: theme => theme.palette.textSecondary,
                  backgroundColor:
                    localTheme === 'dark'
                      ? theme => darken(theme.palette.primary.main, 0.3)
                      : theme => lighten(theme.palette.primary.main, 0.8),
                }}
              />
            );
          })}
      </Grid>
      <StyledIconButton
        title="Set and edit project filters"
        onClick={handleFilterClick}
        icon={<TuneIcon data-testid="filterButton" />}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={handleFilterClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        data-testId="filterMenu"
      >
        {Object.keys(filterOptions).map(filterType => {
          return (
            <Grid key={filterType}>
              <ListItem key={filterType}>
                <Typography
                  color="textSecondary"
                  sx={{
                    padding: theme => theme.spacing(0.2),
                    color: theme => theme.palette.text.secondary,
                    fontSize: 16,
                  }}
                >
                  {`${filterType.toString()}`}
                </Typography>
              </ListItem>
              <Grid item sx={{ width: 200, pl: 1 }}>
                {filterOptions[filterType].map(option => {
                  return (
                    <Chip
                      key={option.label}
                      aria-label={option.ariaLabel}
                      label={option.label}
                      onClick={event => {
                        handleFilterOptions(event, option);
                        handleFilterClose();
                      }}
                      variant="outlined"
                      sx={{
                        margin: theme => theme.spacing(0.25),
                        color: theme => theme.palette.textSecondary,
                        backgroundColor: option.isActive
                          ? theme => lighten(theme.palette.primary.main, 0.8)
                          : 'transparent',
                        fontWeight: option.isActive ? '450' : null,
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Menu>
      <Button
        sx={{
          float: 'right',
          fontSize: 15,
          color: theme => theme.palette.text.secondary,
          textTransform: 'none',
          textDecoration: 'underline',
        }}
        disableElevation
        onClick={clearFilters}
      >
        Clear Filters
      </Button>
    </Grid>
  );
};

export default FilterByMenu;
