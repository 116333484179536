/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Done, DoneAll, List as ListIcon } from '@mui/icons-material';
import {
  useNavigate,
  useParams,
  useLocation,
  createSearchParams,
} from 'react-router';
import qs from 'qs';
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Tooltip, Typography } from '@mui/material';
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { formatRelativeDate } from 'utils';
import EmptyState from 'components/empty-state';
import Alert from 'components/alerts';
import {
  ParametersContext,
  PageLayoutContext,
  ProjectPermissionContext,
} from 'context';
import { FoldingDrawer } from 'components/drawer';
import Filters from 'components/filters';
import PageLayoutBox from 'components/layout/page-layout-styles';
import handleFilters from 'components/filters/hooks/index';
import { DataGrid } from 'components/tables';
import StatusCircle from 'components/status-circle';
import { EditButton } from 'components/buttons';
import { parametersApi } from 'api';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import formatParameterInfo from '../helpers';
import { StyledBox } from '../parameters-page-styles';
import ToggleFilter from '../../../components/toggle-filter/index';
import EditEntry from '../parameter-details-pane/details/edit-entry';
import AddSource from '../common/add-source';
import Header from './header';

function ParameterList() {
  const { openDrawers, setOpenDrawers } = useContext(PageLayoutContext);
  const {
    parameters,
    loading,
    getParameters,
    error,
    after,
    updateParametersList,
  } = useContext(ParametersContext);
  const { parameterId, projectId, assetId } = useParams();
  const { editPermission } = useContext(ProjectPermissionContext);
  const [paramSelected, setParamSelected] = useState(
    parameterId ? [parameterId] : []
  );
  const { checkPermission, approvePermission } = useContext(
    ProjectPermissionContext
  );
  const [paramFiltersActiveCount, setParamFiltersActiveCount] = useState(0);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const { search } = useLocation();
  const theme = useTheme();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };
  const navigate = useNavigate();
  const resetToParamListView = () => {
    navigate({
      pathname: assetId
        ? `/projects/${projectId}/asset/${assetId}/parameters`
        : `/projects/${projectId}/parameters`,

      search: `?${createSearchParams({ ...searchString })}`,
    });
    setParamSelected('');
  };
  const {
    sortByOptions,
    filters,
    applyFilters,
    filtersMap,
    handleOnChange,
    handleOnCancel,
    clearFilters,
    isFilterActive,
    handleFilterActive,
  } = handleFilters(setParamFiltersActiveCount, resetToParamListView);

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const CustomFooterStatusComponent = () => {
    return (
      <>
        <Divider />
        <Grid aria-label="customFooter" container justifyContent="flex-end">
          <Typography variant="body2" sx={{ padding: theme.spacing(1) }}>
            {after
              ? `Loaded first ${parameters?.length} rows.`
              : `Total Rows: ${parameters?.length}`}
          </Typography>
        </Grid>
      </>
    );
  };

  const EditEntryPanelContent = ({ row: rowProp }) => {
    const [openForm, setOpenForm] = useState(true);
    const [openAddSource, setOpenAddSource] = useState(false);
    const [selectedSource, setSelectedSource] = useState({});
    const [formValues, setFormValues] = useState({});
    const [heldFormValues, setHeldFormValues] = useState({});
    const [savedFormValues, setSavedFormValues] = useState({});

    const apiRef = useGridApiContext();
    const closePanel = id => {
      if (id) apiRef.current.toggleDetailPanel(id);
    };

    useEffect(() => {
      if (openForm === false && openAddSource === false) closePanel(rowProp.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openForm]);

    return rowProp.deletedAt ? null : openAddSource ? (
      <AddSource
        openAddSource={openAddSource}
        setOpenAddSource={setOpenAddSource}
        setOpenEditingForm={setOpenForm}
        setSelectedSource={setSelectedSource}
      />
    ) : (
      <EditEntry
        aria-label="editForm"
        parameter={rowProp}
        setOpenEditingForm={setOpenForm}
        setParameter={() => {}}
        setOpenAddSource={setOpenAddSource}
        selectedSource={selectedSource}
        setSelectedSource={setSelectedSource}
        setError={() => {}}
        formValues={formValues}
        setFormValues={setFormValues}
        heldFormValues={heldFormValues}
        setHeldFormValues={setHeldFormValues}
        savedFormValues={savedFormValues}
        setSavedFormValues={setSavedFormValues}
      />
    );
  };

  const getEditEntryPanelContent = useCallback(
    ({ row }) => <EditEntryPanelContent row={row} />,
    []
  );

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: params => (
        <Tooltip
          key="edit"
          title={
            !editPermission
              ? "You don't have permission to do this"
              : 'Edit permissions'
          }
        >
          <EditButton
            type="Parameter"
            key="edit-button"
            {...params}
            disabled={params.row.deletedAt}
            isEditing={params.row.deletedAt ? false : params.value}
            onClick={() => {}}
          />
        </Tooltip>
      ),
      headerName: 'Edit',
    },
    {
      field: 'parameterTypeName',
      flex: 1,
      headerName: 'Parameter type',
      type: 'string',
      valueGetter: (value, row) => row.parameterType.name,
      sortable: false,
    },
    {
      field: 'value',
      flex: 0.5,
      headerName: 'Value',
      type: 'string',
      valueGetter: (value, row) => {
        const updatedParameter = formatParameterInfo(row);
        return updatedParameter.valueWithUnit;
      },
      sortable: false,
    },
    {
      field: 'qa',
      flex: 0.5,
      headerName: 'QA',
      display: 'flex',
      type: 'singleSelect',
      valueOptions: ['check', 'approve', 'reject', 'removed', 'unanswered'],
      renderCell: ({ row }) => {
        return (
          <Grid container display="grid" alignItems="center">
            {row.deletedAt && (
              <Tooltip title={'removed'}>
                <RemovedAssetIcon />
              </Tooltip>
            )}
            {!row.deletedAt && (
              <StatusCircle status={row.selectedEntry?.status} />
            )}
          </Grid>
        );
      },
      sortable: false,
    },
    {
      field: 'lastUpdated',
      flex: 1,
      headerName: 'Last Updated',
      type: 'string',
      valueGetter: (value, row) => {
        return formatRelativeDate(row.selectedEntry?.createdAt, undefined);
      },
      sortable: false,
    },
    {
      field: 'source',
      flex: 1,
      headerName: 'Source',
      type: 'string',
      valueGetter: (value, row) => {
        return row.selectedEntry?.source?.title;
      },
      sortable: false,
    },
  ];

  const updateParameterStatus = async () => {
    const values = selectedParameters.map(param => {
      updateParametersList(
        {
          ...param,
          selectedEntry: {
            ...param.selectedEntry,
            status:
              param?.selectedEntry?.status === 'answered'
                ? 'checked'
                : 'approved',
          },
        },
        param.id
      );
      return {
        // eslint-disable-next-line camelcase
        revision_id: param?.selectedEntry?.revisionId,
        status:
          param?.selectedEntry?.status === 'answered' ? 'checked' : 'approved',
      };
    });
    const response = await parametersApi('patchRevisions', {
      body: {
        revisions: [...values],
      },
    });
    setSelectedParameters([]);
    setParamSelected([]);
    return response;
  };

  const handleOnRowsScrollEnd = () => {
    if (after) {
      getParameters();
    }
  };

  return (
    <FoldingDrawer
      open={openDrawers.openParamList}
      collapsedTooltip="Parameter list"
      width="75%"
      onClick={() =>
        setOpenDrawers(curr => {
          curr.openParamList = !openDrawers.openParamList;
        })
      }
    >
      <PageLayoutBox>
        <Suspense fallback={null}>
          <StyledBox aria-label="parameterList">
            <Suspense fallback={null}>
              <Header
                handleFilterActive={handleFilterActive}
                isFilterActive={isFilterActive}
                filterCount={paramFiltersActiveCount}
                setFilterCount={setParamFiltersActiveCount}
              />
            </Suspense>
            {isFilterActive ? (
              <Suspense fallback={null}>
                <ToggleFilter type="deletedParamFilter" />
                <Filters
                  sortByOptions={sortByOptions}
                  filters={filters}
                  applyFilters={applyFilters}
                  filtersMap={filtersMap}
                  handleOnChange={handleOnChange}
                  handleOnCancel={handleOnCancel}
                  clearFilters={clearFilters}
                />
              </Suspense>
            ) : !loading && isEmpty(parameters) ? (
              <Suspense fallback={null}>
                {error ? (
                  <div style={{ padding: theme.spacing(2) }}>
                    <Alert title={error.msg} text={error.details} />
                  </div>
                ) : (
                  <EmptyState
                    Icon={ListIcon}
                    title="No parameters"
                    subtitle="Try changing your filter or asset selection"
                  />
                )}
              </Suspense>
            ) : (
              <Grid
                sx={{
                  maxWidth: '70vw',
                  marginTop: theme.spacing(2),
                  marginLeft: theme.spacing(4),
                  marginRight: theme.spacing(4),
                }}
              >
                <>
                  <Button
                    disabled={
                      isEmpty(paramSelected) ||
                      !(
                        (selectedParameters.every(
                          param => param?.selectedEntry?.status === 'checked'
                        ) &&
                          approvePermission) ||
                        (selectedParameters.every(
                          param => param?.selectedEntry?.status === 'answered'
                        ) &&
                          checkPermission)
                      )
                    }
                    onClick={updateParameterStatus}
                    startIcon={
                      selectedParameters.every(
                        param => param?.selectedEntry?.status === 'checked'
                      ) ? (
                        <DoneAll />
                      ) : (
                        <Done />
                      )
                    }
                  >
                    {selectedParameters.length > 0
                      ? `Mark Selected as ${
                          selectedParameters.every(
                            param => param?.selectedEntry?.status === 'checked'
                          )
                            ? 'approved'
                            : 'checked'
                        }`
                      : 'Select Parameters for Bulk Operations'}
                  </Button>
                  <DataGrid
                    footer={CustomFooterStatusComponent}
                    data-cy="parametersGrid"
                    aria-label="parameter-type-table"
                    isRowSelectable={params => !params.row.deletedAt}
                    exportName="test"
                    getRowId={row => row.id}
                    showToolbar
                    filterMode="server"
                    onRowsScrollEnd={handleOnRowsScrollEnd}
                    loading={loading}
                    columnBuffer={5}
                    rows={parameters}
                    columns={columns}
                    editMode="row"
                    rowBufferPx={100}
                    hideFooterPagination={false}
                    DetailPanelContentComponent={getEditEntryPanelContent}
                    density="standard"
                    sortingOrder={['asc', 'desc']}
                    checkboxSelection
                    height={500}
                    rowHeight={50}
                    noRowsMessage="test"
                    disableColumnFilter
                    rowSelectionModel={paramSelected}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={newRowSelectionModel => {
                      setParamSelected(newRowSelectionModel);
                      setSelectedParameters(
                        parameters.filter(({ id }) =>
                          newRowSelectionModel.includes(id)
                        )
                      );
                    }}
                    onCellClick={data => {
                      if (
                        !data.row.deletedAt &&
                        data.field === 'parameterTypeName'
                      ) {
                        if (data.row.id === parameterId) {
                          resetToParamListView();
                        } else {
                          navigate({
                            pathname: `${data.row.id}`,
                            search: `?${createSearchParams({
                              ...searchString,
                            })}`,
                          });
                        }
                      } else {
                        resetToParamListView();
                      }
                    }}
                  />
                </>
              </Grid>
            )}
            {/* <Divider sx={{ margin: theme.spacing(2) }} />
            <Tabs
              sx={{ marginLeft: theme.spacing(2) }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="History" {...0} />
            </Tabs> */}
          </StyledBox>
        </Suspense>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default ParameterList;
