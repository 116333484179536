import { Container, useMediaQuery } from '@mui/material';
import AppFooter from 'components/footer';
import ContactOnboarding from 'components/contact-onboarding';
import ImageInformationSection from 'components/information-sections/image-information-section';
import { StyledDivider } from '../../home/styles';

const CentraliseData = () => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sections = [
    {
      title: 'Centralise Project Data',
      description:
        'DDB offers a centralised hub to store and manage all your project data and design decisions. No more digging through reports and emails to find the latest project information. Keep everything synchronised, consistent, and up-to-date at every stage of your projects with a trusted source.',
      imageSrc: '/illustrations/landing-page/centraliseData.svg',
      imageAlt: 'centralise data',
      titleSection: true,
      buttonText: 'Go To Projects',
    },
    {
      title: 'Clear & Consistent Structure',
      description:
        "Project data in DDB is structured to meet Arup's taxonomy and naming conventions, ensuring consistency and compatibility with other applications without reformatting. This consistent data structure also enables repeatable and automated processes across projects.",
      imageSrc: '/illustrations/landing-page/Structure.svg',
      imageAlt: 'structure',
      leftSection: true,
    },
    {
      title: 'Templates',
      description:
        "DDB Templates allow project teams to create blueprints for a specific process. They contain placeholders for the assets and parameters that you'll need, allowing you to streamline your work for standard or repeated project tasks.",
      imageSrc: '/illustrations/landing-page/Templates.svg',
      imageAlt: 'templates',
    },
    {
      title: 'Time-marked Audit Log',
      description:
        'DDB stores every change in an audit log, ensuring that project teams have a clear view of what happened in the project at what time. You can find out more information about DDB in ',
      imageSrc: '/illustrations/landing-page/history.svg',
      imageAlt: 'history',
      leftSection: true,
      link: 'https://docs.ddb.arup.com/',
      linkText: 'our documentation.',
    },
    {
      title: 'Data Transparency',
      description:
        'DDB enables transparency of all the design parameters. Everything is available in one place for all design teams to access, based on user permissions. No more digging through reports or emails to find a specific design value from another team.',
      imageSrc: '/illustrations/landing-page/data.svg',
      imageAlt: 'data',
    },
  ];

  return (
    <>
      <main id="main-content" tabIndex="-1" style={{ minHeight: 650 }}>
        <Container maxWidth="lg">
          {sections.map((section, index) => (
            <div key={section.title}>
              <ImageInformationSection
                title={section.title}
                description={section.description}
                imageSrc={section.imageSrc}
                imageAlt={section.imageAlt}
                titleSection={section.titleSection}
                buttonText={section.buttonText}
                isMdUp={isMdUp}
                leftSection={section.leftSection}
                link={section.link}
                linkText={section.linkText}
              />
              {index < sections.length - 1 && <StyledDivider />}
            </div>
          ))}
          <ContactOnboarding isMdUp={isMdUp} />
        </Container>
      </main>
      <AppFooter />
    </>
  );
};

export default CentraliseData;
