export default (elem, height) => {
  const elemClone = elem
    ? {
        scrollWidth: elem.scrollWidth,
        clientWidth: elem.clientWidth,
        scrollHeight: elem.scrollHeight,
        clientHeight: elem.clientHeight,
      }
    : {
        scrollWidth: 0,
        clientWidth: 0,
        scrollHeight: 0,
        clientHeight: 0,
      };
  if (height) elemClone.clientHeight = height;

  return (
    elemClone.scrollWidth > elemClone.clientWidth ||
    elemClone.scrollHeight > elemClone.clientHeight
  );
};
