import config from 'config';
import createClient from './create-client';

const BASE_URL = import.meta.env.VITE_APP_URL || config.url.api.base;

const baseDocsUrl = `${BASE_URL}/documentation`;

const commentsServiceUrl = `${baseDocsUrl}/comments_service/json`;
const commentsServiceVersion = 2;

const parametersServiceUrl = `${baseDocsUrl}/parameter_service/json`;
const parametersServiceVersion = 1;

const environmentContextServiceUrl = `${baseDocsUrl}/environment_context_service/json`;
const environmentContextServiceVersion = 2;

const qaServiceUrl = `${baseDocsUrl}/qa_service/json`;
const qaServiceVersion = 2;

const parameterMetadataServiceUrl = `${baseDocsUrl}/parameter_metadata_service/json`;
const parameterMetadataServiceVersion = 1;

const referenceDataServiceUrl = `${baseDocsUrl}/reference_data_service/json`;
const referenceDataServiceVersion = 2;

const userServiceUrl = `${baseDocsUrl}/user_service/json`;
const userServiceVersion = 1;

const templateServiceUrl = `${baseDocsUrl}/template_service/json`;
const templateServiceVersion = 0;

const auditServiceUrl = `${baseDocsUrl}/audit_service/json`;
const auditServiceVersion = 0;

export const commentsClient = createClient(
  commentsServiceUrl,
  commentsServiceVersion
);
export const parametersClient = createClient(
  parametersServiceUrl,
  parametersServiceVersion
);

export const environmentContextClient = createClient(
  environmentContextServiceUrl,
  environmentContextServiceVersion
);
export const qaClient = createClient(qaServiceUrl, qaServiceVersion);

export const parameterMetadataClient = createClient(
  parameterMetadataServiceUrl,
  parameterMetadataServiceVersion
);
export const referenceDataClient = createClient(
  referenceDataServiceUrl,
  referenceDataServiceVersion
);

export const templateServiceClient = createClient(
  templateServiceUrl,
  templateServiceVersion
);

export const userClient = createClient(userServiceUrl, userServiceVersion);

export const auditServiceClient = createClient(
  auditServiceUrl,
  auditServiceVersion
);
