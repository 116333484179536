import { Suspense, useContext } from 'react';
import { LocalOffer, QueryStats } from '@mui/icons-material';
import { Divider, Grid, Typography, Box } from '@mui/material';
import { useParams } from 'react-router';
import { FoldingDrawer } from 'components/drawer';
import Alert from 'components/alerts';
import {
  PageLayoutContext,
  GlobalTypesContext,
  ProjectPermissionContext,
  AssetContext,
} from 'context';
import PageLayoutBox from 'components/layout/page-layout-styles';
import config from 'config';
import { StyledGrid, StyledCard } from '../common/details-pane-styles';
import Statistics from '../common/statistics';
import DataDrawerGrid from '../common/grid';
import Tags from '../common/tags';
import History from '../common/version-history';
import Status from '../common/status-history';
import { StyledBox } from '../parameters-page-styles';
import EditAsset from './header/edit-asset';
import Value from './value';
import useAssetDetails from './hooks/use-asset-details';
import DataCardHeader from './header';

function DataDrawer() {
  const { openDrawers, setOpenDrawers, numberOfOpen } =
    useContext(PageLayoutContext);
  const { assetTagsAndTypes } = useContext(GlobalTypesContext);
  const { writePermission } = useContext(ProjectPermissionContext);
  const {
    error: assetError,
    theAsset: asset,
    setTheAsset: setAsset,
    isLoading,
    setError,
    deleteError,
    setDeleteError,
  } = useContext(AssetContext);

  const { assetId } = useParams();
  const {
    assetTags,
    setAssetTags,
    isTagLoading,
    openEditingForm,
    setOpenEditingForm,
  } = useAssetDetails();

  return (
    <FoldingDrawer
      open={openDrawers.openAssetDetail}
      tooltip="Asset details"
      width={numberOfOpen > 2 ? '20%' : '30%'}
      onClick={() =>
        setOpenDrawers(curr => {
          curr.openAssetDetail = !openDrawers.openAssetDetail;
        })
      }
    >
      <PageLayoutBox>
        <Suspense fallback={null}>
          <StyledBox>
            <DataCardHeader
              asset={asset}
              setAsset={setAsset}
              loading={isLoading}
              setError={setDeleteError}
              openEditingForm={openEditingForm}
              setOpenEditingForm={setOpenEditingForm}
            />
            <Divider />

            {assetError ? (
              <Box sx={{ padding: theme => theme.spacing(3) }}>
                <Alert title={assetError?.msg} text={assetError?.details} />
              </Box>
            ) : (
              <StyledCard>
                {deleteError && (
                  <Box sx={{ padding: theme => theme.spacing(3) }}>
                    <Alert
                      title={deleteError?.msg}
                      text={deleteError?.details}
                      onClose={() => setDeleteError(undefined)}
                    />
                  </Box>
                )}
                <StyledGrid
                  item
                  aria-label="content"
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {openEditingForm ? (
                    <EditAsset
                      asset={asset}
                      setAsset={setAsset}
                      setAssetError={setError}
                      loading={isLoading}
                      aria-label="editForm"
                      setOpenEditingForm={setOpenEditingForm}
                    />
                  ) : (
                    <Value asset={asset} />
                  )}
                  <br />
                  <DataDrawerGrid
                    icon={
                      <QueryStats
                        sx={{
                          color: theme =>
                            asset?.deletedAt && theme.palette.secondary.dark,
                        }}
                      />
                    }
                    caption={
                      <Typography variant="caption" color="textSecondary">
                        Parameter Stats
                      </Typography>
                    }
                    content={<Statistics />}
                  />
                  {config.featureFlags.isAssetHistoryEnabled && (
                    <>
                      <History parameter={asset} header="Not defined" />
                      <Status parameter={asset} header="Not defined" />
                    </>
                  )}

                  {!asset?.deletedAt && (
                    <DataDrawerGrid
                      icon={<LocalOffer />}
                      caption=""
                      content={
                        <Grid container aria-label="tags component">
                          <Typography variant="caption" color="textSecondary">
                            Tags
                          </Typography>
                          {assetTagsAndTypes.map(tagType => (
                            <Tags
                              referenceTable="assets"
                              id={assetId}
                              existingTags={assetTags}
                              setExistingTags={setAssetTags}
                              tagType={tagType}
                              globalTags={tagType?.tags}
                              key={tagType?.id}
                              loading={isTagLoading}
                              permission={writePermission}
                            />
                          ))}
                        </Grid>
                      }
                    />
                  )}
                </StyledGrid>
              </StyledCard>
            )}
          </StyledBox>
        </Suspense>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default DataDrawer;
