import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
  useParams,
} from 'react-router';
import qs from 'qs';
import { Close } from '@mui/icons-material';
import { parametersApi } from 'api';
import { DeleteButton, CopyButton, StyledIconButton } from 'components/buttons';
import config from 'config';
import { ParametersContext } from 'context';

export default (parameter, setError) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { parameterId, projectId, assetId } = useParams();
  const [displayWarning, setDisplayWarning] = useState(false);
  const { setParameters } = useContext(ParametersContext);
  const { pathname, search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getAssetHierarchy = async () => {
      setAssetsLoading(true);
      const response = await parametersApi(
        'getAssetHierarchy',
        {
          asset_ids: [parameter.parents[0].id],
          show_deleted: searchParams.get('show_deleted_assets'),
        },
        source.token
      );
      if (!didCancel && response) {
        const { hierarchies } = response;
        setAssetList(...hierarchies);
        setAssetsLoading(false);
      }
    };
    if (!isEmpty(parameter.parents)) {
      getAssetHierarchy();
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
  }, [parameter, searchParams]);

  const deleteParameter = async () => {
    setError(undefined);
    setDeleteLoading(true);
    try {
      const response = await parametersApi('deleteParameter', {
        parameter_id: parameter.id,
      });
      if (response) {
        if (searchParams.get('show_deleted_parameters') === 'true') {
          setParameters(curr => {
            const indexOfUpdate = curr.findIndex(
              existing => existing.id === parameter.id
            );

            curr[indexOfUpdate] = {
              ...curr[indexOfUpdate],
              deletedAt: new Date().toISOString(),
            };
          });
        } else {
          setParameters(curr => {
            const updatedSources = curr.filter(
              existing => existing.id !== parameter.id
            );
            return updatedSources;
          });
        }
        setDeleteLoading(false);
        const parametersIndex =
          pathname.lastIndexOf('/parameters') + '/parameters'.length;
        const pathnameNoParameterId = pathname.slice(0, parametersIndex);
        navigate({
          pathname: pathnameNoParameterId,
          search: `?${createSearchParams({ ...searchString })}`,
        });
      }
    } catch (err) {
      setError(err?.response?.data);
      setDeleteLoading(false);
    }
  };

  const warningClose = () => {
    setDisplayWarning(false);
  };

  const warningAccept = () => {
    deleteParameter();
    warningClose();
  };

  const headerActions = [
    <CopyButton
      key="copy"
      toBeCopied={parameterId}
      toolTipText="Parameter ID"
    />,
    <DeleteButton
      key="delete"
      onClick={() => {
        setDisplayWarning(true);
      }}
    />,
  ];

  if (config.featureFlags.isParameterListTableEnabled) {
    headerActions.push(
      <StyledIconButton
        key="close"
        tooltipProps={{ placement: 'left-end' }}
        title="Close Parameter Details"
        onClick={() => {
          navigate({
            pathname: assetId
              ? `/projects/${projectId}/asset/${assetId}/parameters`
              : `/projects/${projectId}/parameters`,

            search: `?${createSearchParams({ ...searchString })}`,
          });
        }}
        aria-label="close-param-button"
        icon={<Close />}
        data-cy="close-source-icon"
      />
    );
  }

  return {
    assetList,
    assetsLoading,
    deleteLoading,
    displayWarning,
    warningClose,
    warningAccept,
    headerActions,
  };
};
