import { Collapse } from '@mui/material';
import PT from 'prop-types';
import { useContext } from 'react';
import { AssetContext } from 'context';
import AddInstance from '../add-new';
import Item from './item';

const Instance = ({ type, columnIndex, index, setRemoveAssetWarning }) => {
  const { assetManipulation, setAssetManipulation } = useContext(AssetContext);

  const typeId = type.parentAssetSubTypeId?.id || type.id;
  const subTypesList = assetManipulation.hasSubTypes[typeId];

  return (
    <>
      {assetManipulation.isAddingNew[type.name] && (
        <AddInstance
          type={type}
          subTypes={subTypesList}
          index={index}
          columnIndex={columnIndex}
        />
      )}
      <Collapse in={assetManipulation.isOpen[type.name] || false}>
        {type.children.map((asset, assetInstanceIndex) => (
          <Item
            key={asset.id}
            asset={asset}
            columnIndex={columnIndex}
            index={index}
            assetInstanceIndex={assetInstanceIndex}
            selected={
              assetManipulation.isSelected[columnIndex] === asset.id || false
            }
            edited={assetManipulation.isEdited[asset.id] || false}
            setAssetManipulation={setAssetManipulation}
            setRemoveAssetWarning={setRemoveAssetWarning}
          />
        ))}
      </Collapse>
    </>
  );
};

Instance.propTypes = {
  type: PT.shape({
    name: PT.string,
    id: PT.string,
    children: PT.arrayOf(PT.shape({})),
    parentAssetSubTypeId: PT.shape({ id: PT.string }) || null,
  }).isRequired,
  index: PT.number.isRequired,
  columnIndex: PT.number.isRequired,
  setRemoveAssetWarning: PT.func.isRequired,
};

export default Instance;
