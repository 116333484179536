/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import qs from 'qs';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
} from 'react-router';
import { useImmer } from 'use-immer';
import {
  qaApi,
  referenceDataApi,
  parameterMetadataApi,
  environmentContextApi,
  handleApiError,
  parametersApi,
} from 'api';
import { ParametersContext } from 'context';
import {
  formatValue,
  formatCurrentFilters,
  updateFiltersFromSearch,
} from '../helper';

export default (setFilterCount, resetToParamListView) => {
  const { search } = useLocation();
  const { setAfter } = useContext(ParametersContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const pageLimit = 500;
  const { projectId, assetId, parameterId } = useParams();
  const { project_parameter: projectParameter } = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const [loading, setLoading] = useState(true);
  const [availableOptions, setAvailableOptions] = useImmer([]);
  const [parameterTypesLoading, setParameterTypesLoading] = useState(true);
  const [parameterTypesOptions, setParameterTypesOptions] = useImmer([]);

  const allowedQaStatus = ['answered', 'checked', 'approved', 'rejected'];

  const [filters, setFilters] = useState({
    sort_by: { sortBy: 'parameter_type_name' },
    qa_status: [],
    parameter_type_id: [],
    source_type_id: [],
    category_id: [],
    unit_id: [],
    discipline_id: [],
    report_id: [],
  });
  const handleOnChange = (value, key) =>
    setFilters({ ...filters, [key]: value });

  const filtersMap = [
    {
      loading,
      id: 'qaStatus',
      key: 'qa_status',
      options:
        availableOptions?.qaTypes?.filter(
          qaType => allowedQaStatus.includes(qaType?.name) && qaType
        ) || [],
      isOptionEqualToValue: (opt, val) => opt.name === val.name,
    },
    {
      loading: parameterTypesLoading,
      id: 'parameterTypes',
      key: 'parameter_type_id',
      options: parameterTypesOptions || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'disciplines',
      key: 'discipline_id',
      options: availableOptions?.disciplines || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'categories',
      key: 'category_id',
      options: availableOptions?.categories || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'reports',
      key: 'report_id',
      options: availableOptions?.reports || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'sourceType',
      key: 'source_type_id',
      options: availableOptions?.sourceTypes || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
    {
      loading,
      id: 'units',
      key: 'unit_id',
      options: availableOptions?.units || [],
      isOptionEqualToValue: (opt, val) => opt.id === val.id,
    },
  ];

  const handleFilterActive = () => {
    if (!isFilterActive) {
      const filtersFromSearch = {
        sort_by: {
          sortBy: searchParams.get('sort_by') || 'parameter_type_name',
        },
        qa_status: formatValue(searchParams.getAll('qa_status'), 'name'),
        parameter_type_id: formatValue(
          searchParams.getAll('parameter_type_id'),
          'id'
        ),
        source_type_id: formatValue(
          searchParams.getAll('source_type_id'),
          'id'
        ),
        category_id: formatValue(searchParams.getAll('category_id'), 'id'),
        unit_id: formatValue(searchParams.getAll('unit_id'), 'id'),
        discipline_id: formatValue(searchParams.getAll('discipline_id'), 'id'),
        report_id: formatValue(searchParams.getAll('report_id'), 'id'),
      };
      setFilters(() => {
        const newFilters = { ...filtersFromSearch };
        Object.entries(newFilters).forEach(filterType => {
          const filterTypeName = filterType[0];
          const filterTypeList = filterType[1];
          const filterSet = filtersMap.find(
            filter => filter.key === filterTypeName
          );
          if (
            filterTypeName !== 'sort_by' &&
            !isEmpty(filterTypeList && filterSet.options)
          ) {
            if (filterTypeName !== 'qa_status') {
              const withName = filterTypeList.map(value => {
                return filterSet.options.find(tag => tag.id === value.id);
              });
              newFilters[filterTypeName] = withName;
            }
          }
        });
        return newFilters;
      });
      resetToParamListView();
    }
    setIsFilterActive(!isFilterActive);
  };

  const clearFilters = () => {
    setFilters({
      sort_by: { sortBy: 'parameter_type_name' },
      qa_status: [],
      parameter_type_id: [],
      source_type_id: [],
      category_id: [],
      unit_id: [],
      discipline_id: [],
      report_id: [],
    });
  };
  const sortByOptions = [
    { label: 'name', sortBy: 'parameter_type_name' },
    { label: 'qaStatus', sortBy: 'qa_status' },
    { label: 'parameterList.createdAt', sortBy: 'created_at' },
    { label: 'parameterList.revisedAt', sortBy: 'revised_at' },
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();
    let didCancel = false;
    if (isFilterActive) {
      setParameterTypesLoading(true);
      const getParameterTypes = async after => {
        const query = {
          page_limit: pageLimit,
          project_id: projectId,
          sort_by: 'name',
          order: 'asc',
          scope_id: projectId,

          show_local: true,
          show_global: true,
        };
        if (assetId) {
          query.asset_id = assetId;
        }
        if (projectParameter) {
          query.asset_id = '';
        }
        if (after) query.after = after;
        try {
          const response = await parametersApi(
            'getAllParameterTypes',
            query,
            source.token
          ).catch(handleApiError);
          if (response) {
            const { parameterTypes, paging } = response;
            if (!didCancel && parameterTypes) {
              setParameterTypesOptions(current => [
                ...current,
                ...parameterTypes,
              ]);
              // eslint-disable-next-line max-depth
              if (paging?.cursors?.after) {
                getParameterTypes(paging.cursors.after);
              } else {
                setParameterTypesLoading(false);
              }
            }
          }
        } catch (err) {
          setParameterTypesLoading(false);
          // eslint-disable-next-line no-console
          console.log(err);
        }
      };
      setParameterTypesOptions([]);
      getParameterTypes();
    }
    return () => {
      source.cancel();
      didCancel = true;
    };
  }, [isFilterActive]);

  const getFiltered = () => {
    const updatedFilters = updateFiltersFromSearch(
      { order: searchParams.get('order') },
      {
        report_id: availableOptions.reports,
        source_type_id: availableOptions.sourceTypes,
        category_id: availableOptions.categories,
        unit_id: availableOptions.units,
        discipline_id: availableOptions.disciplines,
      }
    );
    setFilters(prevFilters => ({
      ...prevFilters,
      ...updatedFilters,
    }));
  };

  const getUnits = async (after, option) => {
    const response = await parametersApi('getAllUnits', {
      sort_by: 'symbol',
      page_limit: 500,
      order: 'asc',
      after,
    }).catch(err => handleApiError(err, []));

    if (response) {
      const { units, paging } = response;
      const formattedUnits = units.map(unit => ({
        id: unit.id,
        name: unit.symbol,
      }));
      setAvailableOptions(curr => {
        curr[option] = [...curr[option], ...formattedUnits];
      });
      if (paging?.cursors?.after) getUnits(paging.cursors.after, option);
    }
  };
  const getSourceTypes = async (after, option) => {
    const { sourceTypes, paging } = await referenceDataApi('getSourceTypes', {
      include_global: 'true',
      sort_by: 'name',
      order: 'asc',
      after,
    }).catch(err => handleApiError(err, []));

    if (sourceTypes) {
      setAvailableOptions(curr => {
        curr[option] = [...curr[option], ...sourceTypes];
      });
      if (paging?.cursors?.after) getSourceTypes(paging.cursors.after, option);
    }
  };
  const getTags = async (typeId, option, after) => {
    const { tags, paging } = await parameterMetadataApi(`getTags`, {
      sort_by: 'name',
      order: 'asc',
      tag_type_id: typeId,
      after,
    }).catch(err => handleApiError(err, []));

    if (tags) {
      setAvailableOptions(curr => {
        curr[option] = [...curr[option], ...tags];
      });
      if (paging?.cursors?.after) getTags(typeId, option, paging.cursors.after);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    let didCancel = false;
    if (isFilterActive) {
      const getOptions = async () => {
        try {
          const response = await Promise.all([
            parameterMetadataApi(
              'getTags',
              {
                sort_by: 'name',
                order: 'asc',
                tag_type_id: '17bd1c6a-a14c-43ef-a981-192b539af5da',
              },
              source.token
            ).catch(handleApiError),
            qaApi(
              'getTypes',
              { flow_id: '564e698d-3e37-4fcc-9d8b-d69978b17652' },
              source.token
            ).catch(handleApiError),
            referenceDataApi(
              'getSourceTypes',
              {
                visible_only: true,
                sort_by: 'name',
                order: 'asc',
              },
              source.token
            ).catch(handleApiError),
            parameterMetadataApi(
              'getTags',
              {
                sort_by: 'name',
                order: 'asc',
                tag_type_id: 'f18d3070-dae5-4386-996e-9c70d796e558',
              },
              source.token
            ).catch(handleApiError),
            parametersApi(
              'getAllUnits',
              {
                sort_by: 'symbol',
                order: 'asc',
              },
              source.token
            ).catch(handleApiError),
            environmentContextApi('getDisciplines', {}, source.token).catch(
              handleApiError
            ),
          ]);

          if (!didCancel && response) {
            const [
              { tags: reports, paging: pagingReports },
              { types: qaTypes },
              { sourceTypes, paging: pagingSourceTypes },
              { tags: categories, paging: pagingCategories },
              { units, paging: pagingUnits },
              { disciplines: disciplinesResponse },
            ] = response;
            const disciplines = disciplinesResponse.map(
              ({ disciplineId: id, disciplineName: name }) => ({
                id,
                name,
              })
            );
            const formattedUnits = units.map(unit => ({
              id: unit.id,
              name: unit.symbol,
            }));
            setAvailableOptions(prevOptions => ({
              ...prevOptions,
              reports,
              qaTypes,
              sourceTypes,
              categories,
              units: formattedUnits,
              disciplines,
            }));

            if (pagingReports?.cursors?.after) {
              getTags(
                '17bd1c6a-a14c-43ef-a981-192b539af5da',
                'reports',
                pagingReports?.cursors?.after
              );
            }

            if (pagingCategories?.cursors?.after) {
              getTags(
                'f18d3070-dae5-4386-996e-9c70d796e558',
                'categories',
                pagingCategories?.cursors?.after
              );
            }

            if (pagingSourceTypes?.cursors?.after) {
              getSourceTypes(pagingSourceTypes.cursors.after, 'sourceTypes');
            }

            if (pagingUnits?.cursors?.after) {
              getUnits(pagingUnits.cursors.after, 'units');
            }
            getFiltered();
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
        setLoading(false);
      };
      getOptions();
    }
    return () => {
      source.cancel();
      didCancel = true;
    };
  }, [isFilterActive]);

  useEffect(() => {
    if (!loading) {
      setFilters(curr => {
        const newFilters = { ...curr };
        Object.entries(newFilters).forEach(filterType => {
          const filterTypeName = filterType[0];
          const filterTypeList = filterType[1];
          const filterSet = filtersMap.find(
            filter => filter.key === filterTypeName
          );
          if (
            filterTypeName !== 'sort_by' &&
            !isEmpty(filterTypeList && filterSet.options)
          ) {
            if (filterTypeName !== 'qa_status') {
              const withName = filterTypeList.map(value => {
                return filterSet.options.find(tag => tag.id === value.id);
              });
              newFilters[filterTypeName] = withName;
            }
          }
        });
        return newFilters;
      });
    }
  }, [loading, parameterTypesLoading]);

  // eslint-disable-next-line no-unused-vars
  const countFilters = (availableFilters, show_deleted_parameters) => {
    let count = 0;
    Object.keys(availableFilters).forEach(key => {
      if (key !== 'sort_by') {
        count += availableFilters[key].length;
      }
    });
    setFilterCount(count);
  };
  const handleOnCancel = () => {
    handleFilterActive();
  };
  const applyFilters = () => {
    const availableFilters = formatCurrentFilters(filters);
    const defaultSearchParams = {
      show_deleted_parameters:
        searchParams.get('show_deleted_parameters') || 'false',
      order: searchParams.get('order') || 'asc',
      show_deleted_assets: searchParams.get('show_deleted_assets') || 'false',
    };
    setAfter(null);
    countFilters(availableFilters, defaultSearchParams.show_deleted_parameters);
    if (parameterId)
      navigate({
        pathname: `/projects/${projectId}/${
          assetId ? `asset/${assetId}/` : ''
        }parameters`,
        search: `?${createSearchParams({
          ...defaultSearchParams,
          ...availableFilters,
        })}`,
      });
    else setSearchParams({ ...defaultSearchParams, ...availableFilters });

    handleFilterActive();
  };

  return {
    filters,
    sortByOptions,
    applyFilters,
    filtersMap,
    handleOnChange,
    countFilters,
    clearFilters,
    handleOnCancel,
    isFilterActive,
    handleFilterActive,
  };
};
