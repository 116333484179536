import { isEmpty } from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { AssetContext, DataTemplatesContext } from 'context';
import { parametersApi } from 'api';

export default () => {
  const { setAllAssets } = useContext(AssetContext);
  const { projectId } = useParams();
  const { templateAssets } = useContext(DataTemplatesContext);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [openDataSetWizard, setOpenDataSetWizard] = useState(false);
  const [availableTemplateAssetTypes, setAvailableTemplateAssetTypes] =
    useState([]);
  const handleFilterActive = () => {
    setIsFilterActive(!isFilterActive);
  };
  const [topLevelAssetType, setTopLevelAssetType] = useState({
    type: '',
    available: false,
  });

  useEffect(() => {
    if (!openDataSetWizard) {
      setAllAssets([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDataSetWizard]);

  const handleOpenCloseWizard = () => {
    setOpenDataSetWizard(!openDataSetWizard);
  };

  useEffect(() => {
    const getAvailableAssetTypes = async assetTypeId => {
      const response = await parametersApi('getAllAssetTypes', {
        project_id: projectId,
        asset_type_id: assetTypeId,
      });
      if (response) {
        const { assetTypes: availableAssetType } = response;
        setAvailableTemplateAssetTypes(availableAssetType[0]);
      }
    };

    if (templateAssets?.length) {
      const topAssetType = templateAssets.find(
        placeholder => !placeholder.parentAssetPlaceholderId
      );
      if (topAssetType.assetType) {
        getAvailableAssetTypes(topAssetType.assetType.id);
      }
    }
  }, [templateAssets, projectId]);

  useEffect(() => {
    if (templateAssets.length) {
      const topAssetType = templateAssets.find(
        placeholder => !placeholder.parentAssetPlaceholderId
      );
      if (topAssetType?.assetType) {
        if (!isEmpty(availableTemplateAssetTypes)) {
          setTopLevelAssetType({
            type: availableTemplateAssetTypes.name,
            available: true,
          });
        } else {
          setTopLevelAssetType({
            type: topAssetType.assetType.name,
            available: false,
          });
        }
      } else {
        setTopLevelAssetType({ type: '', available: true });
      }
    } else {
      setTopLevelAssetType({ type: '', available: false });
    }
  }, [availableTemplateAssetTypes, templateAssets]);

  return {
    openDataSetWizard,
    setOpenDataSetWizard,
    handleOpenCloseWizard,
    topLevelAssetType,
    setTopLevelAssetType,
    handleFilterActive,
  };
};
