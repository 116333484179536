import { useMediaQuery, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import {
  ProjectContext,
  SourcesContext,
  ProjectPermissionContext,
} from 'context';
import SplitButton from 'components/buttons/split-button';
import config from 'config';
import { StyledTypography } from './sources-page-styles';

const SourcesTitle = () => {
  const screenSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { project } = useContext(ProjectContext);
  const { setOpenAddSource, setOpenGlobalSources } = useContext(SourcesContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [buttonMenuOpen, setButtonMenuOpen] = useState(false);
  const buttonTitle = { id: 'addSourceMain', name: 'Add Source' };
  const buttonOptions = [{ id: 'addNewSource', name: 'Add a new source' }];
  const { writePermission } = useContext(ProjectPermissionContext);

  const handleMenuItemClick = index => {
    if (index === 0) {
      setOpenGlobalSources(false);
      setOpenAddSource(true);
    }
    if (index === 1) {
      setOpenAddSource(false);
      setOpenGlobalSources(true);
    }

    setSelectedIndex(index);
    setButtonMenuOpen(false);
  };

  if (config.featureFlags.areGlobalSourcesEnabled)
    buttonOptions.push({
      name: 'Import Global Sources',
    });
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      <Grid item>
        <StyledTypography
          aria-label="drawer-title-sources"
          variant={screenSm ? 'h6' : 'h5'}
          component="h1"
          pt={1.2}
        >
          Sources Management - {project?.shortTitle}
        </StyledTypography>
      </Grid>
      <span>
        <Grid
          item
          sx={{
            padding: theme => theme.spacing(1),
            paddingTop: theme => theme.spacing(2),
          }}
        >
          <SplitButton
            options={buttonOptions}
            buttonTitle={buttonTitle}
            handleMenuItemClick={handleMenuItemClick}
            selectedIndex={selectedIndex}
            open={buttonMenuOpen}
            setOpen={setButtonMenuOpen}
            disabled={!writePermission}
          />
        </Grid>
      </span>
    </Grid>
  );
};

export default SourcesTitle;
