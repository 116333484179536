import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { environmentContextApi, handleApiError } from 'api';

export default (updateProjectsState, toggleOpen) => {
  const [loading, setLoading] = useState(false);
  const [addingProject, setAddingProject] = useState(false);
  const [addedProject, setAddedProject] = useState(false);
  const [addingProjectErrors, setAddingProjectErrors] = useState(false);
  const [options, setOptions] = useState([]);
  const [projectSearch, setProjectSearch] = useState(null);
  const [selectedProject, setSelectedProject] = useState();
  const [ddbProjects, setDdbProjects] = useState([]);
  const [projectExists, setProjectExists] = useState(false);

  const getDDBProjects = async (source, projectNumbers) => {
    try {
      const response = await environmentContextApi(
        'getProjects',
        { number: projectNumbers },
        source.token
      );
      if (response) {
        const { projects: projectsResponse } = response;
        setDdbProjects(projectsResponse);
        return projectsResponse;
      }
    } catch (err) {
      if (!axios.isCancel(err)) throw err;
    }
    return () => {
      source.cancel();
    };
  };
  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getOptions = async () => {
      setLoading(true);
      try {
        const response = await environmentContextApi(
          'getArupDbProjects',
          {
            search: true,
            number: projectSearch,
          },
          source.token
        ).catch(handleApiError);

        if (!didCancel && response) {
          const { projects: newOptions } = response;
          if (newOptions) {
            setOptions(newOptions);
          }
          setLoading(false);
        }
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
      }
    };

    let timeout;
    if (!isEmpty(projectSearch)) {
      timeout = setTimeout(getOptions, 1000);
    }

    if (isEmpty(projectSearch)) {
      setOptions([]);
      setLoading(false);
    }

    return () => {
      didCancel = true;
      source.cancel();
      clearTimeout(timeout);
    };
  }, [projectSearch]);

  useEffect(() => {
    if (selectedProject) setProjectExists('checking');
    const getExistingDDBProjects = async () => {
      const didCancel = false;
      const source = axios.CancelToken.source();
      const response = await getDDBProjects(
        source,
        selectedProject.projectNumber
      ).catch(handleApiError);
      if (!didCancel && !isEmpty(response)) {
        setProjectExists(response[0].projectId);
      } else {
        setProjectExists(false);
      }
    };
    if (selectedProject) {
      getExistingDDBProjects();
    }
  }, [selectedProject]);

  const postProject = async () => {
    setAddingProject(true);
    try {
      const { project } = await environmentContextApi('addProject', {
        body: { number: selectedProject.projectNumber },
      });
      if (project) {
        setAddedProject(project.projectId);
        updateProjectsState(project);
      }
    } catch (err) {
      // todo: add errors, once error supression removed at top level
      if (err.status === 409) {
        const isConfidential = !ddbProjects.find(project => {
          return project.projectNumber === selectedProject.projectNumber;
        });
        if (isConfidential) {
          err.isConfidential = true;
        }
      }
      setAddingProjectErrors(err);
    }
    setAddingProject(false);
  };

  const handleInputChange = event => {
    if (event.target.value === '') setProjectExists(false);

    setProjectSearch(event.target.value);
  };

  const handleSelection = (event, value) => {
    setSelectedProject(value);
    setAddingProjectErrors(false);
  };

  const handleClose = () => {
    setProjectExists(false);
    setAddedProject(false);
    setAddingProjectErrors(false);
    setProjectSearch(null);
    setSelectedProject();
    setOptions([]);
    toggleOpen();
  };

  return {
    handleInputChange,
    postProject,
    handleSelection,
    handleClose,
    options,
    loading,
    projectSearch,
    selectedProject,
    addingProject,
    addedProject,
    addingProjectErrors,
    projectExists,
  };
};
