import PT from 'prop-types';
import { useNavigate, useLocation, createSearchParams } from 'react-router';
import { useIsAuthenticated } from '@azure/msal-react';
import {
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  Divider,
  Tooltip as ToolTip,
  Avatar,
  Box,
} from '@mui/material';

export default function NavigationMenu({
  drawerOpen,
  navigationList,
  navigationListTitle,
  toggleDrawerOpen,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const filteredNavigationList = navigationList.filter(
    item => !item.requiresAuthentication || isAuthenticated
  );

  return (
    <>
      {navigationListTitle && (
        <Box
          sx={{
            // eslint-disable-next-line no-confusing-arrow
            padding: theme =>
              drawerOpen ? theme.spacing(1, 2, 1, 2) : theme.spacing(1),
          }}
          data-testid="navigationMenu"
        >
          {drawerOpen ? (
            <Typography variant="h6" component="h1">
              {navigationListTitle.toUpperCase()}
            </Typography>
          ) : (
            <ToolTip title={navigationListTitle} data-testid="tooltip">
              <Avatar
                variant="rounded"
                data-testid="avatar"
                sx={{ bgcolor: theme => theme.palette.grey[600] }}
              >
                {navigationListTitle.match(/\b(\w)/g).slice(0, 2)}
              </Avatar>
            </ToolTip>
          )}
          <Divider light />
        </Box>
      )}
      <List
        sx={{ paddingTop: 0, overflow: 'auto', height: '100%' }}
        data-cy="navigationList"
        aria-label="navigationList"
        role="list"
      >
        {filteredNavigationList.map(
          ({ path, translate = true, text, ...rest }) =>
            rest.userAccess && (
              <ListItem
                sx={{ padding: 0 }}
                key={path || rest.params}
                data-cy={rest['data-cy'] || 'navigationMenuListItem'}
              >
                <ListItemButton
                  aria-label={text}
                  data-testid={`navigationMenuListItem ${text}`}
                  selected={
                    pathname.includes(path) ||
                    pathname === path ||
                    rest.selected
                  }
                  onClick={() => {
                    if (rest.params) {
                      navigate({
                        search: `?${createSearchParams(rest.params)}`,
                      });
                    } else {
                      navigate({
                        pathname: path,
                        search: `?${createSearchParams(rest.params)}`,
                      });
                      if (drawerOpen) toggleDrawerOpen();
                    }
                  }}
                >
                  {drawerOpen ? (
                    <>
                      {rest.icon && (
                        <ListItemIcon
                          sx={{
                            paddingLeft: theme =>
                              rest.inset && theme.spacing(4),
                          }}
                        >
                          {rest.icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={translate ? text : text}
                        sx={{
                          paddingLeft: theme => rest.inset && theme.spacing(4),
                        }}
                        inset={rest.inset}
                      />
                    </>
                  ) : (
                    <ListItemIcon>
                      <ToolTip title={translate ? text : text}>
                        {rest.icon}
                      </ToolTip>
                    </ListItemIcon>
                  )}
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </>
  );
}

NavigationMenu.propTypes = {
  toggleDrawerOpen: PT.func.isRequired,
  drawerOpen: PT.bool.isRequired,
  navigationListTitle: PT.string,
  navigationList: PT.arrayOf(
    PT.shape({
      path: PT.string,
      'data-cy': PT.string,
      text: PT.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      icon: PT.any,
      userAccess: PT.bool.isRequired,
      translate: PT.bool,
    })
  ).isRequired,
};
NavigationMenu.defaultProps = {
  navigationListTitle: null,
};
