/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-confusing-arrow */
import PT, { string } from 'prop-types';
import { isEmpty } from 'lodash';
import { ChevronRight, ExpandMore, MoreVert } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogContent,
  ClickAwayListener,
  Chip,
} from '@mui/material';
import {
  useSearchParams,
  useLocation,
  createSearchParams,
  useNavigate,
} from 'react-router';
import qs from 'qs';
import { useContext, useState } from 'react';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { AssetNavigationTreeItem } from 'components/tree/tree-item';
import { AssetContext, ThemeContext } from 'context';
import useHeaders from 'routes/parameters-page/asset-details-pane/header/hooks';
import WarningDialog from 'components/dialogs/warning';
import useButton from 'components/buttons/hooks/use-button';
import config from 'config';
import EditAsset from 'routes/parameters-page/asset-details-pane/header/edit-asset';
import DuplicateAsset from '../../duplicate-asset';
import StyledTreeIconButton from './tree-items-styles';

const TreeItems = ({
  node,
  expanded,
  isHierarchy,
  setExpanded,
  selectedNode,
  setSelectedNode,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const searchString = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const {
    theAsset,
    getAssets,
    assetsLoading,
    allAssets,
    isExpanding,
    getAsset,
    setTheAsset,
    setError,
  } = useContext(AssetContext);
  let setAsset = () => {};
  if (theAsset?.id === node.id) {
    setAsset = setTheAsset;
  }
  const { handleDeleteClick, displayWarning, handleRestoreClick } = useHeaders(
    node,
    setAsset
  );
  const showDeleted = parsedSearch.show_deleted_assets === 'true';
  delete searchString.project_parameter;
  const { localTheme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isDuplicateAssetOpen, setIsDuplicateAssetOpen] = useState(false);
  const [openEditingForm, setOpenEditingForm] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [moreVertVisible, setMoreVertVisible] = useState(false);
  const getSetMoreVertVisible = () =>
    menuOpen ? () => {} : setMoreVertVisible;

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setMoreVertVisible(true);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
    setMenuOpen(false);
    setMoreVertVisible(false);
  };

  const handleDelete = event => {
    handleMenuClose(event);
    handleDeleteClick();
  };

  const handleEdit = event => {
    handleMenuClose(event);
    setOpenEditingForm(true);
  };

  const handleRestore = event => {
    handleMenuClose(event);
    handleRestoreClick();
  };

  const handleDuplicateAssetClick = event => {
    handleMenuClose(event);
    setIsDuplicateAssetOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const {
    renderTooltipDeleteIcon,
    renderTooltipDuplicateButton,
    renderTooltipRestore,
    renderTooltipEditCloseIcon,
  } = useButton();

  const onOpenClick = () => {
    if (!isHierarchy) return;
    if (!node.id.includes('_')) {
      if (node.id !== theAsset.id) {
        const selected = allAssets.find(asset => asset.id === node.id);
        const chunkSize = 99;

        Array.from(
          { length: Math.ceil(selected.children.length / chunkSize) },
          (_, i) =>
            getAssets({
              asset_id: selected.children.slice(
                i * chunkSize,
                i * chunkSize + chunkSize
              ),
            })
        );
      }
    }
    setExpanded(curr => [...curr, node.id]);
  };

  const handleCopy = event => {
    event.stopPropagation();
    navigator.clipboard.writeText(node.id);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  const onNodeToggle = () => {
    if (!isHierarchy) return;
    navigate({
      pathname: `asset/${node.id}/parameters`,
      search: `?${createSearchParams({
        ...searchString,
      })}`,
    });
  };

  const menuItems = [
    { label: 'Copy ID', action: 'copyId', onClick: handleCopy },
    {
      label: 'Rename Asset',
      action: 'renameAsset',
      onClick: handleEdit,
      disabled:
        !isEmpty(node.assetSubType) ||
        node.deletedAt ||
        renderTooltipEditCloseIcon(false, 'Asset')[1],
      tooltipTitle:
        !isEmpty(node.assetSubType) || node.deletedAt
          ? 'Not available'
          : renderTooltipEditCloseIcon(false, 'Asset')[0],
    },
  ];
  if (config?.featureFlags?.isDuplicateAssetsEnabled) {
    menuItems.push({
      label: 'Duplicate Asset',
      action: 'duplicateAsset',
      disabled:
        node.deletedAt ||
        !isEmpty(node.assetSubType) ||
        renderTooltipDuplicateButton()[1],
      tooltipTitle:
        !isEmpty(node.assetSubType) || node.deletedAt
          ? 'Not available'
          : renderTooltipDuplicateButton()[0],
      onClick: handleDuplicateAssetClick,
    });
  }
  if (node.deletedAt) {
    menuItems.push({
      label: 'Restore Asset',
      action: 'restoreAsset',
      onClick: handleRestore,
      disabled: renderTooltipRestore()[1],
      tooltipTitle: renderTooltipRestore()[0],
    });
  } else {
    menuItems.push({
      label: 'Delete Asset',
      action: 'deleteAsset',
      onClick: handleDelete,
      disabled: renderTooltipDeleteIcon()[1],
      tooltipTitle: renderTooltipDeleteIcon()[0],
    });
  }

  const deletedStyleColor = theme =>
    showDeleted && node?.deletedAt
      ? theme.palette.secondary.dark
      : theme.palette.primary.main;

  const deletedChildren =
    !isEmpty(node.children) &&
    node?.children?.every(child => {
      return child?.deletedAt !== null;
    });

  return (
    (!isHierarchy ||
      showDeleted ||
      node.isTypeOnly ||
      (!showDeleted && node?.deletedAt === null)) && (
      <>
        <AssetNavigationTreeItem
          setMoreVertVisible={getSetMoreVertVisible()}
          key={node?.id}
          itemId={node?.id}
          node={node}
          labelInfo={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              {isHierarchy && (
                <Tooltip
                  title={`${node.name} - ${node.assetTypeName}`}
                  placement="left"
                >
                  <Chip
                    key={node.id}
                    aria-label="asset-type-chip"
                    size="small"
                    label={node.assetTypeName}
                    sx={{
                      display: 'flex',
                      position: 'absolute',
                      right: 20,
                      margin: '0 8px',
                      color: theme => theme.palette.getContrastText,
                      fontSize: '0.75rem',
                      height: '20px',
                      maxWidth: '100px',
                    }}
                  />
                </Tooltip>
              )}
              {isHierarchy && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MoreVert
                    aria-label="more-vert-icon"
                    aria-hidden="false"
                    onClick={handleMenuOpen}
                    sx={{
                      cursor: 'pointer',
                      display: moreVertVisible ? 'flex' : 'none',
                      position: 'absolute',
                      right: 0,
                    }}
                  />
                  <Menu
                    open={menuOpen}
                    anchorEl={anchorEl}
                    aria-label="asset-menu"
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    style={{
                      height: '300px',
                    }}
                  >
                    {menuItems.map(item =>
                      item.action === 'copyId' ? (
                        <ClickAwayListener
                          key={item.action}
                          onClickAway={handleTooltipClose}
                        >
                          <Tooltip
                            placement="right"
                            title="Copied"
                            open={tooltipOpen}
                            onClick={handleTooltipClose}
                          >
                            <MenuItem onClick={item.onClick}>
                              {item.label}
                            </MenuItem>
                          </Tooltip>
                        </ClickAwayListener>
                      ) : (
                        <Tooltip
                          placement="right"
                          key={item.action}
                          title={item.tooltipTitle}
                        >
                          <span>
                            <MenuItem
                              onClick={item.onClick}
                              aria-label={item.action}
                              disabled={item.disabled}
                            >
                              {item.label}
                            </MenuItem>
                          </span>
                        </Tooltip>
                      )
                    )}
                  </Menu>
                </div>
              )}
            </div>
          }
          labelText={node?.name}
          classes={{
            label: menuOpen ? 'menu-open' : 'instanceLabel',
            iconContainer: 'assetNavigationTree',
          }}
          labelIcon={
            showDeleted &&
            node.deletedAt && (
              <RemovedAssetIcon
                sx={{
                  paddingRight: theme => theme.spacing(0.5),
                  color: theme => theme.palette.secondary.dark,
                }}
              />
            )
          }
          onLabelClick={onNodeToggle}
          slots={{
            icon: () => (
              <Grid container>
                <Grid item xs={12}>
                  {(assetsLoading &&
                    node.hasChildren &&
                    isEmpty(node.children) &&
                    expanded.includes(node.id)) ||
                  (assetsLoading &&
                    (isExpanding || selectedNode === node.id) &&
                    node.id === theAsset.id) ? (
                    <CircularProgress size="50%" aria-label="loading-spinner" />
                  ) : node.hasChildren &&
                    (!deletedChildren || showDeleted) &&
                    !expanded.includes(node.id) ? (
                    <StyledTreeIconButton
                      sx={{ padding: 1.25 }}
                      mode={localTheme}
                      title={`Expand ${node.name}`}
                      data-cy="tree-expand-icon"
                      onClick={event => {
                        setSelectedNode(node.id);
                        event.stopPropagation(); // Prevent selection
                        onOpenClick();
                      }}
                      icon={<ChevronRight />}
                    />
                  ) : (
                    node.hasChildren &&
                    (!deletedChildren || showDeleted) &&
                    expanded.includes(node.id) && (
                      <StyledTreeIconButton
                        sx={{ padding: 1.25 }}
                        mode={localTheme}
                        data-cy="tree-collapse-icon"
                        title={`Collapse ${node.name}`}
                        onClick={event => {
                          setSelectedNode(node.id);
                          event.stopPropagation();
                          setExpanded(curr =>
                            curr.filter(nodes => nodes !== node.id)
                          );
                        }}
                        icon={<ExpandMore />}
                      />
                    )
                  )}
                </Grid>
              </Grid>
            ),
          }}
          color={theme => deletedStyleColor(theme)}
          textWeight={400}
        >
          {!isEmpty(node.children)
            ? node.children
                .filter(deletedAsset =>
                  showDeleted ? deletedAsset : deletedAsset.deletedAt === null
                )
                .map(child => {
                  if (Array.isArray(child)) {
                    return child.reduce((allChildren, grandChild) => {
                      if (!showDeleted && grandChild?.deletedAt === null) {
                        return (
                          <TreeItems
                            isHierarchy={isHierarchy}
                            node={grandChild}
                            key={grandChild.id}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                          />
                        );
                      }
                      return allChildren;
                    }, {});
                  }
                  return (
                    <TreeItems
                      isHierarchy={isHierarchy}
                      node={child}
                      key={child.id}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      selectedNode={selectedNode}
                      setSelectedNode={setSelectedNode}
                    />
                  );
                })
            : null}
        </AssetNavigationTreeItem>
        <Dialog
          fullWidth
          maxWidth="sm"
          aria-label="duplicate-asset-dialog"
          open={isDuplicateAssetOpen}
        >
          <DialogContent>
            <DuplicateAsset
              setOpenDuplicateAsset={setIsDuplicateAssetOpen}
              assetId={node.id}
              parentId={node.parent}
              getAsset={getAsset}
              isDuplicateAssetOpen={isDuplicateAssetOpen}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          aria-label="rename-asset-dialog"
          open={openEditingForm}
        >
          <DialogContent>
            <EditAsset
              asset={node}
              setAsset={setAsset}
              setAssetError={setError}
              loading={false}
              aria-label="editForm"
              setOpenEditingForm={setOpenEditingForm}
            />
          </DialogContent>
        </Dialog>
        <WarningDialog
          open={displayWarning?.open}
          handleAction={displayWarning.handleAccept}
          handleClose={displayWarning.handleClose}
          title={displayWarning?.title}
          body={displayWarning.body}
          secondaryButtonText={displayWarning?.secondaryButtonText}
          primaryButtonText={displayWarning?.primaryButtonText}
        />
      </>
    )
  );
};
TreeItems.propTypes = {
  node: PT.shape({
    assetSubType: PT.shape({}),
    id: PT.string,
    name: PT.string,
    children: PT.array,
    deletedAt: PT.string,
    hasChildren: PT.bool,
    checked: PT.bool,
    parent: PT.string,
    parentChecked: PT.bool,
    isTypeOnly: PT.bool,
    setInfo: PT.arrayOf(PT.shape({ maxCount: PT.number })),
    assetTypeCount: PT.number || PT.null,
    multipleSubtypes: PT.bool,
    assetTypeName: PT.string,
  }),

  expanded: PT.arrayOf(string),
  isHierarchy: PT.bool.isRequired,
  setExpanded: PT.func.isRequired,
  setSelectedNode: PT.func,
  selectedNode: PT.string,
};
TreeItems.defaultProps = {
  node: {},
  expanded: [],
  selectedNode: '',
  setSelectedNode: () => {},
};
export default TreeItems;
