import { useEffect, useState } from 'react';
import PT from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Chip,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import FinalForm from 'components/forms';
import TextField from 'components/pickers/final-form-fields/text-field';
import config from 'config';
import useDuplicateAsset from './hooks/use-duplicate-asset';

const DuplicateAsset = ({
  setOpenDuplicateAsset,
  assetId,
  parentId,
  getAsset,
  isDuplicateAssetOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [enableSubmitButton, setEnableSubmitButton] = useState(true);
  const [editingValue, setEditingValue] = useState(1);
  const [chipsLoading, setChipsLoading] = useState(true);
  const {
    postDuplicateAsset,
    openSnackBar,
    handleIncludeDescendantChange,
    assetSummary,
    handleIncludeParametersChange,
  } = useDuplicateAsset(
    setOpenDuplicateAsset,
    assetId,
    parentId,
    getAsset,
    editingValue,
    isDuplicateAssetOpen,
    setChipsLoading
  );

  useEffect(() => {
    if (openSnackBar.open) {
      const title =
        openSnackBar.variant === 'success'
          ? 'Asset added successfully'
          : `Error: ${openSnackBar.message}`;
      enqueueSnackbar(title, {
        variant: openSnackBar.variant,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSnackBar]);

  const formFields = [
    {
      size: 12,
      field: (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="duplicateAllChildren"
                onChange={handleIncludeDescendantChange}
              />
            }
            label="Duplicate all children of this asset"
            name="duplicateAllChildren"
          />
        </Grid>
      ),
    },
    {
      size: 12,
      field: (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="duplicateAllChildren"
                onChange={handleIncludeParametersChange}
              />
            }
            label="Duplicate parameters"
            name="duplicateAllChildren"
          />
        </Grid>
      ),
    },
    {
      size: 12,
      field: (
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
              You will generate in total:
            </Typography>
          </Grid>
          {chipsLoading ? (
            <CircularProgress
              data-testid="loadingCircle"
              size={20}
              thickness={6}
            />
          ) : (
            <>
              <Chip
                aria-label="new-chip"
                size="small"
                color="secondary"
                label={
                  assetSummary?.assetCount !== undefined
                    ? `${assetSummary?.assetCount} assets`
                    : 'error'
                }
                sx={{
                  marginRight: 1,
                  fontSize: '12px',
                }}
              />
              <Chip
                aria-label="new-chip"
                size="small"
                color="secondary"
                label={
                  assetSummary?.parameterCount !== undefined
                    ? `${assetSummary?.parameterCount} parameters`
                    : 'error'
                }
                sx={{
                  marginRight: 1,
                  fontSize: '12px',
                }}
              />
            </>
          )}
        </Grid>
      ),
    },
  ];

  if (config?.featureFlags?.isNumberOfDuplicatesEnabled) {
    formFields.unshift({
      size: 12,
      field: (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={!config?.featureFlags?.isNumberOfDuplicatesEnabled}
              required
              label="Number of copies"
              name="numberOfCopies"
              onChange={e => {
                if (e.target.value >= 1 || e.target.value === '') {
                  setEditingValue(e.target.value);
                  if (e.target.value === '') {
                    setEnableSubmitButton(false);
                  } else {
                    setEnableSubmitButton(true);
                  }
                }
              }}
              value={editingValue}
            />
          </Grid>
        </Grid>
      ),
    });
  }

  return (
    <FinalForm
      aria-label="duplicate-asset"
      title="Duplicate Asset"
      isPanel={true}
      resetButton={false}
      cancelButton={true}
      submitButtonLabel="Duplicate"
      initialValues={{}}
      formFields={formFields}
      enableSubmitButton={enableSubmitButton}
      onSubmit={value => {
        setEnableSubmitButton(false);
        postDuplicateAsset(value);
      }}
      onClose={() => {
        setOpenDuplicateAsset(false);
      }}
    />
  );
};

DuplicateAsset.propTypes = {
  setOpenDuplicateAsset: PT.func.isRequired,
  assetId: PT.string.isRequired,
  parentId: PT.string,
  getAsset: PT.func.isRequired,
  isDuplicateAssetOpen: PT.bool.isRequired,
};

DuplicateAsset.defaultProps = {
  parentId: null,
};

export default DuplicateAsset;
