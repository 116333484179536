import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license';
import App from './app';
import 'auth/graph-provider';

LicenseInfo.setLicenseKey(import.meta.env.VITE_APP_MUI_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
