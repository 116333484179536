import { useContext, useState, useEffect } from 'react';
import { AssetContext, DataSetsContext, DataTemplatesContext } from 'context';
import CreateDataSet from '../create-data-set';
import AssetsTreePanel from '../assets-tree-panel';
import Instructions from '../instructions';
import DataSetParametersList from '../data-set-parameters-list';

export default toggleOpen => {
  const { templateAssets, templateParameters, formattedAssets } =
    useContext(DataTemplatesContext);
  const { getAssets, allAssets, assetsLoading } = useContext(AssetContext);
  const { setDataSets } = useContext(DataSetsContext);
  const [assetTypes, setAssetTypes] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const getAssetsForTemplate = async () => {
    const templateAssetTypes = [];
    const assetTypesForTemplate = templateAssets.reduce((acc, curr) => {
      if (curr.assetType) {
        templateAssetTypes.push(curr);
        acc.push(curr.assetType.id);
      }
      return acc;
    }, []);
    setAssetTypes([...templateAssetTypes]);
    await getAssets({
      asset_type_id: [...assetTypesForTemplate],
      show_deleted: false,
    });
  };

  useEffect(() => {
    getAssetsForTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    toggleOpen();
  };

  const submitDataSet = values => {
    const { dataSet } = values;
    setDataSets(curr => [...dataSet, ...curr]);
    toggleOpen();
  };

  const steps = [
    {
      label: 'Instructions',
      optional: false,
      header: ' Read these instructions before creating a data set',
      content: () => <Instructions />,
    },
    {
      label: 'Select required assets',
      optional: false,
      header: '',
      content: props => (
        <AssetsTreePanel
          formattedAssets={formattedAssets}
          assetTypes={assetTypes}
          assetsLoading={assetsLoading}
          {...props}
        />
      ),
    },
    {
      label: 'Confirm parameters to add',
      optional: false,
      header: 'Review parameters',
      content: props => (
        <DataSetParametersList
          formattedAssets={formattedAssets}
          templateParameters={templateParameters}
          selectedAssets={selectedAssets}
          {...props}
        />
      ),
    },
    {
      label: 'Create data set',
      optional: false,
      header: 'Create data set',
      content: props => (
        <CreateDataSet selectedAssets={selectedAssets} {...props} />
      ),
    },
  ];

  return {
    selectedAssets,
    setSelectedAssets,
    steps,
    handleClose,
    submitDataSet,
    assetTypes,
    allAssets,
  };
};
