import PT from 'prop-types';
import {
  PostAdd as PostAddIcon,
  DomainAdd as DomainAddIcon,
  Help as HelpIcon,
  LibraryBooks as LibraryBooksIcon,
} from '@mui/icons-material';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { AssetContext } from 'context';
import TooltipButton from '../../buttons/icons/help';
import DictionaryButton from '../../buttons/icons/dictionary';
import config from '../../../config';
import StyledGrid from './title-styles';

const Title = ({ activeStep, wizard, breadcrumbs }) => {
  const { theAsset } = useContext(AssetContext);

  const ParameterButton = () => {
    return (
      <TooltipButton
        titleHeader={
          "Can't find the parameter you need? Notice an issue with an existing parameter?"
        }
        titleBody="All parameter types are managed by the DDB team. To have this resolved, please submit a Custom Parameter Request."
        onClick={() => window.open(config.url.newParameterTypeForm, '_blank')}
        icon={<HelpIcon color="primary" aria-label="helpButton" />}
      />
    );
  };

  const wizardSelection = {
    parameters: {
      icon: (
        <PostAddIcon
          fontSize="large"
          color="primary"
          aria-label="icon"
          sx={{ paddingTop: theme => theme.spacing(1) }}
        />
      ),
      header: (
        <Typography variant="h6" aria-label="wizard-title">
          Add parameters to
        </Typography>
      ),
      help: (activeStep === 0 || activeStep === 1) && (
        <div>
          <ParameterButton />
          <DictionaryButton />
        </div>
      ),
    },
    assets: {
      icon: (
        <DomainAddIcon
          fontSize="large"
          color="primary"
          aria-label="icon"
          sx={{ paddingTop: theme => theme.spacing(1) }}
        />
      ),
      header: (
        <Typography variant="h6" aria-label="wizard-title">
          Add assets to
        </Typography>
      ),
      help: activeStep === 0 && <DictionaryButton />,
    },
    dataSets: {
      icon: (
        <LibraryBooksIcon
          fontSize="large"
          color="primary"
          aria-label="icon"
          sx={{ paddingTop: theme => theme.spacing(1) }}
        />
      ),
      header: (
        <Typography variant="h6" aria-label="wizard-title">
          {'Add data set from template: '}
        </Typography>
      ),
      help: (activeStep === 0 || activeStep === 1) && <DictionaryButton />,
    },
  };

  return (
    <Grid container justify="flex-start">
      <Grid item xs={11} container>
        <Grid item>{wizardSelection[wizard].icon}</Grid>
        <StyledGrid item>{wizardSelection[wizard].header}</StyledGrid>
        <StyledGrid item>
          <Breadcrumbs aria-label="breadcrumb">
            {!isEmpty(breadcrumbs) ? (
              breadcrumbs.map((item, ind) => (
                <Typography
                  variant="h6"
                  key={item}
                  color={ind === breadcrumbs.length - 1 ? 'primary' : null}
                >
                  {
                    // eslint-disable-next-line no-nested-ternary
                    ind !== breadcrumbs.length - 1
                      ? item.name
                      : wizard === 'parameters'
                        ? `${item.name} (${theAsset.assetType?.name})`
                        : item.name
                  }
                </Typography>
              ))
            ) : (
              <Typography variant="h6" color="primary">
                {theAsset.name ? theAsset.name : 'Project level'}
              </Typography>
            )}
          </Breadcrumbs>
        </StyledGrid>
      </Grid>
      <Grid item xs={1} container justify="flex-end">
        {wizardSelection[wizard].help}
      </Grid>
    </Grid>
  );
};

Title.propTypes = {
  breadcrumbs: PT.arrayOf(
    PT.shape({ name: PT.string, id: PT.string, assetTypeId: PT.string })
  ),
  activeStep: PT.number.isRequired,
  wizard: PT.string.isRequired,
};

Title.defaultProps = {
  breadcrumbs: [],
};
export default Title;
