import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
  Breadcrumbs,
  Chip,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import { darken } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import useSummary from './hooks/use-summary';

const Summary = () => {
  const { handleToggleOpen, summary } = useSummary();
  return (
    <Box
      aria-label="summary"
      sx={{
        display: 'grid',
        direction: 'column',
        wrap: 'nowrap',
        overflowY: 'auto',
      }}
      // eslint-disable-next-line no-confusing-arrow
      bgcolor={theme =>
        theme.palette.mode === 'dark'
          ? darken(theme.palette.secondary.main, 0.9)
          : theme.palette.background.paper
      }
    >
      {Object.keys(summary)
        .reverse()
        .map((assetType, indx) => (
          <List
            disablePadding
            key={`${assetType.id - indx}`}
            dense
            aria-label="list-of-types"
          >
            <ListItem
              aria-label="type"
              button
              onClick={() => {
                handleToggleOpen(assetType);
              }}
            >
              <ListItemIcon>
                {summary[assetType].open ? (
                  <KeyboardArrowDown aria-label="collapse-icon" />
                ) : (
                  <KeyboardArrowRight aria-label="expand-icon" />
                )}
              </ListItemIcon>
              <ListItemText
                aria-label="types-name"
                primaryTypographyProps={{ variant: 'h6' }}
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
                disableTypography
                primary={`${assetType} (${summary[assetType].children.length}) `}
              />
            </ListItem>
            <Divider />
            <Collapse in={summary[assetType].open}>
              {summary[assetType].children.map(asset => (
                <ListItem
                  aria-label="instance"
                  sx={{
                    padding: 0,
                    paddingLeft: theme => theme.spacing(+9),
                  }}
                  key={asset.id}
                >
                  <ListItemText
                    aria-label="instance-name"
                    primary={
                      asset.editedName ? (
                        <Typography aria-label="edited-instance">
                          <span style={{ textDecoration: 'line-through' }}>
                            {asset.editedName}
                          </span>{' '}
                          {asset.name}
                        </Typography>
                      ) : (
                        <Typography aria-label="new-instance">
                          {asset.name}
                        </Typography>
                      )
                    }
                  />
                  {asset.parentName && (
                    <Breadcrumbs
                      aria-label="parent-name-chip"
                      sx={{
                        padding: 1,
                      }}
                    >
                      <Chip label={asset.parentName} />
                    </Breadcrumbs>
                  )}
                </ListItem>
              ))}
            </Collapse>
          </List>
        ))}
    </Box>
  );
};

export default Summary;
