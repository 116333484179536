import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
// import config from 'config';
import history from '../history';

const cookieValue = decodeURIComponent(
  document?.cookie
    ?.split('; ')
    ?.find(row => row.startsWith('ddbCookiesPolicyAccepted='))
    ?.split('=')[1]
    ?.trim()
);

let parsedCookie = {};
if (cookieValue !== 'undefined') {
  parsedCookie = JSON.parse(cookieValue);
}

const clickPluginInstance = new ClickAnalyticsPlugin();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_APP_APPLICATION_INSIGHTS_KEY,
    autoTrackPageVisitTime: true,
    disableFetchTracking: false,
    //loggingLevelConsole: config.env === 'development' ? 2 : 0,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
      [clickPluginInstance.identifier]: {
        autoCapture: true,
        urlCollectHash: true,
        urlCollectQuery: true,
      },
    },
  },
});

if (import.meta.env.VITE_APP_APPLICATION_INSIGHTS_KEY) {
  if (parsedCookie?.analytic === true) {
    /* istanbul ignore next */
    appInsights.loadAppInsights();
    /* istanbul ignore next */
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  }
}

const withAppInsights = (Component, name) =>
  withAITracking(reactPlugin, Component, name);

export { withAppInsights, appInsights, reactPlugin };
