import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router';
import { useIsAuthenticated } from '@azure/msal-react';
import qs from 'qs';
import { findIndex, isEmpty } from 'lodash';
import { UserContext } from 'context';
import { environmentContextApi, userApi } from 'api';

export default () => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const pageLimit = 48;
  const [searchParams, setSearchParams] = useSearchParams({
    sort_by: 'created_at',
    order: 'desc',
  });

  const isAuthenticated = useIsAuthenticated();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const [searchValue, setSearchValue] = useState('');
  const [paging, setPaging] = useState(null);
  const [hasMore, setHasMore] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);
  const [loadFavourites, setLoadFavourites] = useState(false);
  const [paramQuery, setParamQuery] = useState({});

  const loadAllProjects = (projectsToLoad, roles) => {
    const newProjects = isEmpty(roles)
      ? [...projectsToLoad]
      : projectsToLoad.reduce((final, curr) => {
          const projRoles = roles[0]?.roles.filter(
            role => role.resourceId === curr.projectId && role.name !== 'reader'
          );
          if (
            curr.projectDirectorEmail?.toLowerCase() ===
            user.email.toLowerCase()
          ) {
            projRoles.push({ name: 'PD', resourceId: curr.projectId });
          }
          if (
            curr.projectManagerEmail?.toLowerCase() === user.email.toLowerCase()
          ) {
            projRoles.push({ name: 'PM', resourceId: curr.projectId });
          }
          final.push({ ...curr, roles: [...projRoles] });
          return final;
        }, []);
    if (hasMore) {
      setProjects(hasMoreProjects => {
        if (hasMoreProjects.every(proj => isEmpty(proj))) {
          return [...newProjects];
        }
        return [...hasMoreProjects, ...newProjects];
      });
    } else {
      setProjects(newProjects);
    }
  };

  const getProjects = async source => {
    const didCancel = false;
    if (!hasMore) {
      setLoading(true);
      setError(undefined);
    }
    const query = { ...paramQuery, page_limit: pageLimit };

    if (paging?.next) query.after = paging.cursors.after;
    if (searchValue) query.search = searchValue;
    let projectIds = [];
    const roleQuery = [
      paramQuery.editor,
      paramQuery.approver,
      paramQuery.administrator,
      paramQuery.checker,
    ].filter(role => {
      return role !== undefined;
    });

    try {
      const userResponse = await userApi(
        'getUsersRoles',
        { emails: [user.email] },
        source.token
      );
      const { usersRoles } = userResponse;
      if (!didCancel && !isEmpty(roleQuery) && !isEmpty(usersRoles)) {
        const projectRoles = usersRoles[0]?.roles.reduce((final, curr) => {
          if (roleQuery.includes(curr.name))
            if (!final.find(proj => proj.id === curr.resourceId)) {
              final.push({ id: curr.resourceId, role: [curr.name] });
            } else {
              const indexOfProj = findIndex(
                final,
                existing => existing.id === curr.resourceId
              );
              final[indexOfProj].role.push(curr.name);
            }
          return final;
        }, []);

        projectIds = projectRoles.reduce((final, curr) => {
          if (roleQuery.every(role => curr.role.includes(role)))
            final.push(curr.id);
          return final;
        }, []);
        query.project_id = [...projectIds];
      }

      if (isEmpty(roleQuery) || (!isEmpty(roleQuery) && !isEmpty(projectIds))) {
        let response = await environmentContextApi(
          'getProjects',
          query,
          source.token
        );
        if (!response) {
          response = { favourites: [], projects: [], paging: {} };
        }
        const {
          favourites: favouritesResponse,
          projects: projectsResponse,
          paging: pagination,
        } = response;

        if (!loadFavourites) {
          loadAllProjects(projectsResponse, usersRoles);
        } else {
          setProjects(favouritesResponse, usersRoles);
        }
        setLoading(false);
        if (!loadFavourites && pagination?.next) setPaging(pagination);
        else {
          setPaging(null);
          setAllLoaded(true);
        }
      } else {
        setProjects([]);
        setAllLoaded(true);
        setLoading(false);
      }
    } catch (err) {
      if (err.response?.status === 503)
        navigate('/error', {
          replace: true,
          state: {
            status: err.response.status,
            ...err.response.data,
          },
        });
      else {
        setLoading(false);
        setError(err?.response?.data);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (
      !isEmpty(user) &&
      isAuthenticated &&
      (paging?.next || !hasMore) &&
      !loadFavourites &&
      !isEmpty(paramQuery)
    ) {
      getProjects(source);
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, searchValue, hasMore, loadFavourites, user]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (isAuthenticated && !isEmpty(user) && !isEmpty(paramQuery)) {
      getProjects(source);
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, loadFavourites, user, paramQuery]);

  useEffect(() => {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
    const { sort_by: sortBy, order, favourites } = { ...parsedSearch };
    const loadFavouritesTemp = favourites === 'true';
    setLoadFavourites(loadFavouritesTemp);
    const addSortDefaults = () => {
      const defaults = {};

      if (!sortBy) defaults.sort_by = searchParams.get('sort_by');
      if (!order) defaults.order = searchParams.get('order');

      if (!isEmpty(defaults)) {
        setSearchParams(defaults, { replace: true });
      }
      setParamQuery({ ...defaults, ...parsedSearch });
    };
    setProjects([{}, {}, {}, {}, {}, {}, {}]);
    setHasMore(0);
    setPaging(null);
    setAllLoaded(false);
    addSortDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const addProject = newProject =>
    setProjects(currProjects => [newProject, ...currProjects]);

  const handleSearch = value => {
    setProjects([{}, {}, {}, {}, {}, {}, {}]);
    setHasMore(0);
    setPaging(null);
    setAllLoaded(false);
    setSearchValue(value);
  };

  const tabList = [
    {
      path: 'allProjects',
      selected: !loadFavourites,
      params: {
        sort_by: 'created_at',
        order: 'desc',
      },
      text: 'All Projects',
      userAccess: true,
      'data-cy': 'projects-all',
    },
    {
      selected: loadFavourites,
      params: {
        sort_by: 'created_at',
        order: 'desc',
        favourites: true,
      },

      text: 'Favourites',
      userAccess: true,
      'data-cy': 'projects-favourites',
    },
  ];

  return {
    error,
    projects,
    loading,
    searchValue,
    handleSearch,
    addProject,
    hasMore,
    paging,
    allLoaded,
    setHasMore,
    getProjects,
    setProjects,
    loadFavourites,
    loadAllProjects,
    setParamQuery,
    paramQuery,
    tabList,
  };
};
