/* eslint-disable react/prop-types */
import PT from 'prop-types';
import { isEmpty } from 'lodash';
import { useEffect, useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { DataTemplatesContext } from 'context';
import AssetTree from './asset-tree';
import AssetRequirements from './asset-requirements';
import useDataSetTree from './hooks/use-data-set-tree';

const AssetsTreePanel = ({
  formattedAssets,
  assetTypes,
  change,
  values,
  assetsLoading,
}) => {
  const { templateAssets, dataTemplate } = useContext(DataTemplatesContext);

  const {
    onAddAssetClick,
    onEditClick,
    updateSelection,
    onDoneClick,
    onClearClick,
    textFieldError,
    onRemoveAssetClick,
    assetNodes,
    expanded,
    selectedAssets,
    assetsForTemplate,
  } = useDataSetTree(formattedAssets, templateAssets, assetTypes, values);

  useEffect(() => {
    change('selectedAssets', selectedAssets);
    if (!isEmpty(selectedAssets)) {
      const keyTemplateAsset = templateAssets.find(
        templateAsset => templateAsset.isKey
      );
      let keySelectedAsset = {};
      if (!isEmpty(keyTemplateAsset.assetType)) {
        keySelectedAsset = selectedAssets.find(
          selectedAsset =>
            selectedAsset.assetType.id === keyTemplateAsset.assetType.id
        );
      } else {
        keySelectedAsset.name = 'Project level';
      }
      if (keySelectedAsset?.name) {
        change(
          'suggestedName',
          `${dataTemplate.name} - ${keySelectedAsset.name}`
        );
        change(
          'dataSetName',
          `${dataTemplate.name} - ${keySelectedAsset.name}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets]);

  useEffect(() => {
    change('expanded', expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    change('allAssets', assetsForTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsForTemplate]);

  return (
    <Grid
      container
      display="flex"
      direction="row"
      alignContent="left"
      spacing={2}
      paddingBottom={10}
    >
      <Grid item container direction="column" xs={6} paddingLeft={0}>
        <Grid item>
          <Typography
            color={theme => theme.palette.secondary}
            variant="body1"
            mb={theme => theme.spacing(1)}
          >
            Select assets to apply the template parameters to
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={theme => theme.palette.secondary} variant="body2">
            A template is a way to quickly apply a group of parameters to your
            project (a data set). Select assets based on the template
            requirements on the right. The data set will be created after step
            4.
          </Typography>
        </Grid>
        <Grid item>
          <AssetTree
            assetNodes={assetNodes}
            updateSelection={updateSelection}
            onAddAssetClick={onAddAssetClick}
            onEditClick={onEditClick}
            onDoneClick={onDoneClick}
            textFieldError={textFieldError}
            onClearClick={onClearClick}
            onRemoveAssetClick={onRemoveAssetClick}
            values={values}
            assetsLoading={assetsLoading}
            change={change}
          />
        </Grid>
      </Grid>
      <AssetRequirements
        formattedAssets={formattedAssets}
        assetNodes={assetNodes}
        updateSelection={updateSelection}
        change={change}
        values={values}
      />
    </Grid>
  );
};

AssetsTreePanel.propTypes = {
  formattedAssets: PT.shape({}),
  change: PT.func.isRequired,
};
AssetsTreePanel.defaultProps = {
  formattedAssets: {},
};
export default AssetsTreePanel;
