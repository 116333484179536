/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import PT from 'prop-types';
import { List as ListIcon } from '@mui/icons-material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { useParams, useNavigate, createSearchParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Suspense, useContext } from 'react';
import EmptyState from 'components/empty-state';
import { GlobalTypesContext, AssetContext } from 'context';
import useAssets from './hooks/use-assets';
import TreeItems from './tree-items';

const Assets = ({
  assetNodes,
  loading,
  isProjectAssetHierarchy,
  expanded,
  setExpanded,
}) => {
  const { assetId } = useParams();
  const { selectedNode, setSelectedNode } = useAssets(
    assetNodes,
    isProjectAssetHierarchy,
    expanded,
    setExpanded
  );

  const { assetTypeTrees } = useContext(GlobalTypesContext);
  const { assetsLoading } = useContext(AssetContext);
  const hierarchy = isProjectAssetHierarchy ? assetNodes : assetTypeTrees;

  const navigate = useNavigate();

  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  return (
    <>
      <Button
        variant="text"
        aria-label="project-parameters-button"
        sx={{
          justifyContent: 'flex-start',
          textTransform: 'none',
          paddingLeft: theme => theme.spacing(2.5),
          paddingButton: 0,
          '& .MuiTouchRipple-child': {
            left: theme => theme.spacing(-12.5),
          },
        }}
        onClick={() => {
          paramsValues.project_parameter = true;
          navigate({
            pathname: `parameters`,
            search: `?${createSearchParams(paramsValues)}`,
          });
        }}
      >
        <Typography variant="body2">Project</Typography>
      </Button>
      <Grid
        aria-label="asset-tree-navigation"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
        }}
      >
        <SimpleTreeView
          selected={assetId || null}
          expandedItems={expanded || []}
        >
          {(loading || assetsLoading) && isEmpty(hierarchy) ? (
            [...Array(1).keys()].map(load => (
              <Skeleton
                aria-label="loading"
                key={load}
                width="100%"
                height="30px"
              />
            ))
          ) : !loading && isEmpty(hierarchy) ? (
            <EmptyState
              Icon={ListIcon}
              title="No Assets"
              subtitle="Try adding a new asset using the button above"
            />
          ) : (
            <Suspense
              fallback={
                <Skeleton aria-label="loading" width="100%" height="30px" />
              }
            >
              {hierarchy.map(node => {
                return (
                  <TreeItems
                    isHierarchy={isProjectAssetHierarchy}
                    node={node}
                    key={node.id}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                  />
                );
              })}
            </Suspense>
          )}
        </SimpleTreeView>
      </Grid>
    </>
  );
};
Assets.propTypes = {
  loading: PT.bool,
  isProjectAssetHierarchy: PT.bool,
  assetNodes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      isAssetInstance: PT.bool,
      children: PT.array,
    })
  ),
  expanded: PT.arrayOf(PT.string).isRequired,
  setExpanded: PT.func.isRequired,
};

Assets.defaultProps = {
  isProjectAssetHierarchy: true,
  loading: false,
  assetNodes: [],
};
export default Assets;
