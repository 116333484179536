import { List, Typography, Divider } from '@mui/material';
import { useContext, useEffect } from 'react';
import PT from 'prop-types';
import { useSearchParams } from 'react-router';
import qs from 'qs';
import { AssetContext, ProjectContext } from 'context';
import ItemType from './type-item';
import Instance from './instance-item';
import { StyledGrid, StyledScrolledGrid } from './column-styles';

const Column = ({ columnIndex, assets, setRemoveAssetWarning }) => {
  const { project } = useContext(ProjectContext);
  const { assetManipulation, allAssets, allRefs } = useContext(AssetContext);
  const [searchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const showDeleted = parsedSearch.show_deleted_assets === 'true';

  const columnName =
    columnIndex !== 0
      ? allAssets.reduce((first, { id, name }) => {
          if (id === assetManipulation.isSelected[columnIndex - 1]) return name;
          return first;
        }, {})
      : project.shortTitle;

  useEffect(() => {
    if (
      assetManipulation.isSelected[columnIndex] &&
      allRefs[assetManipulation.isSelected[columnIndex]] &&
      (showDeleted ||
        (!showDeleted &&
          allAssets.find(
            asset =>
              asset.id === assetManipulation.isSelected[columnIndex] &&
              !asset.deletedAt
          )))
    ) {
      allRefs[assetManipulation.isSelected[columnIndex]].current.scrollIntoView(
        {
          inline: 'start',
          block: 'nearest',
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRefs, assetManipulation]);

  return (
    <StyledGrid item borderRight={1} aria-label="column">
      <List>
        <Typography
          variant="body2"
          color="textSecondary"
          pb={1}
          pl={1}
          noWrap
          aria-label="column-name"
        >
          {`${columnName} contains`}
        </Typography>
        <Divider aria-label="column-divider" />
        <StyledScrolledGrid item>
          {assets.map((type, index) => {
            return (
              <div key={`${type.id}-${type.parent}`}>
                <ItemType
                  key={`type-${type.id}-${type.parent}`}
                  type={type}
                  columnIndex={columnIndex}
                  open={assetManipulation.isOpen[type.name]}
                />
                <Instance
                  key={`asset-instance-${type.id}-${type.parent}`}
                  type={type}
                  columnIndex={columnIndex}
                  index={index}
                  setRemoveAssetWarning={setRemoveAssetWarning}
                />
              </div>
            );
          })}
        </StyledScrolledGrid>
      </List>
    </StyledGrid>
  );
};

Column.propTypes = {
  columnIndex: PT.number.isRequired,
  assets: PT.arrayOf(PT.shape({})).isRequired,
  setRemoveAssetWarning: PT.func.isRequired,
};
export default Column;
