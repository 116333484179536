import SwaggerClient from 'swagger-client';
import { isPlainObject, size } from 'lodash';
import axios from 'axios';

const createClient = async (specUrl, apiVersion) => {
  const requestInterceptor = request => {
    const axiosRequest = {
      ...request,
      userFetch: async (url, req) => {
        const newRequest = {
          ...req,
          data: req.body,
          headers: {
            ...req.headers,
            Version: apiVersion,
            'App-Client-Type': 'Web',
          },
        };
        const axiosResponse = await axios(url, newRequest);
        // If body doesn't come back then give default value null
        // https://developer.mozilla.org/en-US/docs/Web/API/Response/Response
        const body = isPlainObject(axiosResponse.data)
          ? JSON.stringify(axiosResponse.data)
          : null;
        const init = {
          status: axiosResponse.status,
        };
        // If no keys for headers don't add to the response
        if (size(axiosResponse.headers)) init.headers = axiosResponse.headers;
        return new Response(body, init);
      },
    };
    return axiosRequest;
  };

  const client = new SwaggerClient(specUrl, { requestInterceptor });
  return client;
};

export default createClient;
