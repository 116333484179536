import { CookiesProvider } from 'react-cookie';
import { unstable_HistoryRouter as Router } from 'react-router';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import pca from 'auth/auth-provider';
import { ThemeProvider, UserProvider } from 'context';
import SnackbarProvider from './snackbar-provider';
import { AppInsightsProvider } from './app-insights';
import history from './history';

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <AppInsightsProvider>
        <CookiesProvider>
          <ThemeProvider useSuspense={false}>
            <CssBaseline />
            <Router history={history}>
              <SnackbarProvider>
                <MsalProvider instance={pca}>
                  <UserProvider>
                    <Component {...props} />
                  </UserProvider>
                </MsalProvider>
              </SnackbarProvider>
            </Router>
          </ThemeProvider>
        </CookiesProvider>
      </AppInsightsProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
