import { Typography, Grid, Tooltip } from '@mui/material';
import PT from 'prop-types';
import { useContext } from 'react';
import { StyledSwitch } from 'components/styles/switch-styles';
import { ThemeContext } from 'context';

function CookiesItems({ cookieList }) {
  const { localTheme } = useContext(ThemeContext);
  return (
    <Grid aria-label="cookies-items">
      <Typography variant="h6" component="h2">
        {cookieList.type}
      </Typography>
      <ul
        style={{ listStyleType: 'none' }}
        data-cy="resources-links"
        aria-label="cookies-list"
      >
        {cookieList.cookies?.map(cookie => {
          return (
            <li key={cookie.name} aria-label="cookies-list-item">
              <Grid container sx={{ marginBottom: theme => theme.spacing(2) }}>
                <Grid item xs={10}>
                  <Typography component="subtitle" variant="subtitle2">
                    {cookie.name}:
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      cookie?.tooltip && cookie?.disabled ? cookie.tooltip : ''
                    }
                    data-testid="cookiesTooltip"
                  >
                    <span>
                      <StyledSwitch
                        palette={localTheme}
                        inputProps={{
                          'aria-label': `${cookie.name} - switch`,
                          'data-cy': `cookies-toggle`,
                        }}
                        onChange={cookie?.onChange}
                        disabled={cookie?.disabled}
                        checked={cookie?.checked}
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Typography variant="body2">{cookie.description}</Typography>
              </Grid>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
}

CookiesItems.propTypes = {
  cookieList: PT.shape({
    type: PT.string.isRequired,
    cookies: PT.arrayOf(PT.shape({})),
  }).isRequired,
};

export default CookiesItems;
