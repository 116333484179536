import PT from 'prop-types';
import qs from 'qs';
import { useLocation } from 'react-router';
import { FilterList, ArrowDownward, ArrowUpward } from '@mui/icons-material/';
import { Grid, Tooltip } from '@mui/material';
import { Fab } from 'components/buttons';

function FilterMenuButtons({ handleClick, handleSortDirection }) {
  const { search } = useLocation();
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });

  return (
    <>
      <Grid item xs={2} sm={1}>
        <Tooltip title="Filter and Sort">
          <Fab
            data-testid="filterButton"
            size="small"
            aria-label="Filter and Sort"
            onClick={handleClick}
          >
            <FilterList />
          </Fab>
        </Tooltip>
      </Grid>
      <Grid item xs={2} sm={1}>
        <Tooltip title="Sort direction">
          <Fab
            data-testid="sortButton"
            size="small"
            aria-label="Sort direction"
            onClick={handleSortDirection}
          >
            {parsedSearch.order === 'asc' ? (
              <ArrowUpward data-testid="asc" />
            ) : (
              <ArrowDownward data-testid="desc" />
            )}
          </Fab>
        </Tooltip>
      </Grid>
    </>
  );
}
FilterMenuButtons.propTypes = {
  handleClick: PT.func.isRequired,
  handleSortDirection: PT.func.isRequired,
};

export default FilterMenuButtons;
