import PT from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Wizard from '../../../components/wizard';
import useParameterWizard from './hooks/use-parameter-wizard';
import stepOptions from './steps';

const ParametersWizard = ({ open, handleToggleOpen }) => {
  const steps = stepOptions();

  const { submit, validatePostedParameter } =
    useParameterWizard(handleToggleOpen);

  return (
    <Form
      key="add-parameters"
      onSubmit={(values, form) => {
        return submit(values, form.mutators.setFormAttribute);
      }}
      initialValues={{
        parameterTypes: [],
        itemsImport: [],
        parameterTypesImport: [],
      }}
      validate={values => {
        return validatePostedParameter(values);
      }}
      mutators={{
        ...arrayMutators,
        setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
          changeValue(state, fieldName, () => fieldVal);
        },
      }}
      render={({
        values,
        handleSubmit,
        form: {
          mutators: { setFormAttribute, push, remove },
        },
        ...rest
      }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          aria-label="parameter-wizard-form"
        >
          <Wizard
            wizard="parameters"
            open={open}
            values={values || []}
            steps={steps}
            handleToggleOpen={handleToggleOpen}
            handleSubmit={handleSubmit}
            setFormAttribute={setFormAttribute}
            push={push}
            remove={remove}
            submitError={rest.submitError}
            {...rest}
          />
        </form>
      )}
    />
  );
};

ParametersWizard.propTypes = {
  open: PT.bool.isRequired,
  handleToggleOpen: PT.func.isRequired,
};

export default ParametersWizard;
