import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PT from 'prop-types';
import axios from 'axios';
import { useParams } from 'react-router';
import { auditApi, parametersApi, referenceDataApi, handleApiError } from 'api';

export const HistoryContext = React.createContext();

export const HistoryProvider = ({ children }) => {
  const [allHistories, setAllHistories] = useState([]);
  const [newlyRequestedHistories, setNewlyRequestedHistories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [allParameters, setAllParameters] = useState([]);
  const [areParametersLoaded, setAreParametersLoaded] = useState(false);
  const [areAssetsLoaded, setAreAssetsLoaded] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [afterCursor, setAfterCursor] = useState(null);
  const [finalCursor, setFinalCursor] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [areSourcesLoaded, setAreSourcesLoaded] = useState(false);
  const [allSources, setAllSources] = useState([]);

  const { projectId } = useParams();

  const getHistory = useCallback(
    async (after = null) => {
      if (finalCursor) return;
      if (!after) setIsLoaded(false);
      setIsLoadingMore(true);

      const source = axios.CancelToken.source();
      const query = { project_id: projectId };
      if (after) query.after = after;

      try {
        const response = await auditApi('getHistory', query, source.token);
        const { snapshots, paging } = response;
        setNewlyRequestedHistories(snapshots);

        if (paging?.cursors?.after) {
          setAfterCursor(paging.cursors.after);
        } else {
          setFinalCursor(true);
        }
      } catch (error) {
        handleApiError(error);
        setIsLoadingMore(false);
        setIsLoaded(false);
      }
    },
    [projectId, finalCursor]
  );

  useEffect(() => {
    setFinalCursor(false);
    setAfterCursor(null);
    setAllHistories([]);
    setNewlyRequestedHistories([]);
    getHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setAreParametersLoaded(false);
    setAreAssetsLoaded(false);
    setAreSourcesLoaded(false);
    const source = axios.CancelToken.source();
    let didCancel = false;

    const getParameters = async (parameterIds, after) => {
      if (!parameterIds.length) {
        setAreParametersLoaded(true);
        return;
      }

      const query = {
        project_id: projectId,
        parameter_id: parameterIds,
        show_deleted: true,
      };
      if (after) query.after = after;
      const response = await parametersApi(
        'getAllParameters',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { parameters: parametersList, paging } = response;
        setAllParameters(curr => [...curr, ...parametersList]);

        if (paging?.cursors?.after) {
          await getParameters(parameterIds, paging?.cursors?.after);
        } else {
          setAreParametersLoaded(true);
        }
      }
    };

    const getAssets = async (assetIds, after) => {
      if (!assetIds.length) {
        setAreAssetsLoaded(true);
        return;
      }

      const query = {
        project_id: projectId,
        asset_id: assetIds,
        show_deleted: true,
      };
      if (after) query.after = after;
      const response = await parametersApi(
        'getAssets',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { assets: assetsList, paging } = response;
        setAllAssets(curr => [...curr, ...assetsList]);

        if (paging?.cursors?.after) {
          await getAssets(assetIds, paging?.cursors?.after);
        } else {
          setAreAssetsLoaded(true);
        }
      }
    };

    const getSources = async (sourceIds, after) => {
      if (!sourceIds.length) {
        setAreSourcesLoaded(true);
        return;
      }

      const query = {
        project_id: projectId,
        source_id: sourceIds,
        show_deleted: true,
      };
      if (after) query.after = after;
      const response = await referenceDataApi(
        'getSources',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { sources: sourcesList, paging } = response;
        setAllSources(curr => [...curr, ...sourcesList]);

        if (paging?.cursors?.after) {
          await getSources(sourceIds, paging?.cursors?.after);
        } else {
          setAreSourcesLoaded(true);
        }
      }
    };

    if (newlyRequestedHistories.length > 0) {
      setAllParameters([]);
      setAllAssets([]);
      setAllSources([]);

      const parameterIds = Array.from(
        new Set(
          newlyRequestedHistories
            .filter(historyItem => historyItem.parameterId !== undefined)
            .map(historyItem => historyItem.parameterId)
        )
      );

      const sourceIds = Array.from(
        new Set(
          newlyRequestedHistories
            .filter(historyItem => historyItem.sourceId !== undefined)
            .map(historyItem => historyItem.sourceId)
        )
      );

      const assetIds = Array.from(
        newlyRequestedHistories.reduce((set, historyItem) => {
          if (historyItem.assetId !== undefined) {
            set.add(historyItem.assetId);
            if (historyItem.parents?.[0]?.id !== null) {
              set.add(historyItem.parents[0].id);
            }
          }
          return set;
        }, new Set())
      );

      getParameters(parameterIds);
      getAssets(assetIds);
      getSources(sourceIds);
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyRequestedHistories]);

  useEffect(() => {
    if (areParametersLoaded && areAssetsLoaded && areSourcesLoaded) {
      const historiesWithDetails = newlyRequestedHistories.map(history => {
        if (history.parameterId !== undefined) {
          const parameter = allParameters.find(
            param => param.id === history.parameterId
          );
          return {
            ...history,
            parameterName: parameter?.parameterType?.name,
            parentName: parameter?.parents[0]?.name,
            parentId: parameter?.parents[0]?.id,
          };
        }
        if (history.assetId !== undefined) {
          const asset = allAssets.find(
            assetItem => assetItem.id === history.assetId
          );
          const parentAsset = allAssets.find(
            parentAssetItem => parentAssetItem.id === history.parents[0]?.id
          );
          return {
            ...history,
            currentAssetName: asset?.name,
            assetTypeName: asset?.assetType?.name,
            parentName: parentAsset?.name,
          };
        }
        if (history.sourceId !== undefined) {
          const source = allSources.find(
            sourceItem => sourceItem.id === history.sourceId
          );
          return {
            ...history,
            sourceName: source?.title,
          };
        }
        return history;
      });

      setAllHistories(curr => [...curr, ...historiesWithDetails]);
      setIsLoaded(true);
      setIsLoadingMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areParametersLoaded, areAssetsLoaded, areSourcesLoaded]);

  const contextValue = useMemo(
    () => ({
      isLoaded,
      allHistories,
      fetchMoreHistories: () => getHistory(afterCursor),
      isLoadingMore,
      finalCursor,
    }),
    [
      isLoaded,
      allHistories,
      getHistory,
      afterCursor,
      isLoadingMore,
      finalCursor,
    ]
  );

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
};

HistoryProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
