import PT from 'prop-types';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { MenuItem, Typography, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { StyledIconButton } from '../../../buttons';

function ManageAccount({ title, handleClose }) {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component="a"
          color="inherit"
          data-cy="manage-account"
          onClick={() => {
            navigate('/user-settings/theme');
            handleClose();
          }}
          aria-label="settings"
        >
          <SettingsIcon data-testid="manage-account" />
          <Typography
            variant="button"
            sx={{ paddingLeft: theme => theme.spacing(1) }}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="settings"
          aria-label="settings"
          data-cy="manage-account"
          component="a"
          onClick={() => navigate('/user-settings/theme')}
          icon={<SettingsIcon data-testid="manage-account" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="settings"
          component="a"
          onClick={() => navigate('/user-settings/theme')}
          aria-label="settings"
          data-cy="manage-account"
          startIcon={<SettingsIcon data-testid="manage-account" />}
        >
          SETTINGS
        </Button>
      )}
    </>
  );
}

ManageAccount.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

ManageAccount.defaultProps = {
  title: null,
  handleClose: null,
};

export default ManageAccount;
