import formatTitle from './format-title';

export default email => {
  if (email) {
    const nameOnly = email.substring(0, email.indexOf('@'));
    return nameOnly
      .split('.')
      .map(name => formatTitle(name))
      .join('')
      .match(/[A-Z][a-z]+/g)
      .join(' ');
  }
  return '';
};
