import PT from 'prop-types';
import { useState } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import {
  Skeleton,
  Box,
  Collapse,
  Typography,
  IconButton,
  Popover,
  Tooltip,
} from '@mui/material';
import { parameterType } from 'types';
import { DataGrid } from 'components/tables';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import StatusCircle from 'components/status-circle';
import { Person, PersonCard } from 'components/microsoft';
import { formatDate } from 'utils';
import DataDrawerGrid from '../grid';

function Status({ parameter, header, statusHistory, statusLoading }) {
  const [open, setOpen] = useState(false);
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEmail(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      hideable: false,
      flex: 0.5,
      maxWidth: 80,
      display: 'flex',
      renderCell: ({ value }) => <StatusCircle status={value} />,
    },
    {
      field: 'date',
      headerName: 'Status History',
      flex: 1,
      hideable: false,
      maxWidth: 150,
      type: 'dateTime',
      valueGetter: value => value && new Date(value),
      display: 'flex',
      renderCell: ({ value }) => formatDate(value, undefined) || null,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated by',
      flex: 1,
      valueGetter: value => value?.email,
      display: 'flex',
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value}>
            <IconButton aria-describedby={id} onClick={handleClick}>
              <Person
                personQuery={value}
                fallbackDetails={{
                  userPrincipalName: value,
                  mail: value,
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1.5,
      display: 'flex',
    },
  ];
  return (
    <Box sx={{ width: '100%' }} aria-label="statusVersionComponent">
      <DataDrawerGrid
        icon={
          <CheckCircleOutline
            sx={{
              color: theme =>
                parameter?.deletedAt && theme.palette.secondary.dark,
            }}
          />
        }
        caption={
          <Typography variant="caption" color="textSecondary">
            Status History
          </Typography>
        }
        content={
          <Box sx={{ width: '100%' }}>
            {statusLoading ? (
              <Skeleton height={30} width="100%" display="inline" />
            ) : (
              <>
                <ExpandOrCollapseButton
                  open={open}
                  onClick={handleChangeOpen}
                  disabled={header === 'Not defined'}
                />
                <Typography
                  component="p"
                  variant="subtitle1"
                  display="inline"
                  sx={{
                    color: theme =>
                      parameter?.deletedAt && theme.palette.secondary.dark,
                  }}
                >
                  {header}
                </Typography>
              </>
            )}
            <Collapse in={open}>
              <DataGrid
                aria-label="detailsTable"
                columns={columns}
                onCellClick={({ value }) => setEmail(value)}
                exportName={`project-${parameter?.projectId}-parameter-${parameter?.id}-status-history`}
                rows={statusHistory}
                loading={statusLoading}
                disableSelectionOnClick
                hideFooter
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
              />
            </Collapse>
          </Box>
        }
      />
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PersonCard isExpanded={false} personQuery={email} />
      </Popover>
    </Box>
  );
}
Status.propTypes = {
  parameter: parameterType,
  header: PT.string.isRequired,
  statusHistory: PT.arrayOf(PT.shape({})),
  statusLoading: PT.bool,
};
Status.defaultProps = {
  parameter: {},
  statusHistory: [],
  statusLoading: false,
};

export default Status;
