import { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import qs from 'qs';
import { useSearchParams } from 'react-router';
import { AddButton, StyledIconButton } from 'components/buttons';
import ConfidentialChip from 'components/chips/confidential';
import { FavouritesButton } from 'components/favourites';
import { ProjectContext, ProjectPermissionContext } from 'context';
import FiltersButton from 'components/buttons/icons/filters';
import config from 'config';

export default (
  open,
  handleClick,
  handleAdd,
  disabled,
  filterCount,
  tooltipMessage,
  rest
) => {
  const { writePermission } = useContext(ProjectPermissionContext);
  const { project } = useContext(ProjectContext);
  const [searchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  const actionButtons = {
    parameters: (
      <Grid
        container
        item
        xs={project?.confidential ? 5 : 4}
        justifyContent="flex-end"
        flexWrap="true"
      >
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
          sx={{ paddingTop: '10px' }}
        >
          {project?.confidential && (
            <Box display="flex" justifyContent="flex-end" alignItems="centre">
              <ConfidentialChip iconOnly />
            </Box>
          )}
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
          sx={{
            paddingRight: theme => theme.spacing(0.5),
            paddingTop: theme => theme.spacing(0.5),
          }}
        >
          <FavouritesButton project={project} />
        </Grid>
        <Grid
          display="flex"
          justifyContent="flex-end"
          item
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
        >
          <AddButton
            display="flex"
            justifyContent="flex-end"
            alignItems="centre"
            tooltip={
              writePermission
                ? 'Add new assets'
                : "You don't have permission to do this"
            }
            onClick={handleClick}
            disabled={!writePermission}
          />
        </Grid>
      </Grid>
    ),
    templates: (
      <Grid container item xs={4} justifyContent="flex-end" flexWrap="true">
        <Grid
          sx={{
            paddingRight: theme => theme.spacing(1),
            paddingTop: theme => theme.spacing(0.5),
          }}
          display="flex"
          justifyContent="flex-end"
          item
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
        >
          <FiltersButton
            onClick={handleClick}
            filterCount={filterCount}
            {...rest}
          />
        </Grid>
        {config.featureFlags.areTemplatesButtonsEnabled && (
          <Grid
            display="flex"
            justifyContent="flex-end"
            item
            xs={open ? 12 : true}
            sm={open ? 12 : true}
            md={open ? 12 : true}
            lg={open ? 12 : true}
            xl={open ? 4 : true}
          >
            <AddButton
              onClick={handleAdd}
              disabled={disabled}
              tooltip={
                disabled ? "You don't have permission to do this" : 'Add'
              }
            />
          </Grid>
        )}
      </Grid>
    ),
    dataSets: (
      <Grid
        container
        justifyContent={
          config.featureFlags.areTemplatesButtonsEnabled
            ? 'space-between'
            : 'flex-end'
        }
        alignItems="center"
        id="dataSetHeader"
        sx={{
          backgroundColor: theme => theme.palette.action.hover,
        }}
      >
        {config.featureFlags.areTemplatesButtonsEnabled && (
          <Grid item>
            <FiltersButton
              filterCount={filterCount}
              {...rest}
              disabled
              onClick={handleClick}
            />
            <StyledIconButton
              title="Sort direction"
              onClick={() => {}}
              aria-label="Sort direction"
              color="default"
              disabled
              icon={
                parsedSearch.order === 'asc' ? (
                  <ArrowUpward data-testid="asc" />
                ) : (
                  <ArrowDownward data-testid="desc" />
                )
              }
            />
          </Grid>
        )}
        <Grid item>
          <AddButton
            onClick={handleAdd}
            disabled={writePermission ? disabled : writePermission}
            tooltip={
              writePermission
                ? tooltipMessage
                : "You don't have permission to do this"
            }
          />
        </Grid>
      </Grid>
    ),
  };

  return { actionButtons };
};
