import { MenuBook } from '@mui/icons-material';
import TooltipButton from '../help';
import config from '../../../../config';

const DictionaryButton = () => {
  return (
    <TooltipButton
      titleHeader="Open DDB Data Dictionary Dashboard?"
      titleBody="The data dictionary is a one-stop-shop dashboard for exploring what parameters, assets, and units are available in DDB. The data dictionary is useful for finding all parameters, the assets they are linked to, and helping inform if a request for a new parameter or unit should be made. The dashboard can also help retrieve ids, view examples from other projects, and see a visual representation of the asset hierarchy."
      onClick={() => {
        window.open(config.url.docsDataDictionary, '_blank');
      }}
      icon={<MenuBook color="primary" aria-label="dictionaryButton" />}
    />
  );
};

export default DictionaryButton;
