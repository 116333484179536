/* eslint-disable no-nested-ternary */
import PT from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { isEmpty } from 'lodash';
import { Grid, Button, CircularProgress, Box } from '@mui/material';
import FinalForm from 'components/forms';
import Alert from 'components/alerts';
import { Autocomplete, Select } from 'components/pickers/final-form-fields';
import { stringValidation, taxonomyNameValidation } from 'utils';
import useCreateParameterType from './hooks/use-create-parameter-type';

const ParameterTypeCreator = ({
  openParameterCreator,
  setOpenParameterCreator,
  rowSelectionModel,
  push,
  setRowSelectionModel,
  availableParameterTypes,
}) => {
  const {
    nonCustomValue,
    allUnitTypes,
    unitTypesLoading,
    selectedUnitType,
    setSelectedUnitType,
    handleAutocompleteChange,
    handleSelectUnitTypeChange,
    getHelperText,
    availableUnits,
    submitNewParameterTypes,
    isUnitDisabled,
    existingParamValue,
    allParameterTypes,
    allLoading,
    error,
  } = useCreateParameterType(
    push,
    setOpenParameterCreator,
    openParameterCreator,
    setRowSelectionModel,
    availableParameterTypes
  );

  const formFields = [
    {
      size: 6,
      field: (
        <Field name="name">
          {({ input }) => (
            <Autocomplete
              label="Name"
              freeSolo
              options={allParameterTypes}
              required
              name="name"
              error={
                !!nonCustomValue?.name ||
                !input.value ||
                !taxonomyNameValidation(input?.value) ||
                !!existingParamValue?.id
              }
              clearOnBlur={false}
              onChange={(event, value, reason) => {
                if (reason === 'reset' || reason === 'createOption') {
                  return;
                }
                handleAutocompleteChange(event, value, reason);
              }}
              value={input.value || ''}
              onInputChange={(event, value, reason) => {
                if (reason === 'reset' || reason === 'createOption') {
                  return;
                }
                const trimmedValue = value.trim();
                const matchingOption = allParameterTypes.find(
                  option =>
                    option.name.toUpperCase() === trimmedValue.toUpperCase()
                );
                if (matchingOption) {
                  handleAutocompleteChange(
                    event,
                    matchingOption,
                    'selectOption'
                  );
                } else {
                  handleAutocompleteChange(event, trimmedValue, reason);
                  input.onChange(value);
                }
              }}
              aria-label="parameter-type-name"
              key={option => option.id}
              loading={allLoading}
              getOptionValue={option => option}
              getOptionLabel={option => option.name || option}
              helperText={
                !taxonomyNameValidation(input?.value)
                  ? 'Invalid Character'
                  : !isEmpty(existingParamValue)
                    ? 'A Parameter with this name already exists on this asset'
                    : isEmpty(nonCustomValue)
                      ? 'Enter a new parameter name. Avoid using project specific codes or names'
                      : "This parameter type already exists, please click on 'Use existing parameter type' below."
              }
            />
          )}
        </Field>
      ),
    },
    {
      size: 6,
      field: (
        <Field name="dataType">
          {({ input }) => (
            <FormSpy subscription={{ mutators: true }}>
              {({ form: { mutators } }) => {
                const handleChange = event => {
                  input.onChange(event);
                  const dataType = event.target.value;
                  if (
                    dataType === 'string' ||
                    dataType === 'date' ||
                    dataType === 'boolean'
                  ) {
                    mutators.remove('unitType');
                    mutators.remove('unit');
                    setSelectedUnitType(null);
                  }
                };

                return (
                  <Select
                    aria-label="select-data-type"
                    label="Data Type"
                    name="dataType"
                    options={[
                      {
                        id: 'string',
                        name: `String: The parameter will accept both text and number`,
                      },
                      {
                        id: 'boolean',
                        name: `Boolean: The parameter will accept only true or false`,
                      },
                      {
                        id: 'date',
                        name: `Date: The parameter will accept only a date`,
                      },
                      {
                        id: 'float',
                        name: `Float: The parameter will accept only numbers including decimals`,
                      },
                      {
                        id: 'integer',
                        name: `Integer: The parameter will accept only whole numbers`,
                      },
                    ]}
                    required
                    error={!input.value}
                    helperText={getHelperText(input.value)}
                    onChange={handleChange}
                  />
                );
              }}
            </FormSpy>
          )}
        </Field>
      ),
    },
    {
      size: 6,
      field: (
        <Field name="unitType">
          {({ input, form }) => (
            <FormSpy subscription={{ mutators: true, values: true }}>
              {({ form: { mutators }, values }) => {
                return (
                  <Autocomplete
                    {...input}
                    loading={unitTypesLoading}
                    required={false}
                    aria-label="select-unit-type"
                    label="Unit Type (Length, Volume, Area, Density...)"
                    name="unitType"
                    options={allUnitTypes}
                    helperText={
                      isUnitDisabled(values.dataType)
                        ? 'This data type does not accept units'
                        : 'Define the units that will be available for your parameter'
                    }
                    key={option => option.id}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => {
                      const units = [];
                      option.unitSystems?.forEach(system => {
                        system?.units.forEach(unit => {
                          units.push(unit.symbol);
                        });
                      });
                      const unitSymbols = units.slice(0, 5).join(', ');
                      return unitSymbols
                        ? `${option.name} (${unitSymbols})`
                        : option.name;
                    }}
                    value={selectedUnitType}
                    onChange={(event, value) =>
                      handleSelectUnitTypeChange(event, value, form, mutators)
                    }
                    disabled={isUnitDisabled(values.dataType)}
                  />
                );
              }}
            </FormSpy>
          )}
        </Field>
      ),
    },
    {
      size: 6,
      field: (
        <Field name="unit">
          {({ meta }) => (
            <FormSpy subscription={{ mutators: true, values: true }}>
              {({ values }) => {
                return (
                  <Select
                    disabled={!selectedUnitType || !availableUnits.length}
                    required={false}
                    aria-label="select-unit"
                    label="Default Unit (m, cm, km, kg...)"
                    name="unit"
                    options={availableUnits}
                    helperText={
                      isUnitDisabled(values.dataType)
                        ? 'This data type does not accept units'
                        : 'Select a default unit for this parameter.'
                    }
                    error={meta.error}
                  />
                );
              }}
            </FormSpy>
          )}
        </Field>
      ),
    },
  ];

  const additionalButton = (
    <Button
      data-cy="button"
      variant="contained"
      disabled={isEmpty(nonCustomValue)}
      onClick={() => {
        setRowSelectionModel([
          ...rowSelectionModel,
          nonCustomValue.parameterTypeId,
        ]);
        push('parameterTypes', nonCustomValue);
        if (!nonCustomValue.currentAvailableParameter) {
          push('itemsImport', nonCustomValue);
        }
        setOpenParameterCreator(!openParameterCreator);
      }}
    >
      Use existing parameter type
    </Button>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      height={allLoading ? '40vh' : 'auto'}
    >
      {allLoading && (
        <CircularProgress size={100} aria-label="loadingSkeleton" />
      )}
      {error && (
        <Box
          sx={{ padding: theme => theme.spacing(3) }}
          aria-label="create-type-alert"
        >
          <Alert title={error.msg} text={error.details} />
        </Box>
      )}
      {!allLoading && !unitTypesLoading && allParameterTypes.length && (
        <Grid item xs={12}>
          <FinalForm
            title="Create local custom parameter"
            subtitle="New parameters created here will only be available in your project. If the parameter already exists you can use it by clicking “Use existing parameter type”."
            onClose={() => {
              setOpenParameterCreator(!openParameterCreator);
            }}
            onSubmit={submitNewParameterTypes}
            validate={values => {
              const errors = {};
              if (allLoading) {
                errors.name = 'Required';
              }
              if (
                !values.name ||
                !isEmpty(nonCustomValue) ||
                !isEmpty(existingParamValue)
              ) {
                errors.name = 'Required';
              }
              if (values.unitType && !values.unit) {
                errors.unit = 'Required';
              }
              if (!values.dataType) {
                errors.dataType = 'Required';
              }
              if (!stringValidation(values?.name)) {
                errors.name = 'Invalid name';
              }
              if (!taxonomyNameValidation(values.name)) {
                errors.name = 'Invalid Character';
              }
              return errors;
            }}
            isPanel
            initialValues={{}}
            formFields={formFields}
            resetButton={false}
            cancelButton
            additionalButton
            buttonComponent={additionalButton}
          />
        </Grid>
      )}
    </Grid>
  );
};

ParameterTypeCreator.propTypes = {
  openParameterCreator: PT.bool.isRequired,
  setOpenParameterCreator: PT.func.isRequired,
  rowSelectionModel: PT.arrayOf(PT.string).isRequired,
  push: PT.func.isRequired,
  setRowSelectionModel: PT.func.isRequired,
  availableParameterTypes: PT.arrayOf(PT.shape({})).isRequired,
};

export default ParameterTypeCreator;
