import { Typography, Grid, Tooltip, Divider } from '@mui/material';
import { useParams } from 'react-router';
import { useContext } from 'react';
import { Link as LinkIcon } from '@mui/icons-material';
import { StyledIconButton } from 'components/buttons';
import { DataGrid } from 'components/tables';
import { SourcesContext } from 'context';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { formatValueUnit } from 'utils';

const LinkedParameters = () => {
  const { sourceParameters, parametersLoading, paramAfter, setHasMore } =
    useContext(SourcesContext);
  const { projectId } = useParams();

  const CustomFooterStatusComponent = () => {
    return (
      <>
        <Divider />
        <Grid aria-label="customFooter" container justifyContent="flex-end">
          <Typography
            variant="body2"
            sx={{ padding: theme => theme.spacing(1) }}
          >
            {paramAfter
              ? `Loaded first ${sourceParameters.length} Rows`
              : `Total Rows: ${sourceParameters.length}`}
          </Typography>
        </Grid>
      </>
    );
  };

  const columns = [
    {
      field: 'parameterType',
      headerName: 'Parameter name',
      flex: 1,
      type: 'string',
      valueGetter: value => value?.name,
    },
    {
      field: 'parents',
      headerName: 'Parent Assets',
      flex: 1,
      type: 'string',
      valueGetter: value => value[0]?.name || 'Project',
    },
    {
      flex: 1,
      hideable: false,
      field: 'value',
      headerName: 'Value',
      valueGetter: (value, row) => {
        const parameterValue = row?.selectedEntry?.values?.[0]?.value;
        const units = row?.selectedEntry?.values?.[0]?.unit?.symbol || '';
        const valueWithUnit = formatValueUnit(parameterValue, units);
        return valueWithUnit;
      },
    },
    {
      field: 'Link',
      headerName: 'Link',
      align: 'center',
      flex: 0.25,
      display: 'flex',
      renderCell: ({ row }) => {
        return row.deletedAt ? (
          <Tooltip aria-label="removed-parameter" title="Removed">
            <RemovedAssetIcon
              sx={{ color: theme => theme.palette.secondary.dark }}
            />
          </Tooltip>
        ) : (
          <StyledIconButton
            title={`Link to Parameter: ${row.parameterType.name}`}
            tooltipProps={{ placement: 'left' }}
            href={
              row?.parents[0]?.id
                ? `/projects/${projectId}/asset/${row?.parents[0]?.id}/parameters/${row.id}`
                : `/projects/${projectId}/parameters/${row.id}`
            }
            icon={<LinkIcon />}
          />
        );
      },
    },
  ];
  return (
    <Grid container>
      <Typography
        variant="subtitle1"
        sx={{ padding: theme => theme.spacing(1) }}
      >
        Linked Parameters
      </Typography>
      <Grid
        aria-label="linked-parameters-grid"
        item
        xs={12}
        sx={{ margin: theme => theme.spacing(1) }}
      >
        <DataGrid
          data-cy="linked-parameters-data-grid"
          rowHeight={30}
          sx={{
            '& .MuiDataGrid-cell': {
              pt: theme => theme.spacing(0.25),
              pb: theme => theme.spacing(0.25),
            },
          }}
          density="compact"
          columns={columns}
          rows={sourceParameters}
          loading={parametersLoading}
          onRowsScrollEnd={() => {
            if (paramAfter) {
              setHasMore(prev => prev + 1);
            }
          }}
          footer={CustomFooterStatusComponent}
        />
      </Grid>
    </Grid>
  );
};

export default LinkedParameters;
