/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { sortBy } from 'lodash';
import { useImmer } from 'use-immer';
import { userApi } from 'api';
import config from '../config';
import { UserContext, ProjectContext } from '.';

export const ProjectPermissionContext = React.createContext();

export const ProjectPermissionProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const { projectId } = useParams();

  const [projectRoles, setProjectRoles] = useState([
    { name: 'reader' },
    { name: 'editor' },
    { name: 'checker' },
    { name: 'approver' },
    { name: 'administrator' },
  ]);
  const [projectPermissions, setProjectPermissions] = useImmer({
    readPermission: false,
    writePermission: false,
    checkPermission: false,
    approvePermission: false,
    adminPermission: false,
  });

  const getProjectPermissions = async () => {
    try {
      const response = await userApi('getUsersRoles', {
        emails: [user.email],
        resource_id: [projectId],
      });
      if (response) {
        const { usersRoles } = response;
        const userProjectPermissions = usersRoles[0].roles.map(
          role => role.name
        );
        setProjectPermissions({
          readPermission: userProjectPermissions.includes('reader'),
          writePermission: userProjectPermissions.includes('editor'),
          checkPermission: userProjectPermissions.includes('checker'),
          approvePermission: userProjectPermissions.includes('approver'),
          adminPermission: userProjectPermissions.includes('administrator'),
        });
      }
      if (user?.roles?.includes('App.Internal')) {
        const roleResponse = await userApi('getRoles', {
          resource_id: [projectId],
        });
        if (roleResponse) {
          const { roles } = roleResponse;

          setProjectRoles(
            sortBy(roles, element => {
              const rank = {
                reader: 1,
                editor: 2,
                checker: 3,
                approver: 4,
                administrator: 5,
              };
              return rank[element.name];
            })
          );
        }
      }
    } catch (err) {
      setProjectPermissions({
        readPermission: false,
        writePermission: false,
        checkPermission: false,
        approvePermission: false,
        adminPermission: false,
      });
    }
  };

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const setEnvPermissions = async () => {
      const roleResponse = await userApi('getRoles', {
        resource_id: [projectId],
      });
      if (!didCancel && roleResponse) {
        const { roles } = roleResponse;
        setProjectRoles(
          sortBy(roles, element => {
            const rank = {
              reader: 1,
              editor: 2,
              checker: 3,
              approver: 4,
              administrator: 5,
            };
            return rank[element.name];
          })
        );
      }
      setProjectPermissions({
        readPermission: true,
        writePermission: true,
        checkPermission: true,
        approvePermission: true,
        adminPermission: true,
      });
    };

    if (projectId && user.email) {
      if (config.featureFlags.isRoleBasedPermissionEnabled)
        getProjectPermissions();
      else setEnvPermissions();
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, user.email, project.confidential]);

  return (
    <ProjectPermissionContext.Provider
      value={{
        projectRoles,
        getProjectPermissions,
        ...projectPermissions,
      }}
    >
      {children}
    </ProjectPermissionContext.Provider>
  );
};

ProjectPermissionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
