/* eslint-disable camelcase */
import {
  useLocation,
  useParams,
  useNavigate,
  createSearchParams,
} from 'react-router';

import PT from 'prop-types';
import { Tab, Tooltip } from '@mui/material';
import { StyledTabs, StyledTypography } from './sidebar-styles';
import useSideNavigation from './hooks/side-navigation';

function Sidebar({ id }) {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { sideNavigationTabs, tabsLookup } = useSideNavigation(
    projectId,
    pathname
  );

  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  const handleChange = (_, newValue) => {
    if (sideNavigationTabs[newValue].path.includes('parameters')) {
      navigate({
        pathname: `${sideNavigationTabs[newValue].path}`,
        search: `?${createSearchParams(paramsValues)}`,
      });
    } else {
      navigate(sideNavigationTabs[newValue].path);
    }
  };

  return (
    <StyledTabs
      aria-label="sidebar"
      orientation="vertical"
      value={tabsLookup[id]}
      onChange={handleChange}
      textColor="primary"
      indicatorColor="primary"
    >
      {sideNavigationTabs.map(
        ({ icon, label, dataCy, id: tabId, disabled }) => {
          return (
            <Tab
              key={label}
              aria-label={label}
              icon={
                <Tooltip
                  placement="right"
                  key={label}
                  title={disabled && "You don't have permission to do this"}
                >
                  {icon}
                </Tooltip>
              }
              disabled={disabled}
              sx={{
                minWidth: 60,
                maxWidth: 60,
                paddingLeft: 1.5,
                paddingBottom: 1,
                ...(tabId === 'project-settings' && {
                  maxWidth: 60,
                  position: 'absolute',
                  bottom: '0',
                }),
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
              }}
              data-cy={dataCy}
              label={<StyledTypography noWrap>{label}</StyledTypography>}
            />
          );
        }
      )}
    </StyledTabs>
  );
}

Sidebar.propTypes = {
  id: PT.string.isRequired,
};

export default Sidebar;
