import PT from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import {
  Grid,
  Typography,
  Dialog,
  Skeleton,
  Tooltip,
  Chip,
} from '@mui/material';
import { Warning } from '@mui/icons-material';
import StatusCircle from 'components/status-circle';
import {
  Autocomplete,
  DatePicker,
  Select,
  TextField,
} from 'components/pickers/final-form-fields';
import { ActionButton } from 'components/buttons';
import getOptionLabel from 'routes/parameters-page/helpers/get-option-label';
import AddSource from '../../routes/parameters-page/common/add-source';
import StyledBox from './add-revisions-styles';
import useRevisions from './hooks';

const AddRevisions = ({ values }) => {
  const {
    sourcesLoading,
    allSources,
    units,
    unitsLoading,
    openAddSource,
    setSearchSources,
    setOpenAddSource,
    setSelectedSource,
    optionsLoading,
    valueOptions,
    getValueValidation,
    getUnitValidation,
    getSourceValidation,
  } = useRevisions(values);

  const getValueFormField = (currentFields, field, index) => {
    if (valueOptions[index]?.length) {
      return (
        <Autocomplete
          label="Value"
          name={`${field}.value`}
          options={[...valueOptions[index]]}
          aria-label="select-value"
          key={option => option.value}
          loading={optionsLoading}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.value}
          required={getValueValidation(currentFields, index, 'required')}
          error={getValueValidation(currentFields, index, 'error')}
          helperText={getValueValidation(currentFields, index, 'helperText')}
        />
      );
    }
    if (currentFields[index].dataType === 'boolean')
      return (
        <Select
          aria-label="select"
          label="Value"
          name={`${field}.value`}
          options={[
            { id: true, name: 'True' },
            { id: false, name: 'False' },
          ]}
          required={getValueValidation(currentFields, index, 'required')}
          error={getValueValidation(currentFields, index, 'error')}
          helperText={getValueValidation(currentFields, index, 'helperText')}
        />
      );

    if (currentFields[index].dataType === 'date')
      return (
        <DatePicker
          label="Value"
          name={`${field}.value`}
          required={getValueValidation(currentFields, index, 'required')}
          error={getValueValidation(currentFields, index, 'error')}
          helperText={getValueValidation(currentFields, index, 'helperText')}
        />
      );
    return (
      <TextField
        aria-label="text field"
        label="Value"
        name={`${field}.value`}
        type={currentFields[index].dataType}
        required={getValueValidation(currentFields, index, 'required')}
        error={getValueValidation(currentFields, index, 'error')}
        helperText={getValueValidation(currentFields, index, 'helperText')}
      />
    );
  };

  return (
    <>
      <ActionButton
        aria-label="add-source-button"
        sx={{
          marginBottom: theme => theme.spacing(1),
        }}
        onClick={() => {
          setOpenAddSource(!openAddSource);
        }}
        variant="contained"
        color="primary"
      >
        {'Add new source'.toLocaleUpperCase()}
      </ActionButton>
      {optionsLoading ? (
        <Skeleton width="90%" height={20} data-testid="loading" />
      ) : (
        <FieldArray name="parameterTypes" aria-label="add-revision-step">
          {({ fields }) => {
            const currentFields = fields.value;
            return fields.map((field, index) => {
              return (
                <StyledBox
                  data-testid="add-revisions"
                  aria-label="paper card parameter type"
                  key={
                    currentFields[index]?.id
                      ? `paper-${currentFields[index].id}`
                      : `paper-${currentFields[index].parameterTypeId}`
                  }
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    key={
                      currentFields[index]?.id
                        ? `grid-${currentFields[index].id}`
                        : `grid-${currentFields[index].parameterTypeId}`
                    }
                  >
                    <Grid
                      item
                      xs={3}
                      sm={1}
                      key={
                        currentFields[index]?.id
                          ? `grid-status-${currentFields[index].id}`
                          : `grid-status-${currentFields[index].parameterTypeId}`
                      }
                    >
                      <StatusCircle
                        aria-label="status"
                        status={
                          currentFields[index].value ||
                          currentFields[index].value === false
                            ? 'answered'
                            : currentFields[index].status
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs
                      key={
                        currentFields[index]?.id
                          ? `grid-typo-${currentFields[index].id}`
                          : `grid-typo-${currentFields[index].parameterTypeId}`
                      }
                    >
                      {currentFields[index].isNewItemType && (
                        <Grid item paddingTop={theme => theme.spacing(1)}>
                          <Tooltip
                            aria-label="warning-tooltip"
                            title="This parameter has not been imported to this asset yet. Import will be completed once the Finish button is pressed in the last step"
                          >
                            <Warning
                              fontSize="small"
                              data-testid="warning-icon"
                              sx={{
                                color: theme => theme.palette.grey[500],
                                marginRight: theme => theme.spacing(0.75),
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      )}

                      <Grid item>
                        <Typography
                          variant="body2"
                          aria-label="parameter-type-name"
                        >
                          {currentFields[index].name ||
                            currentFields[index].parameterTypeName}
                        </Typography>
                      </Grid>
                      {currentFields[index].isNewParameterType && (
                        <Grid item>
                          <Chip
                            aria-label="custom-chip"
                            sx={{
                              backgroundColor: theme => theme.palette.grey[500],
                              margin: theme => theme.spacing(0.5),
                            }}
                            label={
                              <Typography
                                variant="subTitle1"
                                fontWeight="bold"
                                color={theme => theme.palette.getContrastText}
                              >
                                Custom
                              </Typography>
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={currentFields[index].unitTypeId ? 8 : 12}
                      sm={currentFields[index].unitTypeId ? 2 : 4}
                      aria-label="value-input"
                      key={
                        currentFields[index]?.id
                          ? `grid-value-${currentFields[index].id}`
                          : `grid-value-${currentFields[index].parameterTypeId}`
                      }
                    >
                      {getValueFormField(currentFields, field, index)}
                    </Grid>
                    {currentFields[index].unitTypeId && (
                      <Grid
                        sx={{ paddingBottom: theme => theme.spacing(0.5) }}
                        item
                        xs={4}
                        sm={2}
                        key={
                          currentFields[index]?.id
                            ? `grid-unit-${currentFields[index].id}`
                            : `grid-unit-${currentFields[index].parameterTypeId}`
                        }
                      >
                        <Autocomplete
                          required={getUnitValidation(
                            currentFields,
                            index,
                            'required'
                          )}
                          error={getUnitValidation(
                            currentFields,
                            index,
                            'error'
                          )}
                          helperText={getUnitValidation(
                            currentFields,
                            index,
                            'helperText'
                          )}
                          aria-label="select-unit"
                          label="Unit"
                          key={option => option.id}
                          name={`${field}.unit`}
                          groupBy={option => option.systemName}
                          options={units[currentFields[index].unitTypeId] || []}
                          loading={unitsLoading}
                          value={currentFields[index]?.unit || null}
                          getOptionValue={unit => unit}
                          getOptionLabel={unit => unit.symbol}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                    )}
                    <Grid
                      sx={{ paddingBottom: theme => theme.spacing(0.5) }}
                      item
                      xs
                      key={
                        currentFields[index]?.id
                          ? `grid-source-${currentFields[index].id}`
                          : `grid-source-${currentFields[index].parameterTypeId}`
                      }
                    >
                      <Autocomplete
                        aria-label="select-source"
                        label="Source"
                        name={`${field}.source`}
                        groupBy={option => option.sourceType.name}
                        options={allSources}
                        loading={sourcesLoading}
                        value={currentFields[index]?.source || null}
                        getOptionValue={source => source}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onInputChange={e => {
                          setSearchSources(e?.target?.value);
                        }}
                        clearOnBlur={false}
                        required={getSourceValidation(
                          currentFields,
                          index,
                          'required'
                        )}
                        error={getSourceValidation(
                          currentFields,
                          index,
                          'error'
                        )}
                        helperText={getSourceValidation(
                          currentFields,
                          index,
                          'helperText'
                        )}
                      />
                    </Grid>
                  </Grid>
                </StyledBox>
              );
            });
          }}
        </FieldArray>
      )}
      {openAddSource && (
        <Dialog
          data-testid="source-dialog"
          open={openAddSource}
          onClose={() => {
            setOpenAddSource(false);
          }}
        >
          <AddSource
            openAddSource={openAddSource}
            setOpenAddSource={setOpenAddSource}
            setSelectedSource={setSelectedSource}
          />
        </Dialog>
      )}
    </>
  );
};

AddRevisions.propTypes = {
  values: PT.shape({}).isRequired || PT.array.isRequired,
};

export default AddRevisions;
