import { useEffect, useState, useContext } from 'react';
import { orderBy } from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { referenceDataApi, handleApiError, parametersApi } from 'api';
import {
  emojiValidation,
  numberValidation,
  intValidation,
  stringValidation,
} from 'utils';
import { AssetContext } from 'context';

export default values => {
  const { theAsset } = useContext(AssetContext);
  const [allSources, setAllSources] = useState([]);
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [units, setUnits] = useState({});
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [openAddSource, setOpenAddSource] = useState(false);
  const [newSource, setSelectedSource] = useState(false);
  const [searchSources, setSearchSources] = useState(null);
  const [valueOptions, setValueOptions] = useImmer({});
  const [optionsLoading, setOptionsLoading] = useState(true);
  const { projectId } = useParams();

  const paramValues = values.parameterTypes || values;

  useEffect(() => {
    const assetType = theAsset?.assetType?.id;
    const getAllOptions = async () => {
      const allOptions = await Promise.all(
        paramValues.map(async (field, index) => {
          const itemOptions = [];
          const query = {
            parameter_type_id: field.parameterTypeId,
          };
          if (assetType) {
            query.asset_type_id = assetType;
          } else {
            query.asset_type_id = '';
          }
          const { itemTypes } = await parametersApi(
            'getAllItemTypes',
            query
          ).catch(handleApiError);
          if (itemTypes[0] && itemTypes[0].hasOptions) {
            const { options } = await parametersApi('getOptions', {
              item_type_id: itemTypes[0].id,
            });
            itemOptions.push(...options);
          }
          return { key: index, value: itemOptions };
        })
      );

      const optionsObject = allOptions.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {}
      );
      setValueOptions(optionsObject);
      setOptionsLoading(false);
    };
    getAllOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, theAsset]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    const loadAllSources = async after => {
      setSourcesLoading(true);
      const query = {
        reference_id: projectId,
        include_global: 'true',
        page_limit: 500,
      };
      if (searchSources) query.search = searchSources;
      if (after) query.after = after;
      const response = await referenceDataApi(
        'getSources',
        query,
        source.token
      ).catch(handleApiError);
      if (!didCancel && response) {
        const { sources, paging } = response;

        setAllSources(curr => {
          const sortedByTypeAndTitle = orderBy(
            [...curr, ...sources],
            [res => res.sourceType.name, res => res.title],
            ['asc', 'asc']
          );
          const filterAutomatedProcesses = sortedByTypeAndTitle.filter(
            res => res.sourceType.name !== 'Auto'
          );
          return filterAutomatedProcesses;
        });

        if (paging?.cursors?.after) {
          loadAllSources(paging?.cursors?.after);
        } else setSourcesLoading(false);
      }
    };
    loadAllSources();

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSource, searchSources]);

  useEffect(() => {
    let didCancel = false;
    const getUnits = async () => {
      setUnitsLoading(true);
      setUnits({});
      // TO DO: update to single query in V1
      const hasUnits = paramValues.filter(val => val.unitTypeId);
      const unitsByType = await Promise.all(
        hasUnits.map(async value => {
          const { unitType: response } = await parametersApi('getUnitType', {
            unit_type_id: value.unitTypeId,
          }).catch(handleApiError);
          const formatted = response.unitSystems?.map(system => {
            const systemName = system.shortTitle;
            const unitsTypes = system.units.map(unit => ({
              ...unit,
              systemName,
            }));
            return unitsTypes;
          });
          return formatted?.flat();
        })
      );
      if (!didCancel) {
        setUnits(
          hasUnits.reduce((obj, value, i) => {
            obj[value.unitTypeId] = unitsByType[i];
            return obj;
          }, {})
        );
        setUnitsLoading(false);
      }
    };
    getUnits();

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValueValidation = (currentFields, index, type) => {
    let validateField = !!(
      (currentFields[index].source || currentFields[index].unit) &&
      currentFields[index].value !== false &&
      !currentFields[index].value
    );
    if (emojiValidation(currentFields[index].value)) {
      validateField = emojiValidation(currentFields[index].value);
      if (type === 'helperText') return validateField ? 'Invalid' : '';
      return validateField;
    }
    if (
      currentFields[index].value &&
      currentFields[index].dataType === 'float'
    ) {
      validateField = !numberValidation(currentFields[index].value);
      if (type === 'helperText') return validateField ? 'Invalid' : '';
      return validateField;
    }
    if (
      currentFields[index].value &&
      currentFields[index].dataType === 'date' &&
      !(
        currentFields[index].value instanceof Date &&
        !isNaN(currentFields[index].value)
      )
    ) {
      if (type === 'helperText') return 'Invalid Date';
      return true;
    }
    if (
      currentFields[index].value &&
      currentFields[index].dataType === 'integer'
    ) {
      validateField = !intValidation(currentFields[index].value);
      if (type === 'helperText') return validateField ? 'Invalid' : '';
      return validateField;
    }
    if (type === 'helperText') return validateField ? 'Required' : '';
    if (
      currentFields[index].value &&
      currentFields[index].dataType === 'string'
    ) {
      validateField = !stringValidation(currentFields[index].value);
      if (type === 'helperText') return validateField ? 'Required' : '';
      return validateField;
    }
    return validateField;
  };

  const getUnitValidation = (currentFields, index, type) => {
    const validateField = !!(
      (currentFields[index].value ||
        currentFields[index].value === false ||
        currentFields[index].source) &&
      !currentFields[index].unit
    );
    if (type === 'helperText') return validateField ? 'Required' : '';
    return validateField;
  };

  const getSourceValidation = (currentFields, index, type) => {
    const validateField = !!(
      (currentFields[index].value ||
        currentFields[index].value === false ||
        currentFields[index].unit) &&
      !currentFields[index].source
    );
    if (type === 'helperText') return validateField ? 'Required' : '';
    return validateField;
  };

  return {
    sourcesLoading,
    allSources,
    units,
    unitsLoading,
    openAddSource,
    setOpenAddSource,
    setSelectedSource,
    setSearchSources,
    valueOptions,
    optionsLoading,
    getValueValidation,
    getUnitValidation,
    getSourceValidation,
  };
};
