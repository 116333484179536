import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledGrid = styled(props => <Grid {...props} />)(
  // eslint-disable-next-line no-unused-vars
  ({ theme, ...props }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(100px, auto))',
    gridTemplateAreas: props?.assetId
      ? "'dataSetName dataSetName' 'date value'"
      : "'dataSetName asset' 'date value'",
  })
);

export default StyledGrid;
