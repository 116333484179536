import { Suspense, useContext } from 'react';
import { useParams } from 'react-router';
import { FoldingDrawer } from 'components/drawer';
import PageLayoutBox from 'components/layout/page-layout-styles';
import useTemplatesPage from 'routes/templates/hooks/use-templates-page';
import { StyledBox } from 'routes/parameters-page/parameters-page-styles';
import { DataSetsContext } from 'context';
import DataTemplateHeader from '../template-details/header';
import DataSetOutputs from './data-set-outputs';

function DataSetDetails() {
  const { dataSetId } = useParams();
  const { dataSets } = useContext(DataSetsContext);
  const {
    openTemplatePanels,
    numberOfTemplatePanelsOpen,
    handleDataSetDetailsChange,
    headerActions,
  } = useTemplatesPage(dataSetId);

  const currentSetInstance = dataSets.filter(
    dataSet => dataSet.id === dataSetId
  );
  const dataSetHeaderInfo = currentSetInstance[0];
  // TODO: History to be moved to component level/updated when data available

  return (
    <FoldingDrawer
      width={numberOfTemplatePanelsOpen > 2 ? '20%' : '30%'}
      open={openTemplatePanels.openDataSetDetails}
      tooltip="Data set details"
      onClick={handleDataSetDetailsChange}
    >
      <PageLayoutBox>
        <StyledBox aria-label="set-instance-details-panel">
          <Suspense fallback={null}>
            <DataTemplateHeader
              dataTemplate={dataSetHeaderInfo}
              loading={false}
              headerActions={headerActions}
              label="dataSet"
            />
            <DataSetOutputs />
          </Suspense>
        </StyledBox>
      </PageLayoutBox>
    </FoldingDrawer>
  );
}

export default DataSetDetails;
