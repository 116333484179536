import { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import validateAssetName from 'utils/validate-asset-name';
import { AssetContext } from 'context';

export default (index, columnIndex, type, subTypes) => {
  const {
    allAssets,
    addAssetToWizard,
    setAssetManipulation,
    assetManipulation,
    assetWizardColumns,
  } = useContext(AssetContext);

  const [isPosting, setIsPosting] = useState(false);
  const [newAsset, setNewAsset] = useState('');
  const [error, setError] = useState(null);

  const onClick = () => {
    setIsPosting(true);
    const errors = validateAssetName(
      !isEmpty(subTypes) ? newAsset.name : newAsset,
      type.parent || null,
      type.id,
      allAssets
    );
    setError(errors);
    if (errors) setIsPosting(false);
    else {
      const newOne = {
        id: uuidv4(),
        name: !isEmpty(subTypes) ? newAsset.name : newAsset,
        new: true,
        assetType: {
          id: type.id,
          name: type.name,
          assetSubType: !isEmpty(subTypes),
        },
        assetSubType: !isEmpty(subTypes) ? { id: newAsset.id } : null,
        parent: type?.parent || null,
        children: [],
        deletedAt: null,
        parentName: type.parent
          ? allAssets.reduce((assets, { id, name }) => {
              if (id === type.parent) return name;
              return assets;
            }, {})
          : '',
      };
      addAssetToWizard(columnIndex, index, newOne);
      setAssetManipulation(curr => {
        Object.keys(curr.isSelected).forEach(key => {
          if (key >= columnIndex) {
            delete curr.isSelected[key];
          }
        });
        curr.isAddingNew[type.name] = false;
        curr.hasSubTypes[type.id] = [];
        curr.allSubTypesUsed[type.id] = subTypes.length === 1;
        curr.isOpen[type.name] = true;
        curr.isSelected[columnIndex] = newOne.id;
      });
      setNewAsset('');
      setIsPosting(false);
    }
  };

  const onClearClick = () => {
    setAssetManipulation(curr => {
      curr.isAddingNew[type.name] = false;
      curr.hasSubTypes[type.id] = [];
    });
    setNewAsset('');
  };
  return {
    isPosting,
    setIsPosting,
    newAsset,
    setNewAsset,
    error,
    setError,
    onClick,
    onClearClick,
    assetWizardColumns,
    allAssets,
    assetManipulation,
  };
};
