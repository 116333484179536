import { Typography, Box, Link, Checkbox } from '@mui/material';
import { Link as RouterLink } from 'react-router';
import { capitalize } from 'lodash';
import { withAppInsights } from 'app/app-insights';
import { DataGrid } from 'components/tables';
import Alert from 'components/alerts';
import usePermissions from './hooks';

function UserPermissions() {
  const { userPermissions, error, loading } = usePermissions();

  const columns = [
    {
      flex: 1,
      headerName: 'Project Name',
      field: 'shortTitle',
      display: 'flex',
      renderCell: ({ row, value }) => (
        <Link to={`/projects/${row.id}`} component={RouterLink}>
          {value}
        </Link>
      ),
    },
    {
      flex: 0.5,
      display: 'flex',
      headerName: 'Project No',
      field: 'projectNumber',
      type: 'number',
    },
    ...['reader', 'editor', 'checker', 'approver', 'administrator'].map(
      name => ({
        flex: 0.5,
        headerName: capitalize(name),
        field: name,
        type: 'boolean',
        display: 'flex',
        valueGetter: (value, row) => row.roles.includes(name),
        renderCell: ({ row, value, field }) => (
          <Checkbox
            checked={value}
            disabled
            inputProps={{
              'aria-label': `checkbox-${field}-${row.shortTitle}`,
            }}
          />
        ),
      })
    ),
  ];

  return (
    <Box
      sx={{ p: theme => theme.spacing(5) }}
      data-testid="user-settings-permissions"
    >
      <Typography variant="h4" component="h1" mb={3} gutterBottom>
        Permissions
      </Typography>
      {error ? (
        <div>
          <Alert title={error.msg} text={error.details} />
        </div>
      ) : (
        <DataGrid
          exportName="user-permissions"
          height="500"
          rows={userPermissions}
          columns={columns}
          loading={loading}
          disableSelectionOnClick
          hideFooterPagination
        />
      )}
    </Box>
  );
}

export default withAppInsights(UserPermissions, 'UserPermissionsPage');
