import { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { withAppInsights } from 'app/app-insights';
import { MetaTitle } from 'components/meta';
import { ResponsiveContainer } from 'components/drawer';
import PrivateRoute from 'app/private';
import UserDetails from './details';
import Cookies from './cookies';
import UserPermissions from './permissions';
import UserTheme from './theme';
import tabs from './tabs-list';

function UserSettings() {
  return (
    <main>
      <MetaTitle title="User Setting" />
      <ResponsiveContainer
        navigationListTitle="User Setting"
        navigationList={tabs}
      >
        <Routes>
          <Route
            path="details"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <UserDetails />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="permissions"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <UserPermissions />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="theme"
            element={
              <Suspense fallback={null}>
                <UserTheme />
              </Suspense>
            }
          />
          <Route
            path="cookies"
            element={
              <Suspense fallback={null}>
                <Cookies />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
      </ResponsiveContainer>
    </main>
  );
}

export default withAppInsights(UserSettings, 'UserSettingsPage');
